import { Box, Divider, Modal, Typography } from "@mui/material";
import React from "react";
import EntityOverview from "../views/EntityOverview";
import { theme } from "../setting";

export const AssignModal = (props) => {
  const { open, onClose, modalOptions } = props;
  return (
    <Modal open={open} onClose={onClose} data-testid='assign-modal'>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "calc(100% - 200px)",

          bgcolor: "white",
          border: `2px solid ${theme.palette.primary.main}`,
          boxShadow: 24,
          padding: "0.5rem",
        }}
      >
        <Typography variant='h6'>{modalOptions.title}</Typography>
        <Divider sx={{ my: 2 }} />
        <EntityOverview {...modalOptions} />
      </Box>
    </Modal>
  );
};
