import moment from "moment";
import { Box } from "@mui/system";
import { getType } from "../utils/utils";

export default function DisplayEditedRecord({ entity, type }) {
  const {
    updated,
    updated_by: updatedBy,
    created,
    created_by: createdBy,
  } = entity;

  const updatedFormattedDate = moment(updated).format("DD-MM-YYYY");
  const updatedFormattedTime = moment(updated).format("HH:mm A");

  const createdFormattedDate = moment(created).format("DD-MM-YYYY");
  const createdFormattedTime = moment(created).format("HH:mm A");

  return (
    <Box
      sx={{
        paddingTop: 1,
        paddingBottom: 2,
        display: "flex",
        flexDirection: "column",
        "& p": {
          margin: 0.5,
        },
      }}
    >
      {created && createdBy && (
        // eslint-disable-next-line max-len
        <Box
          component='p'
          sx={{
            fontSize: "15px",
          }}
        >
          This {getType(type)} was created by {createdBy} at{" "}
          {createdFormattedDate} - {createdFormattedTime}
        </Box>
      )}
      {updated && updatedBy && (
        // eslint-disable-next-line max-len
        <Box
          component='p'
          sx={{
            color: "grey",
            fontStyle: "italic",
            fontSize: "15px",
          }}
        >
          Last edit: {updatedFormattedDate} - {updatedFormattedTime} (by{" "}
          {updatedBy})
        </Box>
      )}
    </Box>
  );
}
