import { useState, useEffect } from "react";
import { fetchFields } from "../dao/fields";
import { errorMessageHandler } from "./../utils/error";

/**
 * @typedef FieldColumn
 * @type Object
 * @property {string} title
 * @property {string} field
 * @property {string} description
 * @property {boolean} required
 * @property {number} sortOrder
 * @property {boolean} filterable
 */

/**
 *
 * @param {string} type The type to get the fields for
 * @returns {FieldColumn[]} The columns to be used
 */
function getFieldsFor(type, abortController) {
  return fetchFields(type, abortController).then((overviewFields) =>
    overviewFields.map((field) => {
      return {
        title: field.label,
        field: field.field_name,
        description: field.description,
        required: field.required,
        sortOrder: field.sort_order,
        filterable: field.filterable || false,
        headerName: field.label,
        hide: field.hide,
        type: field.type,
        mass_editable: field.mass_editable,
        inline_editable: field.inline_editable,
        values: field.values,
        hasPattern: field.has_pattern,
        sort_name: field.sort_name,
        unit: field.unit,
      };
    })
  );
}

/**
 * @param {string} [targetType] Target type to filter by (`FILTER_TYPES`)
 * @returns {<null|FieldColumn[]>} The columns to be used for type
 */
export function useColumns(targetType) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    getFieldsFor(targetType, abortController)
      .then((columnData) => {
        setColumns(
          columnData.filter((field) => field.sortOrder?.overview ?? false)
        );
      })
      .catch(errorMessageHandler);

    return () => {
      abortController.abort();
    };
  }, []);

  return { columns };
}
