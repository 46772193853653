import { useState, useEffect } from "react";
import { fetchTypes } from "../dao/types";

export default function useFetchTypes(type) {
  const [typesData, setTypesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getTypes = async () => {
      setIsLoading(true);
      try {
        const data = await fetchTypes(type);
        setTypesData(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getTypes();
  }, []);

  return [typesData, isLoading, error];
}
