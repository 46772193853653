import { post } from "../api/api";

export const INLINE_EDIT_BATCH_SAVE_PATH = "/inline-edit";

export async function batchSave(payload, entity) {
  if (!payload || !Array.isArray(payload) || !entity) {
    throw new Error("Invalid payload or entity name.");
  }

  const request = {
    source: {
      type: entity,
      ids: payload.map((item) => item.id),
    },
    edit: payload,
  };
  return post(INLINE_EDIT_BATCH_SAVE_PATH, request);
}
