import { useState, useEffect } from 'react';
import { fetchFields } from '../dao/fields';
import { errorMessageHandler } from './../utils/error';

export { FILTER_TYPES } from '../dao/fields';

/**
 * Simple hook to fetch fields, either all known fields, or more commonly a
 * set of fields filtered by the 'target' type that is to be editted.
 *
 * @param {String} [targetType] Target type from `FILTER_TYPES`
 * @param {boolean} [waitForType] Wait for targetType to be set?
 * @param {boolean} [includeCreateFields]
 * @param {boolean} [includeUpdateFields]
 * @returns {Promise<import('../dao/fields').Field[]>} Set of fields
 */
export default function useFields(
    targetType,
    waitForType = false,
    includeCreateFields = false,
    includeUpdateFields = false,
) {
  const [ fields, setFields ] = useState(null);

  useEffect(() => {
    if (!waitForType || targetType) {
      const abortController = new AbortController();
      fetchFields(targetType, abortController)
          .then(
              (allFields) => allFields
                  .filter(
                      (field) =>
                        (field.sort_order?.form ?? false) &&
                        (includeCreateFields || !field.only_creatable) &&
                        (includeUpdateFields || !field.only_updatable),
                  ),
          )
          .then(
              (allFields) => allFields.sort(
                  (fieldA, fieldB) =>
                    (fieldA.sort_order?.form ?? 0) -
                  (fieldB.sort_order?.form ?? 0),
              ),
          )
          .then(setFields)
          .catch(errorMessageHandler);
      return () => {
        abortController.abort();
      };
    }
  }, [ targetType, waitForType, includeCreateFields, includeUpdateFields ]);

  return [ fields ];
}
