import { CircularProgress, Button } from "@mui/material";

function ButtonWithSpinner({
  onClick,
  color,
  loading,
  children,
  variant,
  disabled,
  ...props
}) {
  return (
    <Button
      onClick={onClick}
      color={color}
      disabled={loading || disabled}
      variant={variant}
      title={disabled ? "You are not allowed to perform this action" : ""}
      {...props}
    >
      {children}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          color='primary'
        />
      )}
    </Button>
  );
}

export default ButtonWithSpinner;
