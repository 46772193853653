export const getNameFromValue = (options, value) => {
  if (!value || value === "") return "";
  const obj = options?.find((o) => o.abbr === (value?.abbr || value));
  return `${obj?.description} (${obj?.abbr})`;
};

export const isSameValue = (option, value) => {
  return value === option.abbr;
};

/**
 * Concatenates error messages from an object into a single string with ordered list numbers.
 * @param {Object} errors - The object containing error messages.
 * @return {string} - The concatenated error messages with ordered list numbers.
 */
export const concatenateErrorMessages = (errors) => {
  if (typeof errors !== "object" || errors === null || Array.isArray(errors)) {
    return String(errors);
  }

  let errorMessage = "";
  let errorCount = 0;

  Object.entries(errors).forEach(([fieldName, errorArray]) => {
    if (!Array.isArray(errorArray)) {
      errorMessage += `${errorCount + 1}. ${fieldName}: ${String(
        errorArray
      )}\n`;
      errorCount++;
    } else {
      errorArray.forEach((error) => {
        errorCount++;
        errorMessage += `${errorCount}. ${fieldName}: ${String(error)}\n`;
      });
    }
  });

  return errorMessage.trim();
};

/**
 * Generates an object of error messages from an array of errors.
 * @param {Array} errors - The array of error objects containing field and message properties.
 * @return {Object} - The object with field names as keys and arrays of error messages as values.
 */
export const generateErrorMessages = (errors) => {
  if (!errors || !Array.isArray(errors)) {
    return [];
  }

  return errors.reduce((acc, current, i) => {
    const COUNT = i + 1;
    const label = current.id ? `${current.id}_${COUNT}` : `${'reason'}_${COUNT}`;
    (acc[label] = acc[label] || []).push(current.message);
    return acc;
  }, {});
};

/**
 * Utility function to get a friendlier error message from a string.
 * @param {String} errorMsg 
 * @returns {String} a friendlier error message
 */
export const getFriendlyMessage = (errorMsg = "") => {
  const friendlyMessagesMap = {
    "duplicateKey": "Record already exists.",
    "serverError" : "There was a problem with the request. Please try different input.",
    "deleted": "The record(s) that you are trying to change have been (soft) deleted."
  };
  switch (true) {
    case /do not exist/i.test(errorMsg):
      return friendlyMessagesMap.deleted;
    case /bad request/i.test(errorMsg):
    case /internal server error/i.test(errorMsg):
      return friendlyMessagesMap.serverError;
    case /duplicatekey/i.test(errorMsg):
      return friendlyMessagesMap.duplicateKey;
    default:
        return errorMsg;
    }
}
