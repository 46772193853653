import React from "react";

import { Redirect } from "react-router-dom";

import useUserPermissionsContext from "./hooks/useUserPermissionsContext";
import UserRights from "./enums/UserRights";
import { Box, Typography, Alert, CircularProgress } from "@mui/material";

const PrivateRoute = ({ entity, children }) => {
  const { isAllowedTo, isLoading, hasError } = useUserPermissionsContext();

  /**
   * It is assumed that a user with create authorisation
   * will also have read authorisation
   */
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, entity);
  const isAllowedToRead = isAllowedTo(UserRights.READ, entity);

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: 3,
          textAlign: "center",
        }}
      >
        <CircularProgress sx={{ marginBottom: "2rem" }} />
        <Typography>Loading your permissions</Typography>
      </Box>
    );
  }
  if (hasError) {
    return (
      <Alert severity='error'>
        <Typography>
          There was an error loading your permissions. Please try again later.
        </Typography>
      </Alert>
    );
  }

  return (
    <>
      {isAllowedToCreate || isAllowedToRead ? (
        children
      ) : (
        <Redirect to='/unauthorised' />
      )}
    </>
  );
};

export default PrivateRoute;
