import React from "react";
import { IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { noop } from "../utils/utils";

export const FormAlert = ({
  onClose = noop.fn,
  severity = "info",
  alertMessage = "",
}) => (
  <Alert
    severity={severity}
    data-testid="formAlert"
    action={
      severity === "error" && (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )
    }
  >
    {alertMessage}
  </Alert>
);
