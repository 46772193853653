import { getGridStringOperators } from "@mui/x-data-grid-pro";
import { CustomBooleanSelectorField } from "../CustomFilterField/CustomBooleanSelectorField";
import { CustomFilterField } from "../CustomFilterField/CustomFilterField";
import { RangeFilter } from "../RangeFilter";

const excludedStringOperators = ["isAnyOf"];

const excludedDateOperators = [
  "is",
  "not",
  "onOrAfter",
  "onOrBefore",
  "isAnyOf",
];

const excludedNumericOperators = [">", "<", "!=", "isAnyOf"];

const customOperatorFactory = (label, value, InputComponent) => (props) =>
  [
    {
      label,
      value,
      getApplyFilterFn: (item) => {
        if (!item.columnField || !item.value || !item.operatorValue) {
          return null;
        }
        return (params) => {
          return [params.value];
        };
      },
      InputComponent,
      InputComponentProps: props,
    },
  ];

const doesNotContainOperator = customOperatorFactory(
  "does not contain",
  "doesNotContain",
  CustomFilterField
);

const dateRangeOperator = customOperatorFactory(
  "is between",
  "betweenDates",
  RangeFilter
);

const numberRangeOperator = customOperatorFactory(
  "is between",
  "betweenNumbers",
  RangeFilter
);

const exactOperator = customOperatorFactory(
  "equals",
  "exactDate",
  CustomFilterField
);

const equalsNotOperator = customOperatorFactory(
  "equals not",
  "equalsNot",
  CustomFilterField
);

const isNotOperator = customOperatorFactory(
  "is not",
  "isNot",
  CustomBooleanSelectorField
);

const containsMultipleOperator = customOperatorFactory(
  "contains multiple",
  "containsMultiple",
  CustomFilterField
);

const doesNotContainMultipleOperator = customOperatorFactory(
  "does not contain multiple",
  "doesNotContainMultiple",
  CustomFilterField
);

// Numeric fields do not have some string operators that we need by default
// We need to append what we need to the default operators
const stringOperatorsForNumber = [...(getGridStringOperators() ?? [])].filter(
  (operator) =>
    ![...excludedStringOperators, "isEmpty", "isNotEmpty"].includes(
      operator.value
    )
);

const customNumberOperators = [
  doesNotContainOperator({
    type: "number",
    name: "does-not-contain-input",
  }),
  equalsNotOperator({
    type: "number",
    name: "equals-not-input",
  }),
  containsMultipleOperator({
    type: "text", // It is a text input, because user can input multiple values separated by semicolon
    name: "contains-multiple-input",
  }),
  doesNotContainMultipleOperator({
    type: "text",
    name: "does-not-contain-input",
  }),
  numberRangeOperator({ type: "number" }),
];

const customStringOperators = [
  doesNotContainOperator({
    type: "text",
    name: "does-not-contain-input",
  }),
  equalsNotOperator({
    type: "text",
    name: "equals-not-input",
  }),
  containsMultipleOperator({
    type: "text",
    name: "contains-multiple-input",
  }),
  doesNotContainMultipleOperator({
    type: "text",
    name: "does-not-contain-input",
  }),
];

const customDateOperators = [
  exactOperator({ type: "date", name: "date-input" }),
  dateRangeOperator({ type: "date" }),
];

const customBooleanOperators = [
  isNotOperator({
    type: "boolean",
    name: "is-not-input",
  }),
];

export {
  excludedStringOperators,
  excludedDateOperators,
  excludedNumericOperators,
  customNumberOperators,
  customStringOperators,
  customBooleanOperators,
  customDateOperators,
  customOperatorFactory,
  exactOperator,
  stringOperatorsForNumber,
};
