import { theme } from './../setting';
/**
 * Define sizes per breakpoint in amount of columns on a grid of 12.
 * So for instance, 2 (of 12) means it fits 6 times on a row.
 */
const sizeCharts = {
  s: {
    palm: 4,
    hands: 3,
    desktop: 2,
    wide: 2,
  },
  m: {
    palm: 6,
    hands: 4,
    desktop: 3,
    wide: 2,
  },
  l: {
    palm: 12,
    hands: 8,
    desktop: 6,
    wide: 4,
  },
  xl: {
    palm: 12,
    hands: 12,
    desktop: 9,
    wide: 6,
  },
};

const defaultSize = 'm';

/**
 * Generate field-width for all known break-points based on simple
 * t-shirt sizing. This behaviour is deliberately kept non-specific
 * as future insight may well change how fields will behave. Their
 * relative size is the only constant.
 * At smallest screen-size, all fields are full-width. As the size
 * of the screen increases, the difference in actual size will become
 * more pronounced.
 *
 * @param {String} size Required size definition (s, m, l, xl)
 *
 * @return {Object} style-object for use with MUI `makeStyles`
 */
export default function fieldSize(size) {
  const gap = theme.gap || `${theme.spacing(2)}px`;
  const column = '8.33%';
  const sizeChart = sizeCharts[size] || sizeCharts[defaultSize];

  return {
    width: `calc(100% - ${gap}px)`,
    margin: `0 ${gap}px ${gap}px 0`,
    [theme.breakpoints.up(589)]: {
      width: `calc(${column} * ${sizeChart.palm} - ${gap}px)`,
    },
    [theme.breakpoints.up(889)]: {
      width: `calc(${column} * ${sizeChart.hands} - ${gap}px)`,
    },
    [theme.breakpoints.up(1139)]: {
      width: `calc(${column} * ${sizeChart.desktop} - ${gap}px)`,
    },
    [theme.breakpoints.up(1489)]: {
      width: `calc(${column} * ${sizeChart.wide} - ${gap}px)`,
    },
  };
}
