import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';
import { flattenRecord } from '../utils/utils';

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
  };
}

export default function useProject(objectId = null, projectId = null) {
  const [ project, setProject ] = useState(createInitialState);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchProjectData = (abortController) => {
    if (projectId) {
      getEntities(
        '/projects',
        {ids: projectId},
        abortController,
        ).then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setProject(flattenRecord(response[0]));
          setIsLoading(false);
      }).catch(errorMessageHandler);
    } else {
      if (objectId) {
        setProject((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId
        }));
      }
      setIsLoading(false);
    }
  };
  /**
   * useEffect should have a dependency on projectId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchProjectData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ projectId ]);

  return {
    project,
    isLoading,
    fetchProjectData
  };
}
