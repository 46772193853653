import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { noop, processHasPatternInputValue } from "../../utils/utils";
import { FIELD_DEFAULT_VALUE } from "./constants";
import IndeterminateCheckBoxTwoToneIcon from "@mui/icons-material/IndeterminateCheckBoxTwoTone";
import InputFieldType from "../../enums/InputFieldType";
import { withConfig } from "../../form/utils";
import { MassEditLookup } from "./MassEditLookup";
import { MassEditSignalTypeLookup } from "./MassEditSignalTypeLookup";

/**
 * getFieldByType
 * returns an object with keys that
 * can be used to select the desired
 * Component
 * @param {props} props for the component
 * @returns {Object} the object
 */
export const getFieldByType = (props) => {
  const {
    label = "",
    value,
    values,
    disabled = false,
    indeterminate = false,
    onChange = noop.fn,
    type = "",
    sx,
    required = false,
    objectId,
    projectId,
    hasPattern,
    ...rest
  } = props;
  return {
    [InputFieldType.BOOLEAN]: (
      <FormControlLabel
        disabled={disabled}
        labelPlacement='end'
        sx={sx}
        control={
          <Checkbox
            onChange={(e) => {
              onChange({
                id: e.target.id,
                value: e.target.checked,
                indeterminate: false,
              });
            }}
            checked={value || false}
            indeterminate={indeterminate}
            indeterminateIcon={
              <IndeterminateCheckBoxTwoToneIcon color='disabled' />
            }
            inputProps={{
              // hardcode type
              type: "checkbox",
            }}
            color={indeterminate ? "default" : "primary"}
            {...rest}
          />
        }
        label={label}
      />
    ),
    string: (
      <TextField
        label={label}
        value={value ?? ""}
        disabled={disabled}
        sx={sx}
        // hardcode type
        type='text'
        InputLabelProps={{
          // keep the label above the value
          shrink: true,
        }}
        onChange={(e) => {
          onChange({
            id: e.target.id,
            value: processHasPatternInputValue(e.target.value, hasPattern),
          });
        }}
        {...rest}
      />
    ),
    [InputFieldType.NUMBER]: (
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        sx={sx}
        // fields with non numeric values should be rendered as text fields
        type={isNaN(value) ? "text" : type}
        InputLabelProps={{
          // keep the label above the value
          shrink: true,
        }}
        // If we need to render a default string value in number field
        // we need to change the type to text initially
        // and then change it back to number on click
        // and then back to text on blur again if user doesn't enter a number
        // otherwise we can't render the default value in the number field
        onClick={(e) => {
          if (value === FIELD_DEFAULT_VALUE || value === "") {
            e.target.type = "number";
          }
        }}
        onBlur={(e) => {
          if (isNaN(value)) {
            e.target.type = "text";
          }
        }}
        onChange={(e) => {
          onChange({
            id: e.target.id,
            value: e.target.value,
          });
        }}
        {...rest}
      />
    ),
    [InputFieldType.DATE]: (
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        sx={sx}
        type={type}
        InputLabelProps={{
          // keep the label above the value
          shrink: true,
        }}
        onChange={(e) => {
          onChange({ id: e.target.id, value: e.target.value });
        }}
        {...rest}
      />
    ),
    [InputFieldType.LOOKUP]: (
      <Autocomplete
        options={values}
        sx={sx}
        disabled={disabled}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            InputLabelProps={{
              // keep the label above the value
              shrink: true,
            }}
            label={required ? `${label} (required)` : label}
          />
        )}
        onChange={(e, value) => {
          if (required && !value) return; // prevent user sending empty value for required field, otherwise it will be rejected by the server
          onChange({ id: props.id, value: value ? value.value : "" });
        }}
        {...rest}
      />
    ),
    [InputFieldType.SIGNAL_TYPES]: withConfig({})(MassEditSignalTypeLookup)({
      ...props,
    }),
    [InputFieldType.CABLE_TYPES]: withConfig({
      route: "/cable-types?withDeleted=false",
      filterFn: (item) => {
        return item.type.length > 0;
      },
      getOptionLabelParam: "type",
    })(MassEditLookup)({
      ...props,
    }),

    [InputFieldType.CABLE_BUNDLES_TYPES]: withConfig({
      route: `/cable-bundles?filters=${encodeURIComponent(
        JSON.stringify({
          object_id: {
            value: objectId,
          },
        })
      )}`,
      filterFn: (item) => {
        return typeof item?.number === "number";
      },
      getOptionLabelParam: "number",
    })(MassEditLookup)({ ...props }),
    [InputFieldType.DRUM_TYPES]: withConfig({
      route: "/drums",
      withDeleted: false,
      getOptionLabelParam: "number",
      dependencies: { objectId, projectId },
    })(MassEditLookup)({ ...props }),
    [InputFieldType.ORDER_TYPES]: withConfig({
      route: "/orders",
      withDeleted: false,
      getOptionLabelParam: "number",
      dependencies: { objectId, projectId },
    })(MassEditLookup)({ ...props }),
  };
};
