import request from 'superagent';
import log from 'loglevel';
import msalInstance from './../msalInstance';
import { loginRequest } from '../authConfig';

import { API_ROOT } from '../config';

// Support auto-parsing of text/csv to make sure we have a
// body in the response
request.parse['text/csv'] = (str) => {
  return str;
};

// Support auto-parsing of application/xlsx
// to make sure we have a body in the response
request.parse['application/xlsx'] = (res) => res;

/**
 * A function to fetch authentication token from the MSAL instance
 * using the idToken
 */
async function getIdToken() {
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
  });
  return response.idToken;
}

/**
 * A function to add the authentication token to every request
 *
 * @private
 * @param {request} requestObject A request in the making
 */
async function userIdentificationModifier(requestObject) {
  const token = await getIdToken();
  return requestObject
      .set({
        Authorization: `Bearer ${token}`,
      });
}

/**
 * A simple utility function that recursively applies all requestModifers
 * by calling them in order. (optimised for tail-end recursion)
 *
 * @private
 * @param {request} requestObject A request in the making
 * @param {requestModifier[]} modifiers An array of request modifiers
 * @returns {request} The modified request object
 */
function modifyRecursive(requestObject, modifiers) {
  if (modifiers && modifiers.length > 0) {
    return modifyRecursive(
        modifiers.pop()(requestObject),
        modifiers,
    );
  }
  return requestObject;
}

/**
 * A simple wrapper to 'unwrap' a response, streight to body.
 *
 * @private
 * @param {response} requestObject A JSON type response
 * @returns {Object<string, any>} Returns the body of a response
 */
async function resultHandler(requestObject) {
  return requestObject
      .then((response) => response.body)
  ;
}

/**
 * This type of 'callback' is used to modify an active request. It can
 * be used to add headers, or request parameters, for example.
 *
 * @callback requestModifier
 * @param {request} request The active request
 * @returns {request} the modified request
 */

/**
 * This wrapper deals with execution of any modifiers to the request.
 * It also is reponsible for injecting auth-tokens when required.
 *
 * @param {request} requestObject The current request being processed
 * @param {requestModifier[]} [modifiers] Modifier functions
 * @returns {request} The modified request.
 */
async function runRequestModifers(requestObject, modifiers = []) {
  const requestModifiers = [
    userIdentificationModifier,
    ...modifiers,
  ].filter((mod) => !!mod);
  return modifyRecursive(requestObject, requestModifiers);
}

/**
 * Send a request to the API, no body, no default resolve to body of response.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<response>} Resolves to response, or throws on server error
 */
function createRawRequest(method, path, abortController, modifiers = []) {
  const actualRequest = runRequestModifers(
      request[method](`${API_ROOT}${path}`),
      [
        ...modifiers,
        (req) => {
          req.on('progress', () => {
            if (abortController?.signal?.aborted) {
              log.info('Abort signal', abortController?.signal?.aborted);
              req.xhr.abort();
              log.info('Request aborted', req.url);
            }
          });
          return req;
        },
      ],
  );
  log.trace(method.toUpperCase(), path);
  return actualRequest;
}

/**
 * Send a request to the API, no body.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
function createRequest(method, path, abortController, modifiers = []) {
  return resultHandler(
      createRawRequest(method, path, abortController, modifiers),
  );
}

/**
 * Send a request with body to the API.
 *
 * @private
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {*} body JSON or other type of body, depending on request type.
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
function createRequestWithBody(method, path, body, modifiers = []) {
  function bodyMod(req) {
    return req.send(body);
  }
  return createRequest(
      method,
      path,
      null,
      [ bodyMod, ...modifiers ],
  );
}

/**
 * Send a request to the API, with a JSON payload.
 *
 * @param {string} method The HTTP-method, usually `get` or `post`
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier[]} [modifiers] Functions to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function createRequestWithJSON(method, path, values, modifier) {
  const body = Array.isArray(values) ? values : {
    ...values,
  };

  function contentTypeJSON(requestObject) {
    return requestObject
        .set('Content-Type', 'application/json')
    ;
  }

  return createRequestWithBody(
      method,
      path,
      body,
      [ modifier, contentTypeJSON ],
  );
}

/**
 * Send a `PATCH` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function patch(path, values, modifier) {
  return createRequestWithJSON('patch', path, values, modifier);
}

/**
 * Send a `DELETE` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function del(path, values, modifier) {
  return createRequestWithJSON('delete', path, values, modifier);
}

/**
 * Send a `PUT` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function put(path, values, modifier) {
  return createRequestWithJSON('put', path, values, modifier);
}

/**
 * Send a `POST` request to the API, with a JSON payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function post(path, values, modifier) {
  return createRequestWithJSON('post', path, values, modifier);
}

/**
 * Send a `GET` request to the API, without payload.
 *
 * @param {string} path The path on the API to send a request to
 * @param {Array|object} values Either an array of values, or a JSON object
 * @param {requestModifier} [modifier] A function to modify the request before
 *        it gets sent.
 * @returns {Promise<object>} Resolves response.body, or throws on server error
 */
async function get(path, abortController, modifier) {
  return createRequest('get', path, abortController, [ modifier ]);
}

export {
  patch,
  put,
  del, // delete is a reserved keyword...
  post,
  get,
  createRequest,
  createRawRequest,
};
