import React from "react";
import Alert from "@mui/material/Alert";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";

// This component is meant to be used everywhere
// a restriction based on user permission is needed
const Restricted = ({ to, entity, children }) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useUserPermissionsContext();

  const allowed = isAllowedTo(to, entity);

  // If the user has that permission, render the children
  if (allowed) {
    return <>{children}</>;
  }

  // Otherwise, render the fallback
  return (
    <Alert severity='warning'>You are not allowed to view this entity</Alert>
  );
};

export default Restricted;
