import { Stack } from "@mui/material";
import { noop } from "../../utils/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { formatLabel } from "../../form/utils";

export const CellHeader = ({
  name = "",
  unit = undefined,
  description = "",
  handleClick = noop.fn,
  inlineEditable,
}) => {
  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      justifyContent='flex-start'
    >
      {formatLabel({ label: name, unit })}
      {inlineEditable && (
        <ModeEditOutlineOutlinedIcon
          fontSize='small'
          color='disabled'
          sx={{ ml: 1 }}
          data-testid='inline-edit-icon'
        />
      )}
      <svg
        id='icon'
        onClick={handleClick(description)}
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        data-testid='trigger'
        viewBox='0 0 32 32'
      >
        {/* eslint-disable-next-line max-len */}
        <polygon points='17 22 17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22' />
        <path d='M16,8a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,8Z' />
        {/* eslint-disable-next-line max-len */}
        <path d='M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z' />
      </svg>
    </Stack>
  );
};
