import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { fetchUserPermissions } from "./dao/user";
import { GlobalContext } from "./globalContext";
import { errorMessageHandler } from "./utils/error";
import { noop } from "./utils/utils";
// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour = {
  isAllowedTo: () => noop.fn(),
  isLoading: true,
};

// Create the context
const UserPermissionContext = React.createContext(defaultBehaviour);

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
export function UserPermissionProvider({ children }) {
  const [authorisation, setAuthorisation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const {
    user: { username },
  } = useContext(GlobalContext);

  const fetchPermission = (email) => {
    // call endpoint to get permisssions
    fetchUserPermissions(email)
      .then((userPermissions) => {
        setAuthorisation(userPermissions);
      })
      .catch((error) => {
        setHasError(true);
        errorMessageHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (username !== "Anonymous") {
      fetchPermission(username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  // Creates a method that returns whether the requested permission
  // is available in the list of permissions passed as parameter
  const isAllowedTo = useCallback(
    (requestedRight, entity) => {
      if (authorisation.length > 0) {
        const permissions = authorisation.reduce((acc, role) => {
          return [].concat(acc, role.rights);
        }, []);

        const isAllowed = Boolean(
          permissions.find(
            (right) =>
              // The export right isn't connected to an entity
              // therefore we check whether the right is stand-alone
              right.name ===
              (entity ? `${requestedRight}-${entity}` : requestedRight)
          )
        );
        return isAllowed;
      }
      return false;
    },
    [authorisation]
  );
  const value = useMemo(() => {
    return { isAllowedTo, isLoading, authorisation, hasError };
  }, [isAllowedTo, isLoading, authorisation, hasError]);

  // This component will render its children
  // wrapped around a UsePermissionContext's provider whose
  // value is set to the method defined above
  return (
    <UserPermissionContext.Provider value={value}>
      {children}
    </UserPermissionContext.Provider>
  );
}

export default UserPermissionContext;
