import { post } from "../api/api";

export const ASSIGN_PATH = "/assign";

export const assignEntity = async (entity, assignTo, payload) => {
  if (!payload || !Array.isArray(payload) || !entity || !assignTo) {
    throw new Error("Invalid payload or entity name.");
  }

  const request = {
    entity: {
      type: entity,
      ids: payload.map((item) => item.id),
    },
    assign_entity_to: {
      type: assignTo.entityName,
      id: assignTo.id,
    },
  };

  return post(ASSIGN_PATH, request);
};
