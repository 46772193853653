import log from "loglevel";
import { ACTION_ON_FIELD_CHANGE, ACTION_ON_FORM_RESET } from "./constants";
import { createInitialState } from "./utils";

/**
 * reducer
 * @param {any} state
 * @param {any} action
 * @returns {any}
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_ON_FIELD_CHANGE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          value: action.payload.value,
          isPristine: false,
          indeterminate: action.payload.indeterminate,
        },
        formSubmitDisabled: false,
      };

    case ACTION_ON_FORM_RESET:
      return createInitialState(
        Object.entries(state)
          // filter out formSubmitDisabled field
          .filter(([key]) => key !== "formSubmitDisabled")
          // create a new array of fields
          .reduce((acc, [key, value]) => {
            return [...acc, { field: key, type: value.meta.type }];
          }, [])
      );

    default:
      log.info("no action detected");
      return state;
  }
};
