import React from "react";
import { Box } from "@mui/system";
import { theme } from "./../setting";
import getGitInfo from "react-git-info/macro";

const Footer = () => {
  const gitInfo = getGitInfo();
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        paddingX: 2,
        paddingY: 0.5,
        color: "white",
        backgroundColor: theme.palette.primary.main,
        zIndex: 1201,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>&#169; Bakker Sliedrecht</span>
      <span>{`Oasis version: ${gitInfo.commit.shortHash}`}</span>
    </Box>
  );
};

export default Footer;
