import { del, patch, post } from "../api/api";
import { getInlineEditQueryParam } from "../utils/utils";

/**
 * Create
 *
 * @param {object} data
 * @param {object} options
 * @param {string} options.route
 * @returns {Promise<object>} - response object
 */
export async function create(data, options) {
  // guard clause
  if (!data || !options || !options.route) {
    throw new Error("Invalid input");
  }
  const body = await post(options.route, data);
  return body?.response;
}

/**
 * Update
 *
 * @param {object} data
 * @param {string} data.id
 * @param {object} options
 * @param {string} options.route
 * @param {boolean} options.isInlineEdit
 * @returns {Promise<object>} - response object
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233427/}
 */
export async function update(data, options) {
  // guard clause
  if (!data || !data.id || !options || !options.route) {
    throw new Error("Invalid input");
  }
  return patch(
    [options.route, data.id].join("/") +
      getInlineEditQueryParam(options.isInlineEdit),
    data
  );
}

/**
 * Soft deletes a record based on the provided options.
 * @param {Object} options - The options for the soft delete operation.
 * @param {string} options.route - The route for the API endpoint.
 * @param {boolean} options.delete - The flag indicating whether to perform the delete operation.
 * @returns {Promise<any>} - A promise that resolves to the response body of the delete operation.
 * @throws {Error} - If the input is invalid.
 */
export const softDelete = (options) => async (data) => {
  // guard clause
  if (
    !data ||
    !data.id ||
    !options ||
    !options.route ||
    typeof options.delete === "undefined"
  ) {
    throw new Error("Invalid input");
  }
  const body = await patch([options.route, data.id].join("/"), {
    delete: options.delete,
  });
  return body.response;
};

/**
 * Deletes a record from the server.
 *
 * @param {Object} options - The options for the delete operation.
 * @param {string} options.route - The route for the delete operation.
 * @returns {Promise<any>} - A promise that resolves with the response from the server.
 * @throws {Error} - If the input is invalid.
 */
export const hardDelete = (options) => async (data) => {
  // guard clause
  if (!data || !data.id || !options || !options.route) {
    throw new Error("Invalid input");
  }
  const body = await del([options.route, data.id].join("/"));
  return body.response;
}
