export const ROUTES = {
  object: {
    edit: "/object/:objectId",
    create: "/objects/create",
    list: "/objects",
  },
  project: {
    edit: "/project/:projectId",
    create: "/projects/create",
    list: "/projects",
  },
  system: {
    edit: "/system/:systemId",
    create: "/systems/create",
    list: "/systems",
  },
  systemGroup: {
    edit: "/system-group/:systemGroupId",
    create: "/system-groups/create",
    list: "/system-groups",
  },
  location: {
    edit: "/location/:locationId",
    create: "/locations/create",
    list: "/locations",
  },
  equipment: {
    edit: "/equipment/:equipmentId",
    create: "/equipments/create",
    list: "/equipments",
  },
  section: {
    edit: "/section/:sectionId",
    create: "/sections/create",
    list: "/sections",
  },
  cable: {
    edit: "/cable/:cableId",
    create: "/cables/create",
    list: "/cables",
  },
  cableType: {
    edit: "/cable-type/:cableTypeId",
    create: "/cable-types/create",
    list: "/cable-types",
  },
  bundle: {
    edit: "/bundle/:id",
    create: "/bundles/create",
    list: "/bundles",
  },
  routePoints: {
    edit: "/route-point/:routePointId",
    create: "/route-points/create",
    list: "/route-points",
  },
  drums: {
    edit: "/drum/:drumId",
    create: "/drums/create",
    list: "/drums",
  },
  order: {
    edit: "/order/:orderId",
    create: "/orders/create",
    list: "/orders",
  },
};
