import { get } from "../api/api";

export const PERMISSIONS_API_PATH = "/roles";
/**
 * fetchUserPermissions

 * @param {string} email
 * @returns {Promise} the response or an error
 */
export async function fetchUserPermissions(email) {
  if (typeof email === "undefined" || email === "") {
    return [];
  }

  const params = new URLSearchParams();
  params.append("user", email);

  const body = await get(`${PERMISSIONS_API_PATH}?${params.toString()}`);
  if (body?.errors?.length > 0) {
    throw new Error(
      body.errors.reduce((acc, item) => `${acc}, ${item.name}`, "")
    );
  }

  return body?.response;
}
