import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
  };
}

export default function useSystemGroup(systemGroupId = null, objectId = null) {
  const [ systemGroup, setSystemGroup ] = useState(createInitialState);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchSystemGroupData = (abortController) => {
    if (systemGroupId) {
      return getEntities(
        '/system-groups',
        {
          object_ids: objectId,
          ids: systemGroupId,
        },
        abortController,
      ).then((res) => {
        const response = res.response;
        // guard
        if (response.length === 0) {
          return;
        }
        setSystemGroup(response[0]);
        setIsLoading(false);
      }).catch(errorMessageHandler);
    } else {
      if (objectId) {
        setSystemGroup((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
        }));
      }
      setIsLoading(false);
    }
  };

  /**
   * useEffect should have a dependency on number
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchSystemGroupData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ systemGroupId ]);

  return {
    systemGroup,
    fetchSystemGroupData,
    isLoading
  };
}
