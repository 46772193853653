import React from "react";

import AppBarHeader from "./AppBarHeader";
import DrawerMenu from "./DrawerMenu";
import Footer from "./Footer";
import { Box } from "@mui/system";

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        height: "100vh",
      }}
    >
      {/* Extra div prevents a null-check error in the UI */}
      <div>
        <AppBarHeader />
      </div>

      <DrawerMenu />
      <Box
        sx={{
          position: "relative",
          paddingTop: 2,
          paddingRight: 2,
          paddingLeft: 4,
          maxWidth: "100vw",
          width: "100%",
          overflow: "auto",
          marginTop: "4rem",
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
