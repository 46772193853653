import { get } from "../api/api";
import { FILTER_TYPES } from "./types";

export const API_PATH = "/fields";
export { FILTER_TYPE, FILTER_TYPES } from "./types";

/**
 * @typedef SelectValue
 * @type object
 * @property {string} value
 * @property {string} label
 */

/**
 * @typedef Field
 * @type object
 * @property {string} label
 * @property {string} field_name
 * @property {string} sort_name
 * @property {string} description
 * @property {string} data_type
 * @property {string[]} types
 * @property {boolean} required
 * @property {string} default_value
 * @property {{
 *   overview: number,
 *   form: number,
 * }} sort_order
 * @property {number} min_length
 * @property {number} max_length
 * @property {object} visible
 * @property {SelectValue[]} [values]
 * @property {boolean} filterable
 * @property {boolean} mass_editable
 * @property {boolean} inline_editable
 * @property {boolean} has_pattern
 */

/**
 * Fetch fields, optionally filtered by `FILTER_TYPES`.
 *
 * @param {string} [targetType] Target type to filter by (`FILTER_TYPES`)
 * @returns {Promise<Field[]>} Set of known fields
 */
export async function fetchFields(targetType, abortController) {
  const params = new URLSearchParams();
  if (targetType) {
    if (!Object.values(FILTER_TYPES).includes(targetType)) {
      throw new Error(
        `Type '${targetType}' is not valid. (Valid options: ${Object.values(
          FILTER_TYPES
        )})`
      );
    }
    params.append("type", targetType);
  }
  const body = await get(`${API_PATH}?${params.toString()}`, abortController);
  return body?.response;
}
