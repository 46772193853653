import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";
import useLocation from "../hooks/useLocation";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";

import {
  addLocation,
  softDeleteLocation,
  patchLocation,
  restoreLocation,
} from "../dao/locations";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";

export default function LocationDetail({ title = "", mode = "" }) {
  const history = useHistory();

  // update record only
  // get locationId from the search params
  const { locationId } = useParams();

  // create record only
  // get objectId from the search params
  const { objectId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.location
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.location
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.location
  );

  const {
    location,
    fetchLocationData,
    isLoading: recordIsLoading,
  } = useLocation(locationId, objectId);

  const [fields] = useFields(
    FILTER_TYPES.location,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({
        objectId: location.object_id,
        systemGroupId: location.system_group_id,
      }).filter(([, value]) => value)
    ).toString();
  }, [location.object_id, location.system_group_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeLocation(payload) {
    if (isUpdateMode) {
      // Existing Location
      log.trace("about to patch", location.id);
      await patchLocation(payload, location.id).then(() => {
        fetchLocationData();
      });
    } else {
      // Brand new Location
      log.trace("about to create", JSON.stringify(location));
      return addLocation(payload).then((res) => {
        history.push(["/location", res.id].join("/"));
      });
    }
  }

  async function removeLocation() {
    if (isUpdateMode) {
      return softDeleteLocation(location).then(() => {
        fetchLocationData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchLocationData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedLocation() {
    restoreLocation(location).then(() => {
      fetchLocationData();
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.location}
            to={addSearchParams(ROUTES.location.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: location,
            fields,
            storeEntity: storeLocation,
            deleteEntity: removeLocation,
            entityType: FILTER_TYPES.location,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedLocation,
            isUpdateMode,
          }}
        />
      )}
      {isUpdateMode && (
        <EntityOverview
          entity={FILTER_TYPES.equipment}
          title={"Equipments overview"}
          nestedEntity={true}
          parent={{
            object_id: location.object_id,
            location_id: location.id,
          }}
        />
      )}
    </Paper>
  );
}
