import { useState, useEffect } from "react";
import { getEntities } from "../dao/common";
import FieldName from "./../enums/FieldName";
import { errorMessageHandler } from "../utils/error";
import { flattenRecord } from "../utils/utils";

/**
 * state initialization functionp
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
  };
}

/**
 * useRoutePoint
 * @param {string} objectId
 * @param {string} routePointId
 * @returns
 */
export default function useRoutePoint(objectId = null, routePointId = null) {
  const [routePoint, setRoutePoint] = useState(createInitialState);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRoutePointData = (abortController) => {
    if (routePointId) {
      getEntities("/route-points", { ids: routePointId }, abortController)
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setRoutePoint(response[0]);
        })
        .catch(errorMessageHandler)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (objectId) {
        setRoutePoint((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
        }));
      }
      setIsLoading(false);
    }
  };
  /**
   * useEffect should have a dependency on routePointId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchRoutePointData(abortController);
    return () => {
      abortController.abort();
    };
  }, [routePointId]);

  return {
    routePoint,
    isLoading,
    fetchRoutePointData,
  };
}
