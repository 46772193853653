import { useReducer } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import compose from "compose-function";

import { noop } from "../../utils/utils";
import { DIALOG_EDIT_TOGGLE } from "../../components/TableComponent";
import {
  areAllItemsSame,
  createInitialState,
  getTabIndex,
  isMassEditable,
  sanitizer,
  sanitizeValue,
  transformValue,
  transformFormStateForCableDropdown,
} from "./utils";
import { MassEditDialogTitle } from "./MassEditDialogTitle";
import {
  ACTION_ON_FIELD_CHANGE,
  ACTION_ON_FORM_RESET,
  NUMBER_OF_RECORDS_BLOCK,
  FIELD_DEFAULT_VALUE,
} from "./constants";
import { reducer } from "./reducer";
import { getFieldByType } from "./getFieldByType";
import InputFieldType from "../../enums/InputFieldType";
import { FILTER_TYPES } from "../../dao/types";

const DIALOG_DESCRIPTION =
  "These fields correspond to the visible columns and selected rows in the overview screen.";

/**
 * handleClickClear
 *
 * Clears the value of a specified form field based on its type.
 * The function returns another function that dispatches the action
 * to clear the field's value and set it to the appropriate default based
 * on the field's type (e.g., boolean, string, number, date).
 *
 * @param {Function} dispatchForm - The dispatch function to update the form state.
 * @param {Object} data - Object containing details about the field to be cleared.
 * @param {string} data.id - The ID of the field being cleared.
 * @param {string} data.type - The type of the field (e.g., boolean, string, number, etc.).
 * @param {boolean} [data.hasPattern] - Optional. Specifies if the field has a pattern constraint.
 *
 * @returns {Function} A function that dispatches the action to clear the field's value.
 */
export const handleClickClear = (dispatchForm, data) => {
  const fieldId = data.id;

  let stringValue;
  if (data.id === "revision") {
    stringValue = "0.0";
  } else if (data.hasPattern) {
    stringValue = null;
  } else {
    stringValue = "";
  }

  const EMPTY_VALUES = {
    boolean: false,
    string: stringValue,
    number: "",
    date: "",
    [InputFieldType.CABLE_TYPES]: "",
    [InputFieldType.CABLE_BUNDLES_TYPES]: null,
    [InputFieldType.DRUM_TYPES]: null,
    [InputFieldType.ORDER_TYPES]: null,
  };

  return function () {
    dispatchForm({
      type: ACTION_ON_FIELD_CHANGE,
      payload: {
        id: fieldId,
        value: EMPTY_VALUES[data.type],
        indeterminate: false,
      },
    });
  };
};

export const MassEditDialog = ({
  fields = [],
  rows = [],
  visible = false,
  handler = noop.fn,
  setVisible = noop.fn,
  entityType = "",
}) => {
  // derive massEditFields from fields prop
  const massEditFields = fields
    // filter only field that can be mass edited
    .filter(isMassEditable)
    .reduce((agg, cur) => {
      const isAllValuesAreSame = areAllItemsSame(rows, cur.field);
      // If values in selected rows are equal, we show the corresponding value in the fields.
      if (isAllValuesAreSame) {
        return [...agg, { ...cur, value: rows[0][cur.field] }];
      } else {
        if (cur.type === "boolean") {
          return [...agg, { ...cur, indeterminate: true }];
        }
        return [...agg, { ...cur, value: FIELD_DEFAULT_VALUE }];
      }
    }, []);

  const objectId = rows[0]?.object_id;
  const projectId = rows[0]?.project_id;

  // =========================
  // State
  // =========================
  const [formState, dispatchForm] = useReducer(
    reducer,
    massEditFields,
    // create initial state dynamically
    createInitialState
  );

  // =========================
  // Event Handlers
  // =========================

  function handleClose() {
    // Close Dialog
    setVisible({ type: DIALOG_EDIT_TOGGLE, payload: { visible: false } });
    // Reset State
    dispatchForm({ type: ACTION_ON_FORM_RESET });
  }

  function handleSubmit(e) {
    e.preventDefault();

    let updatedFormState = formState;

    if (entityType === FILTER_TYPES.cable) {
      updatedFormState = transformFormStateForCableDropdown(updatedFormState);
    }

    const updatedFields = Object.entries(updatedFormState)
      // filter out items that have been updated
      .filter(([, value]) => value.isPristine === false)
      // create an object of the required shape
      .reduce((acc, item) => {
        return {
          ...acc,
          ...compose(transformValue, sanitizeValue(sanitizer))(item),
        };
      }, {});
    // send it to the handler
    handler({
      ids: rows.map((row) => row.id),
      type: entityType,
      fields: updatedFields,
    });
    // Close Dialog
    setVisible({ type: DIALOG_EDIT_TOGGLE, payload: { visible: false } });
    // Reset State
    dispatchForm({ type: ACTION_ON_FORM_RESET });
  }

  function handelChange(data) {
    dispatchForm({
      type: ACTION_ON_FIELD_CHANGE,
      payload: {
        id: data.id,
        value: data.value,
        indeterminate: data.indeterminate,
      },
    });
  }

  // =========================
  // Render
  // =========================

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      scroll={"paper"}
      fullWidth={true}
      aria-labelledby='Edit multiple records'
      aria-describedby={DIALOG_DESCRIPTION}
      data-testid='editDialog'
      maxWidth='sm'
    >
      <DialogTitle
        id='dialog-title'
        data-testid='dialogTitle'
        sx={{
          paddingTop: "1.5rem",
        }}
      >
        <MassEditDialogTitle
          numRecords={rows.length}
          message={DIALOG_DESCRIPTION}
        />
      </DialogTitle>
      <DialogContent dividers={"paper"}>
        <DialogContentText>
          {/* Form element needs to be inside the DialogContentText
           otherwise the paper scroll effect breaks */}
          <form onSubmit={handleSubmit}>
            {rows.length <= NUMBER_OF_RECORDS_BLOCK && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                {massEditFields
                  // iterate over fields rendering the correct component
                  .map(
                    (
                      {
                        field,
                        title,
                        required = false,
                        type,
                        values,
                        hasPattern,
                      },
                      i
                    ) => {
                      const fieldTabIndex = getTabIndex(i)(0);
                      const buttonFieldIndex = getTabIndex(i)(1);
                      const actualType = values ? InputFieldType.LOOKUP : type;
                      const Field = getFieldByType({
                        disabled: formState[field].disabled,
                        indeterminate: formState[field].indeterminate,
                        id: field,
                        type,
                        label: title || "",
                        required,
                        tabIndex: fieldTabIndex,
                        onChange: handelChange,
                        sx: {
                          flexGrow: 4,
                        },
                        value: formState[field].value,
                        values,
                        objectId,
                        projectId,
                        hasPattern,
                      })[actualType];
                      return (
                        <Box
                          key={field}
                          sx={{
                            margin: "0 0 2rem",
                            paddingLeft: "0.5rem",
                            gap: 2,
                            display: "grid",
                            gridTemplateColumns: "5fr 1fr",
                            borderRadius: "4px",
                            borderLeftWidth: 4,
                            borderLeftStyle: "solid",
                            borderLeftColor: formState[field].isPristine
                              ? "#E7E7E7"
                              : "#00904b",
                            alignItems: "center",
                          }}
                        >
                          {/** Field as an expression */}
                          {Field}
                          {type !== InputFieldType.DATE && (
                            <Button
                              variant='contained'
                              color='grey'
                              tabIndex={buttonFieldIndex}
                              id={field}
                              disabled={formState[field].disabled || required}
                              size='small'
                              onClick={() =>
                                handleClickClear(dispatchForm, {
                                  id: field,
                                  type,
                                  hasPattern,
                                })()
                              }
                              data-testid='clearButton'
                              sx={{ width: "80%" }}
                            >
                              Clear
                            </Button>
                          )}
                        </Box>
                      );
                    }
                  )}
              </Box>
            )}
            {/* Submit button needed here for implicit form submission (onEnterKey) */}
            <Button sx={visuallyHidden} type='submit'>
              Submit
            </Button>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "0.75rem 1.5rem",
        }}
      >
        <Button
          onClick={handleClose}
          color='primary'
          tabIndex={getTabIndex(100)(0)}
          data-testid='cancelButton'
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          tabIndex={getTabIndex(100)(1)}
          data-testid='submitButton'
          disabled={formState.formSubmitDisabled}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
