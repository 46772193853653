import compose from "compose-function";
import { OPERATOR_DICTIONARY } from "../enums/FilterOperators";
import {
  getGridDateOperators,
  getGridStringOperators,
  getGridBooleanOperators,
  getGridNumericOperators,
} from "@mui/x-data-grid-pro";
import {
  excludedDateOperators,
  excludedNumericOperators,
  excludedStringOperators,
  stringOperatorsForNumber,
} from "../components/filters/customFilters";
import { reorderOperators } from "./array";
import InputFieldType from "../enums/InputFieldType";

export const OASIS_STORAGE_KEY = "_oasis_";

/**
 * Set min width of the columns
 * @param {Object} item
 * @returns {Object}
 */
export const addWidthField = (item) => ({
  ...item,
  minWidth: 50,
});

/**
 * curried Fn that returns an row object
 * @returns {object} the row object
 */
export const addValueFormatter = (field) => (formatterFn) => (row) => {
  if (field !== row.field) {
    return row;
  }
  return {
    ...row,
    valueFormatter: (params) => {
      return formatterFn(params.value);
    },
  };
};

/**
 * curried Fn that returns a padded string
 * @returns {string} a string padded with "0"
 */
export const padValueWith = (paddingAmount) => (value) => {
  // Guard
  if (value === "" || typeof value === "undefined") {
    return value;
  }
  return String(value).padStart(paddingAmount, "0");
};

/**
 * Adds href field that set to the value of concatenated
 * segments object
 * @param {Object} segments
 * @returns {Object}
 */
export const addHrefToRow = (segment) => (row) => {
  // guard clause
  if (typeof row[segment.field] === "undefined") {
    return row;
  }

  return {
    ...row,
    href: `/${segment.slug}/${encodeURIComponent(row[segment.field])}`,
  };
};

/**
 * Converts the 'is_deleted' value to a boolean for the table
 * @param {string} field
 * @returns {Object}
 */
export const changeDeletedToBoolean = (field) => (row) => {
  const is_deleted = Boolean(row.hasOwnProperty(field));
  return {
    ...row,
    is_deleted,
  };
};

/**
 * adds reference field
 * where value is retrieved from nested fields
 * @param {Object} row
 * @returns {Object}
 */
export const addProjectReferenceField = (row) => {
  if ("project_type" in row && "number" in row) {
    return { ...row, reference: row.project_type + row.number };
  }
  return {};
};

/**
 * adds signal type reference field
 * where value is retrieved from nested fields
 * @param {Object} row
 * @returns {Object} row
 */
export const addSignalTypeReferenceField = (row) => {
  if ("signal_type" in row) {
    return { ...row, "signal_type.abbr": row.signal_type.abbr };
  }
  return row;
};

/**
 *
 * @param {Object} row
 * @returns {Object}
 */
export const addObjectNumberField = (row) => {
  return { ...row, object_number: row.object.number };
};

/**
 * curried fn that adds
 * a field valueGetter where
 * the value a fn that is passed
 * @returns {Object}
 */
export const addValueGetter = (field) => (valueGetterFn) => (row) => {
  if (row.field === field) {
    return { ...row, valueGetter: valueGetterFn };
  }
  return row;
};

/**
 * curried fn for retrieving
 * fields on client property
 * @param {Object} params
 * @returns {string}
 */
export const getClient =
  (field) =>
  (params = {}) => {
    if ("row" in params && "client" in params.row) {
      return params.row.client[field] || "";
    }
    return "";
  };

/**
 * Gets a regex object that can be used
 * for filtering
 * @param {object} param - a param object
 * @returns {RegExp} the regex object
 */
export const getFilterRegex = (param) => {
  switch (param.type) {
    case "starts":
      return new RegExp(`^${param.value}`, "i");
    case "ends":
      return new RegExp(`${param.value}$`, "i");
    case "contains":
      return new RegExp(param.value, "i");
    default:
      return new RegExp(param.value, "i");
  }
};

/**
 * Selects filtered rows
 * @param {object[]} param=[]
 * @param {object[]} data=[]
 * @returns {object[]}
 */
export const selectFilteredRows = (param = [], data = []) => {
  return data.filter((row) => {
    return recurseValues(row).some((value) => {
      const regex = getFilterRegex(param);
      // reassign value null to empty string
      value = value ?? "";
      return regex.test(value.toString());
    });
  });
};

/**
 * Factory fn that creates search param objects
 * @param {string} query
 * @returns {object} - search param
 */
export const filterParamFactory = (query = "") => {
  let type = "contains";

  if (query.endsWith("*")) {
    type = "starts";
  }
  if (query.startsWith("*")) {
    type = "ends";
  }
  return {
    type: type,
    value: query.replace(/[()*?\\]+/g, ""),
  };
};

/**
 * recursive fn that returns values
 * of a nested object
 * @param {object[]} data
 * @returns {string[]} - the values from the object
 */
export function recurseValues(data) {
  return Object.values(data).reduce((acc, value) => {
    if (value instanceof Object) {
      return [...acc, ...recurseValues(value)];
    }
    return [...acc, value];
  }, []);
}

/**
 * fn gets returns the value field of an object
 * and returns an empty string if value is undefined
 * @param {object} paramObj
 * @returns {string} the value as String
 */
export const getFitlerValue = (paramObj) => {
  if (Array.isArray(paramObj.value)) {
    return paramObj.value.map((item) => String(item.value));
  }
  return paramObj.value ?? "";
};

/**
 * Curried function that takes valueString and operatorType
 * as values and returns an appropriate string
 * @param {string} operatorType
 * @param {string} valueString
 * @returns {string} the string + operator combination
 */

export const addOperatorToString = (operatorType) => (valueString) => {
  switch (operatorType) {
    case "startsWith":
      return valueString === "" ? valueString : `${valueString}*`;
    case "endsWith":
      return valueString === "" ? valueString : `*${valueString}`;
    default:
      return valueString;
  }
};

/**
 * reducer function that returns an object in the desired shape
 * @param {object} acc - the accumulator
 * @param {object} param - the param
 * @returns {object} the filter object
 */
export const filterReducer = (acc, param) => {
  if (!param?.columnField) {
    return acc;
  }

  switch (param.operatorValue) {
    case "betweenDates":
    case "betweenNumbers":
      return {
        ...acc,
        ...(param.value?.from || param.value?.to
          ? {
              [param.columnField]: {
                from: param?.value?.from || "",
                to: param?.value?.to || "",
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
    case "before":
      return {
        ...acc,
        ...(param.value?.to
          ? {
              [param.columnField]: {
                to: compose(
                  addOperatorToString(param.operatorValue),
                  getFitlerValue
                )(param),
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
    case "after":
      return {
        ...acc,
        ...(param.value?.from
          ? {
              [param.columnField]: {
                from: compose(
                  addOperatorToString(param.operatorValue),
                  getFitlerValue
                )(param),
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
    case "isNot":
      return {
        ...acc,
        [param.columnField]: {
          value: compose(
            addOperatorToString(param.operatorValue),
            getFitlerValue
          )(param),
          type: OPERATOR_DICTIONARY[param.operatorValue],
        },
      };
    case ">=":
      return {
        ...acc,
        ...(param.value
          ? {
              [param.columnField]: {
                from: param?.value || "",
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
    case "<=":
      return {
        ...acc,
        ...(param.value
          ? {
              [param.columnField]: {
                to: param?.value || "",
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
    case "isEmpty":
    case "isNotEmpty":
      return {
        ...acc,
        // empty operators don't have a value field
        // so we set it to an empty string and do not check for a value like in other cases
        [param.columnField]: {
          value: "",
          type: OPERATOR_DICTIONARY[param.operatorValue],
        },
      };
    default:
      return {
        ...acc,
        ...(param.value
          ? {
              [param.columnField]: {
                value: compose(
                  addOperatorToString(param.operatorValue),
                  getFitlerValue
                )(param),
                type: OPERATOR_DICTIONARY[param.operatorValue],
              },
            }
          : {}),
      };
  }
};

export const getDefaultOperators = (colType) => {
  switch (colType) {
    case InputFieldType.DATE:
      return getGridDateOperators();
    case InputFieldType.BOOLEAN:
      return getGridBooleanOperators();
    case InputFieldType.NUMBER:
    case InputFieldType.CABLE_BUNDLES_TYPES:
      return [...getGridNumericOperators(), ...stringOperatorsForNumber];
    default:
      return getGridStringOperators();
  }
};

// We can change the order of the operators with this array
const operatorsOrder = [
  { operator: "doesNotContain", afterWhich: "contains" },
  { operator: "equalsNot", afterWhich: "equals" },
  { operator: "containsMultiple", afterWhich: "doesNotContain" },
  { operator: "isNotEmpty", afterWhich: "betweenNumbers" }, // for  number
  { operator: "isEmpty", afterWhich: "betweenNumbers" }, // for number
  { operator: "isNotEmpty", afterWhich: "isNot" }, // for boolean
  { operator: "isEmpty", afterWhich: "isNot" }, // for boolean
];

export const addCustomOperator = (colType) => (customOperators) => (column) => {
  // Determine which operators to exclude based on the column type
  let excludedOperators;
  if (colType === InputFieldType.DATE) {
    excludedOperators = excludedDateOperators;
  } else if (
    colType === InputFieldType.NUMBER ||
    colType === InputFieldType.CABLE_BUNDLES_TYPES
  ) {
    excludedOperators = excludedNumericOperators;
  } else {
    excludedOperators = excludedStringOperators;
  }

  // Add custom and default operators to the column
  if (column["type"] === colType) {
    const defaultOperators = getDefaultOperators(colType);
    const filteredDefaultOperators = defaultOperators.filter(
      ({ value }) => ![...excludedOperators].includes(value)
    );
    const customOperatorsArr = [];
    customOperators.forEach((customOperator) => {
      customOperatorsArr.push(...customOperator);
    });
    const operators = [...filteredDefaultOperators, ...customOperatorsArr];
    const orderedOperators = reorderOperators(operators, operatorsOrder);
    return {
      ...column,
      filterOperators: orderedOperators,
    };
  }
  return column;
};

export const returnVisibleColumns = (columns) => {
  let visibleColumns = columns.map((col) => {
    return col.field;
  });
  // Remove the first (checkbox) and last (actions) column
  // As they contain no relevant data
  visibleColumns = visibleColumns.slice(1, -1);

  return visibleColumns;
};

/**
 * Generates a key for storing or retrieving data from the browser's local storage.
 * The key is composed of a base storage key (`OASIS_STORAGE_KEY`), the entity type,
 * an optional suffix based on provided options, and the entity key.
 *
 * The function constructs the key based on the following rules:
 * - If both `nestedEntity` and `assignModal` are true, no suffix is added. This kind of usage is incorrect.
 * - If only `nestedEntity` is true, the suffix "-nested" is added.
 * - If only `assignModal` is true, the suffix "-assign-modal" is added.
 * - If neither `nestedEntity` nor `assignModal` is true, no suffix is added.
 *
 * @param {string} entity - The type of entity.
 * @param {string} key - The specific key associated with the entity.
 * @param {Object} options - An object containing optional boolean properties:
 *                           - nestedEntity: whether the entity is nested.
 *                           - assignModal: whether the entity is associated with a modal assignment.
 * @returns {string} The generated local storage key.
 */
export const generateEntityKeyForLocalStorage = (entity, key, options) => {
  let optionSuffix = "";

  if (options?.nestedEntity && !options?.assignModal) {
    optionSuffix = "-nested";
  } else if (!options?.nestedEntity && options?.assignModal) {
    optionSuffix = "-assign-modal";
  }

  return `${OASIS_STORAGE_KEY}${entity}${optionSuffix}-${key}`;
};

export const saveDataToLocalStorage = (key, object) => {
  if ("localStorage" in window) {
    localStorage.setItem(key, JSON.stringify(object));
  }
};

export const readDataFromLocalStorage = (key) => {
  if ("localStorage" in window) {
    const data = localStorage.getItem(key);
    if (data !== undefined && data !== null) {
      return JSON.parse(data);
    }
    return null;
  }
};

export const getDefaultFilterModel = (key, isInModal = false) => {
  // Start by defining the base item
  const items = [
    {
      columnField: key,
      operatorValue: "contains",
      value: "",
    },
  ];

  // If isInModal is true, add an additional item to the items array
  if (isInModal) {
    items.unshift({
      columnField: "is_deleted",
      operatorValue: "is",
      value: "false",
    });
  }

  // Return the filter model structure
  return {
    filterJSON: "",
    filterModel: {
      items: items,
      init: true,
    },
  };
};

/**
 * Add editable key
 * @param {Object} item
 * @returns {Object}
 */
export const addEditableOption = (item) => {
  return {
    ...item,
    editable: !!item.inline_editable,
  };
};

export const updateRowModesModel = (id, modes, setRowModesModel) => {
  setRowModesModel((prevRowModesModel) => ({
    ...prevRowModesModel,
    [id]: {
      ...prevRowModesModel[id],
      ...modes,
    },
  }));
};

/**
 * Initialize filters for the network requests and grid
 * Conditions
 * - If there is a cachedFilterModel, use it
 * - If there isn't a cachedFilterModel, fallback to the defaultFilterModel
 * - If there is a cachedFilterJSONString, use it
 * - If there isn't a cachedFilterJSONString, fallback to an empty string
 * @param {Object} config
 * @param {Object} config.cache
 * @param {GridFilterModel} config.cache.localFilterModel
 * @param {string} config.cache.filterJSONString
 * @param {GridFilterModel} config.defaultFilterModel
 * @returns {Object} an object with the grid and api filter string
 */

export function getInitialFilters(config) {
  const { cache = {}, defaultFilterModel = {} } = config;
  return {
    grid: cache.localFilterModel ?? defaultFilterModel,
    apiFilterString: cache.filterJSONString ?? "",
  };
}

/**
 * Updates the client field of a specific row with a new value.
 *
 * @param {object} params - The parameters object containing the necessary information.
 * @param {string} params.field - The field to update in the client object (e.g., "client.name").
 * @param {object} params.row - The row object containing the client object to update.
 * @param {string} params.id - The unique identifier of the row to update.
 * @param {object} apiRef - The reference to the DataGrid API.
 * @param {object} event - The event object containing the new value.
 * @returns {boolean} A boolean indicating whether the update was successful.
 */
export const updateClientField = (params, apiRef, event) => {
  if (
    !params ||
    !params.field ||
    !params.row ||
    !params.row.client ||
    !event ||
    !apiRef ||
    !apiRef.current
  ) {
    return false; // Missing required parameters or references
  }

  const fieldName = params.field.split(".")[1];
  const rowModels = Array.from(apiRef.current.getRowModels().values());
  const spliceIndex = rowModels.findIndex(
    (rowModel) => rowModel.id === params.id
  );

  if (spliceIndex === -1) {
    return false; // Unable to find the specified row
  }

  const updatedRow = {
    ...rowModels[spliceIndex],
    client: {
      ...params.row.client,
      [fieldName]: event.target.value ?? rowModels[spliceIndex][params.field],
      // The right side of the nullish operator handles the case of a Pointer Event
      // that occurs when clicking outside of the edited cell.
      // In this case, event.target.value is undefined, so we fallback to the original value from the row model.
    },
  };

  rowModels.splice(spliceIndex, 1, updatedRow);
  apiRef.current.setRows(rowModels);

  return true; // Update successful
};

/**
 * Formatter function that takes a number as input
 * and returns a string without separators.
 * @param {number} value
 * @returns {string} the formatted value
 */
export const numberFormatter = (value) => {
  // guard
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  return value.toLocaleString("nl-NL", {
    useGrouping: false,
  });
};

/**
 * Saves the column widths to local storage
 * @param {string} entity
 * @param {object} params
 * @param {object} options
 * @param {boolean} options.nestedEntity
 * @param {boolean} options.assignModal
 */
export const handleColumnWidthChange = (entity, params, options) => {
  const storedColumnWidths = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(entity, "columnWidths", options)
  );
  const field = params.colDef.field;
  const updatedColumnWidths = {
    ...storedColumnWidths,
    [field]: params.width,
  };
  saveDataToLocalStorage(
    generateEntityKeyForLocalStorage(entity, "columnWidths", options),
    updatedColumnWidths
  );
};

/**
 * This function is used to handle the scroll and highlight of the row
 * @param {HTMLElement[]} elements
 */
export const handleRowHighlight = (elements) => {
  elements.forEach((element) => {
    if (element.classList.contains("deleted-true")) {
      element.classList.add("highlighted-row-deleted");
    } else {
      element.classList.add("highlighted-row");
    }
  });
};

/**
 * Function to get column order after pin/unpin.
 * @param {function} getColumns - Function to get the current columns configuration.
 * @param {object} updatedPinnedColumns - Object containing arrays of left and right pinned column names.
 * @returns {array} - Array of ordered columns.
 */
export const getColumnOrderAfterPin = (getColumns, updatedPinnedColumns) => {
  // define variables
  const currentColumns = getColumns();
  const leftPinnedColumnNames = updatedPinnedColumns.left;
  const rightPinnedColumnNames = updatedPinnedColumns.right;

  const excludeLeftAndRightColumns = currentColumns.filter(
    (column) =>
      !leftPinnedColumnNames.includes(column.field) &&
      !rightPinnedColumnNames.includes(column.field)
  );

  // We need the full column configuration before writing the new order
  // updatedPinnedColumns contains only the names of the pinned columns
  const leftPinnedColumns = currentColumns.filter((column) =>
    leftPinnedColumnNames.includes(column.field)
  );
  const rightPinnedColumns = currentColumns.filter((column) =>
    rightPinnedColumnNames.includes(column.field)
  );

  const orderedColumns = [
    ...leftPinnedColumns,
    ...excludeLeftAndRightColumns,
    ...rightPinnedColumns,
  ];

  // exclude actions column and first column (checkbox column)
  const clearedColumns = orderedColumns.filter(
    (column, index) => column.field !== "actions" && index !== 0
  );

  return clearedColumns;
};

/**
 * Function to disable sorting on the column header click
 * and only allow sorting via the sort button
 * There are 3 possible targets when clicking on the column header:
 * - The button which is used to sort the column
 * - The SVG element inside the button
 * - The path element inside the SVG element
 * @param { GridColumnHeaderParams } params
 * @param { MuiEvent<React.MouseEvent> } event
 * @returns {void}
 */
export const handleColumnHeaderClick = (_, event) => {
  const isSortButton = event.target?.title === "Sort";
  const isSortSvg = event.target.parentNode?.title === "Sort";
  const isSortPath = [...(event.target.parentElement.classList ?? [])].some(
    (className) => className === "MuiDataGrid-sortIcon"
  );
  // If the target is one of the elements mentioned above, do not prevent the default behavior of sorting the column
  if (isSortButton || isSortSvg || isSortPath) {
    return;
  }
  // Otherwise, prevent the default behavior
  event.defaultMuiPrevented = true;
};
