import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useSystem from "../hooks/useSystem";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";

import {
  addSystem,
  softDeleteSystem,
  patchSystem,
  restoreSystem,
} from "../dao/systems";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";

export default function SystemDetail({ title = "", mode = "" }) {
  const history = useHistory();
  const { systemId } = useParams();
  const { objectId, systemGroupId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.system);

  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, FILTER_TYPES.system);

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.system
  );

  const {
    system,
    fetchSystemData,
    isLoading: recordIsLoading,
  } = useSystem(objectId, systemGroupId, systemId);

  const [fields] = useFields(
    FILTER_TYPES.system,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({
        objectId: system.object_id,
        systemGroupId: system.system_group_id,
      }).filter(([, value]) => value)
    ).toString();
  }, [system.object_id, system.system_group_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeSystem(payload) {
    if (isUpdateMode) {
      // Existing System
      log.trace("about to patch", system.id);
      await patchSystem(payload, system.id).then(() => {
        if (
          objectId !== payload.object_id ||
          systemGroupId !== payload.system_group_id
        ) {
          history.push(["/system", system.id].join("/"));
        } else {
          fetchSystemData();
        }
      });
    } else {
      // Brand new System
      log.trace("about to create", JSON.stringify(system));
      return addSystem(payload).then((res) => {
        history.push(["/system", res.id].join("/"));
      });
    }
  }

  async function removeSystem() {
    if (isUpdateMode) {
      return softDeleteSystem(system).then(() => {
        fetchSystemData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchSystemData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedSystem() {
    // return the promise
    return restoreSystem(system).then(() => {
      fetchSystemData();
    });
  }
  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.system}
            to={addSearchParams(ROUTES.system.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: system,
            fields,
            storeEntity: storeSystem,
            deleteEntity: removeSystem,
            entityType: FILTER_TYPES.system,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedSystem,
            isUpdateMode,
          }}
        />
      )}
      {isUpdateMode && (
        <EntityOverview
          entity={FILTER_TYPES.equipment}
          title={"Equipments overview"}
          nestedEntity={true}
          systemId={systemId}
          parent={{
            object_id: system.object_id,
            system_id: system.id,
            system_group_id: system.system_group_id,
          }}
        />
      )}
    </Paper>
  );
}
