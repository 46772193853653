import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useSystemGroup from "../hooks/useSystemGroup";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";

import {
  addSystemGroup,
  softDeleteSystemGroup,
  patchSystemGroup,
  restoreSystemGroup,
} from "../dao/systemGroups";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";

export default function SystemGroupDetail({ title = "", mode = "" }) {
  const history = useHistory();
  const { systemGroupId } = useParams();
  const { objectId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.system_group
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.system_group
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.system_group
  );

  const {
    systemGroup,
    fetchSystemGroupData,
    isLoading: recordIsLoading,
  } = useSystemGroup(systemGroupId, objectId);

  const [fields] = useFields(
    FILTER_TYPES.system_group,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: systemGroup.object_id }).filter(
        ([, value]) => value
      )
    ).toString();
  }, [systemGroup.object_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeSystemGroup(payload) {
    if (isUpdateMode) {
      // Existing SystemGroup
      log.trace("about to patch", systemGroup.id);
      await patchSystemGroup(payload, systemGroup.id).then(() => {
        fetchSystemGroupData();
      });
    } else {
      // Brand new SystemGroup
      log.trace("about to create", JSON.stringify(systemGroup));
      return addSystemGroup(payload).then((res) => {
        history.push(["/system-group", res.id].join("/"));
      });
    }
  }

  async function removeSystemGroup() {
    if (isUpdateMode) {
      return softDeleteSystemGroup(systemGroup).then(() => {
        fetchSystemGroupData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchSystemGroupData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedSystemGroup() {
    restoreSystemGroup(systemGroup).then(() => {
      fetchSystemGroupData();
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.system_group}
            to={addSearchParams(ROUTES.systemGroup.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <EditEntity
          {...{
            isAllowedToUpdate,
            isAllowedToDelete,
            isAllowedToCreate,
            entity: systemGroup,
            fields,
            storeEntity: storeSystemGroup,
            deleteEntity: removeSystemGroup,
            entityType: FILTER_TYPES.system_group,
            cancel: cancelChanges,
            restoreEntity: restoreDeletedSystemGroup,
            isUpdateMode,
          }}
        />
      )}
      {isUpdateMode && (
        <EntityOverview
          entity={FILTER_TYPES.system}
          title={"Systems overview"}
          nestedEntity={true}
          parent={{
            object_id: systemGroup.object_id,
            system_group_id: systemGroup.id,
          }}
        />
      )}
    </Paper>
  );
}
