import { useState, useEffect } from "react";
import { fetchReportTypes } from "../dao/report";

export const useReportTypes = (entity) => {
  const [reportTypes, setReportTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const loadReportTypes = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const types = await fetchReportTypes(entity, abortController);
        setReportTypes(types);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    loadReportTypes();

    return () => {
      abortController.abort();
    };
  }, [entity]);

  return { reportTypes, isLoading, error };
};
