// SETTINGS
export const NUMBER_OF_RECORDS_BLOCK = 500;
export const NUMBER_OF_RECORDS_WARN = 100;

// ACTIONS
export const ACTION_ON_FIELD_CHANGE = "edit/onFieldChange";
export const ACTION_ON_FORM_RESET = "edit/onFormReset";

export const FIELD_DEFAULT_VALUE = "<<...>>";

export const FIELD_ERROR_MESSAGE =
  "An error occurred while getting the data for this field, please refresh the page and try again.";
