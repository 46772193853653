import { useEffect, useState } from "react";
import {
  generateEntityKeyForLocalStorage,
  readDataFromLocalStorage,
} from "../utils/table";
import { getType } from "../utils/utils";

/**
 * Custom hook to manage the sortModel state
 * @param {string} entity - The entity name
 * @param {Object} options - The options object
 * @param {string} options.nestedEntity - If the entity is nested
 * @param {string} options.assignModal - If the entity is in a modal
 * @returns {Object} - The sortModel state and the setSortModel function
 */
export const useSortModel = (entity, options) => {
  const [sortModel, setSortModel] = useState([]);
  const { nestedEntity, assignModal } = options || {};

  useEffect(() => {
    const sortModel = readDataFromLocalStorage(
      generateEntityKeyForLocalStorage(getType(entity), "sortModel", {
        nestedEntity,
        assignModal,
      })
    );
    // Set the state with the sortModel if it exists to prevent unnecessary rendering
    sortModel?.length && setSortModel(sortModel);
  }, [entity]);

  return { sortModel, setSortModel };
};
