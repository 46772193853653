// Happily borrowed from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
import { useState, useEffect } from 'react';

/**
 * This hook creates a debounced state, allowing multiple rapid updates
 * to the state to trigger only limited updates to dependant elements.
 *
 * Usage:
 * ```javascript
 * const [debouncedValue, setDebouncedValue] = useDebounce(undefined, 1000);
 *
 * useEffect(() => {
 *   fetchSearchCompletion(debouncedValue);
 * }, [debouncedValue]);
 *
 * return (<TextInput onChange={(e) => setDebouncedValue(e.target.value)} />);
 * ```
 * @param {*} initialValue Initial value of this state
 * @param {int} delay Delay after which to update value
 */

// Our hook
export default function useDebounce(initialValue, delay) {
  const [ value, setValue ] = useState(initialValue);
  // State and setters for debounced value
  const [ debouncedValue, setDebouncedValue ] = useState(initialValue);

  useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Return a cleanup function that will be called every time ...
        // ... useEffect is re-called. useEffect will only be re-called ...
        // ... if value changes (see the inputs array below).
        // This is how we prevent debouncedValue from changing if value is ...
        // ... changed within the delay period. Timeout gets cleared and ...
        // ... restarted.
        // To put it in context, if the user is typing within our app's ...
        // ... search box, we don't want the debouncedValue to update until ...
        // ... they've stopped typing for more than 500ms.
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [ delay, value ],
  );

  return [ debouncedValue, setValue, value ];
}
