import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
*/
function createInitialState() {
  return {
    object_id: "",
    system_group_id: "",
    system_id: "",
    source_section_id: "",
    destination_section_id: "",
  };
}
export default function useCable({
  objectId = null,
  projectId = null,
  systemId = null,
  systemGroupId = null,
  sectionId = null,
  sourceSectionId = null,
  destinationSectionId = null,
  cableId = null,
}) {
    const [cable, setCable] = useState(createInitialState);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCableData = (abortController) => {
        // Only fetch if we have the required params
        if (cableId) {
          getEntities("/cables", {
            object_ids: objectId,
            system_ids: systemId,
            ids: cableId,
            section_ids: sectionId,
            source_section_ids: sourceSectionId,
            destination_section_ids: destinationSectionId,
            overview: true,
          }, abortController)
            .then((res) => {
              const response = res.response;
              // guard
              if (response.length === 0) {
                return;
              }
              setCable(response[0]);
              setIsLoading(false);
            })
            .catch(errorMessageHandler);
        } else {
          if (objectId) {
            setCable((prevState) => ({
              ...prevState,
              [FieldName.OBJECT_ID]: objectId,
            }));
          }
          if (projectId) {
            setCable((prevState) => ({
              ...prevState,
              [FieldName.PROJECT_ID]: projectId,
            }));
          }
          if (systemGroupId) {
            setCable((prevState) => ({
              ...prevState,
              [FieldName.SYSTEM_GROUP_ID]: systemGroupId,
            }));
          }
          if (systemId) {
            setCable((prevState) => ({
              ...prevState,
              [FieldName.SYSTEM_ID]: systemId,
            }));
          }
          if(sourceSectionId) {
            setCable((prevState) => ({
              ...prevState,
              source_section_id: sourceSectionId,
            }));
          }
          if(destinationSectionId) {
            setCable((prevState) => ({
              ...prevState,
              destination_section_id: destinationSectionId,
            }));
          }
          setIsLoading(false);
        }
        
    };

    /**
     * useEffect should have a dependency on sectionNumber
     * so that it fires when this changes (changed url params)
     */
    useEffect(() => {
        const abortController = new AbortController();

        fetchCableData(abortController);
        return () => {
            abortController.abort();
        };
    }, [cableId]);

    return { 
      cable,
      fetchCableData,
      isLoading
    };
}
