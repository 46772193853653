export const ENGINEER_ROLE = "engineer";
export const INSTALLATION_ROLE = "installation";
export const ADMIN_ROLE = "admin";

export const CONDITIONS_DISABLED_FOR_BOTH = [
  { engineering_lock: true, installation_lock: false }, // engineering_lock and installation lock fields are disabled
];
export const CONDITIONS_INSTALLATION = [
  { engineering_lock: false, installation_lock: true }, // installation fields are enabled
  { engineering_lock: true, installation_lock: true }, // installation fields are enabled
  { installation_lock: true }, // installation fields are enabled
];

export const CONDITIONS_ENGINEER = [
  { engineering_lock: false, installation_lock: false }, // engineering fields are enabled
];
