import { useState, useEffect } from "react";
import { getEntities } from "../dao/common";
import { errorMessageHandler } from "./../utils/error";
import { API_ROUTES } from "../enums/api";

export default function useCableBundle({
  cableBundleId = null,
  objectId = null,
}) {
  const [cableBundle, setCableBundle] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchCableBundleData = (abortController) => {
    // Only fetch if we have the required params
    if (cableBundleId) {
      getEntities(
        API_ROUTES["cable-bundles"],
        {
          ids: cableBundleId,
          object_ids: objectId,
        },
        abortController
      )
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setCableBundle(response[0]);
          setIsLoading(false);
        })
        .catch(errorMessageHandler);
    } else {
      if (objectId) {
        setCableBundle((prevState) => ({
          ...prevState,
          object_id: objectId,
        }));
      }
      setIsLoading(false);
    }
  };

  /**
   * useEffect should have a dependency on cableBundleId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchCableBundleData(abortController);
    return () => {
      abortController.abort();
    };
  }, [cableBundleId]);

  return {
    cableBundle,
    fetchCableBundleData,
    isLoading,
  };
}
