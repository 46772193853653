import React from "react";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { snakeCaseToNormalCase } from "../utils/utils";

const CreateItemButton = ({
  to = "",
  isDisabled = false,
  entity = "",
  size = "small",
}) => {
  const entityName = entity ? snakeCaseToNormalCase(entity) : "entity";
  return (
    <IconButton
      component={Link}
      to={to}
      disabled={isDisabled}
      size={size}
      title={
        isDisabled
          ? "You are not allowed to perform this action"
          : `Create a new ${entityName}`
      }
    >
      <AddIcon />
    </IconButton>
  );
};

export default CreateItemButton;
