import React from "react";

import { Divider } from "@mui/material";
import { Box } from "@mui/system";

import Restricted from "../components/Restricted";
import LayoutWrapper from "../components/LayoutWrapper";

import ObjectsContainer from "../components/entities/ObjectsContainer";
import ProjectsContainer from "../components/entities/ProjectsContainer";
import SystemGroupsContainer from "../components/entities/SystemGroupsContainer";
import SystemsContainer from "../components/entities/SystemsContainer";
import LocationsContainer from "../components/entities/LocationsContainer";
import EquipmentsContainer from "../components/entities/EquipmentsContainer";
import SectionsContainer from "../components/entities/SectionsContainer";
import CablesContainer from "../components/entities/CablesContainer";
import CableTypesContainer from "../components/entities/CableTypesContainer";
import RoutePointsContainer from "../components/entities/RoutePointsContainer";
import OrdersContainer from "../components/entities/OrdersContainer";

import UserRights from "../enums/UserRights";
import { FILTER_TYPES } from "../dao/types";
import { CableBundlesContainer } from "../components/entities/CableBundlesContainer";
import DrumsContainer from "../components/entities/DrumsContainer";

export default function EntityOverview({
  entity,
  nestedEntity = false,
  parent = {},
}) {
  return (
    <LayoutWrapper
      condition={!nestedEntity}
      wrapper={(children) => <Box>{children}</Box>}
    >
      {nestedEntity && (
        <Divider
          sx={{
            margin: "1rem 0",
          }}
        />
      )}
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Restricted to={UserRights.READ} entity={entity}>
          {entity === FILTER_TYPES.object && <ObjectsContainer />}
          {entity === FILTER_TYPES.project && (
            <ProjectsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.system_group && (
            <SystemGroupsContainer
              nestedEntity={nestedEntity}
              parent={parent}
            />
          )}
          {entity === FILTER_TYPES.system && (
            <SystemsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.location && (
            <LocationsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.equipment && (
            <EquipmentsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.section && (
            <SectionsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.cable && (
            <CablesContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.cable_type && <CableTypesContainer />}
          {entity === FILTER_TYPES.cable_bundle && (
            <CableBundlesContainer
              nestedEntity={nestedEntity}
              parent={parent}
            />
          )}
          {entity === FILTER_TYPES.route_point && <RoutePointsContainer />}
          {entity === FILTER_TYPES.drum && <DrumsContainer />}
          {entity === FILTER_TYPES.order && <OrdersContainer />}
        </Restricted>
      </Box>
    </LayoutWrapper>
  );
}
