import { del, patch, post } from "../api/api";
import { API_ROUTES } from "../enums/api";
import { getInlineEditQueryParam } from "../utils/utils";

export const CABLE_BUNDLE_API_PATH = API_ROUTES["cable-bundles"];

export async function patchCableBundle(
  cableBundle,
  cableBundleId,
  isInlineEdit
) {
  return patch(
    [CABLE_BUNDLE_API_PATH, cableBundleId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    cableBundle
  );
}

export async function addCableBundle(cableBundle) {
  const body = await post(CABLE_BUNDLE_API_PATH, cableBundle);
  return body?.response;
}

export async function softDeleteCableBundle(cableBundle) {
  const body = await patch([CABLE_BUNDLE_API_PATH, cableBundle.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

export async function restoreCableBundle(cableBundle) {
  const body = await patch([CABLE_BUNDLE_API_PATH, cableBundle.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteCableBundle(cableBundle) {
  const body = await del([CABLE_BUNDLE_API_PATH, cableBundle.id].join("/"));
  return body?.response;
}
