import useFetchTypes from "../../hooks/useFetchTypes";
import { FILTER_TYPES } from "../../dao/types";
import { Alert, Autocomplete, TextField } from "@mui/material";
import { getNameFromValue, isSameValue } from "../../utils/form";
import { FIELD_DEFAULT_VALUE, FIELD_ERROR_MESSAGE } from "./constants";

export const MassEditSignalTypeLookup = (props) => {
  const { onChange, required, ...rest } = props;

  const [signalTypes, isLoading, error] = useFetchTypes(FILTER_TYPES.signal);

  if (error) {
    return <Alert severity='error'>{FIELD_ERROR_MESSAGE}</Alert>;
  }

  return (
    <Autocomplete
      options={signalTypes || []}
      data-testid='mass-edit-signal-lookup'
      getOptionLabel={(value) => {
        if (isLoading) return "Loading...";
        if (value === FIELD_DEFAULT_VALUE) return value;
        return getNameFromValue(signalTypes, value);
      }}
      isOptionEqualToValue={(option, value) =>
        isSameValue(option, value) ||
        value === FIELD_DEFAULT_VALUE ||
        value === ""
      }
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={required ? `${props.label} (required)` : props.label}
          InputLabelProps={{
            // keep the label above the value
            shrink: true,
          }}
        />
      )}
      onChange={(e, value) => {
        if (required && !value) return; // prevent user sending empty value for required field, otherwise it will be rejected by the server
        onChange({ id: props.id, value: value ? value.abbr : "" });
      }}
      {...rest}
    />
  );
};
