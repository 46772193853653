import React from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@mui/material';

export default function BooleanField({
  formStateHandlers,
  fieldSetting,
  inputProps,
  ...props
}) {
  const { checkbox } = formStateHandlers;
  const {
    className: externalClassName,
    label,
    required,
    disabled,
  } = props;

  const classNames = externalClassName?.split(' ') ?? [];
  const onChange = (e) => {
    const moddedEvent = {
      ...e,
      target: {
        ...e.target,
        value: e.target.checked,
      },
    };
    return fieldSetting.onChange(moddedEvent);
  };

  const fieldConfig = checkbox({
    ...fieldSetting,
    onChange,
  });

  // Convert to boolean
  const normalisedChecked = String(fieldConfig?.checked ?? true).toLowerCase();
  const checked = normalisedChecked === 'true' || normalisedChecked === '1';
  const disabledValue = disabled || (checked && required);

  return (
    <FormControlLabel
      sx={ {
        alignItems: 'center',
        justifyContent: 'flex-end',
      } }
      labelPlacement='top'
      label={ label }
      className={ classNames.join(' ') }
      control={ <Checkbox { ...{
        ...fieldConfig,
        color: 'primary',
        disabled: disabledValue,
        checked,
        required,
      } } /> }
    />
  );
}
