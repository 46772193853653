import React, { useState } from 'react';

const DisplayMessageContext = React.createContext();

// call it DisplayMessage since we can use it for many purpose
// as open drawer or storing DisplayMessage data
export function DisplayMessageProvider({ children }) {
  const [ openMessage, setOpenMessage ] = useState(false);
  const [ requestResult, setRequestResult ] = useState(null);
  const [ errorFlag, setErrorFlag ] = useState(false);

  const appData = {
    openMessage,
    setOpenMessage,
    requestResult,
    setRequestResult,
    errorFlag,
    setErrorFlag,
  };
  return <DisplayMessageContext.Provider value={ appData }>
    { children }
  </DisplayMessageContext.Provider>;
}

export const DisplayMessageConsumer = DisplayMessageContext.Consumer;
export default DisplayMessageContext;
