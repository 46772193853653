import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(
    () =>
      Array.from(new URLSearchParams(search)).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {}),
    [search]
  );
}
