import React, { useContext } from "react";
import compose from "compose-function";
import { GlobalContext } from "../../globalContext";
import { useSortModel } from "../../hooks/useSortModel";
import { FILTER_TYPES } from "../../dao/types";
import {
  addHrefToRow,
  addValueFormatter,
  changeDeletedToBoolean,
  generateEntityKeyForLocalStorage,
  getDefaultFilterModel,
  getInitialFilters,
  padValueWith,
  readDataFromLocalStorage,
} from "../../utils/table";
import { useColumns } from "../../hooks/useColumns";
import {
  dateToString,
  exportToFile,
  fetchDataWithOptionsAndFilters,
  getSortOrder,
  sortColumns,
  sortModelToString,
} from "../../utils/utils";
import useEntities from "../../hooks/useEntities";
import useUserPermissionsContext from "../../hooks/useUserPermissionsContext";
import UserRights from "../../enums/UserRights";
import TableComponent from "../TableComponent";
import CreateItemButton from "../CreateItemButton";
import { addSearchParams } from "../../utils/dao";
import { ROUTES } from "../../enums/Routes";
import { copyRecord, editRecords, exportRecords } from "../../dao/common";
import { hardDelete, softDelete } from "../../dao/operations";
import moment from "moment";

const DRUM_API_PATH = "/drums";

/**
 * Here we need to transform the row data retrieved,
 * in order to make it compatible with @mui/x-data-grid-pro
 */
const transformRows = (rows) => {
  return rows.reduce((acc, row) => {
    return [
      ...acc,
      compose(
        changeDeletedToBoolean("deleted"),
        addHrefToRow({
          field: "id",
          slug: "drum",
        })
      )(row),
    ];
  }, []);
};

/**
 * Here we need to transform the column data retrieved,
 * in order to add @mui/x-data-grid-pro formatters
 */
export const transformColumns = (columns) => {
  return columns.reduce((acc, column) => {
    return [
      ...acc,
      compose(
        addValueFormatter("object_number")(padValueWith(5)),
        addValueFormatter("total_length")(padValueWith(4)),
        addValueFormatter("number")(padValueWith(4)),
        addValueFormatter("purchase_date")(dateToString),
        addValueFormatter("purchase_delivery_date")(dateToString)
      )(column),
    ];
  }, []);
};

const DrumsContainer = () => {
  const { globalFilters } = useContext(GlobalContext);
  const { sortModel, setSortModel } = useSortModel(FILTER_TYPES.drum);

  // Get cached local filter model from local storage
  // with a fallback to default field values
  const cachedLocalFilter = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(FILTER_TYPES.drum, "filterModel")
  ) ?? {
    filterModel: null,
    filterJSON: "",
  };

  // destructure fields from cachedLocalFilter
  const {
    filterModel: cachedLocalFilterModel,
    filterJSON: cachedFilterJSONString,
  } = cachedLocalFilter;

  const initialFilters = getInitialFilters({
    cache: {
      localFilterModel: cachedLocalFilterModel,
      filterJSONString: cachedFilterJSONString,
    },
    defaultFilterModel: getDefaultFilterModel("number").filterModel,
  });

  const { columns } = useColumns(FILTER_TYPES.drum);

  const sortModelString =
    sortModelToString(sortModel, columns) || "number desc";

  const {
    data,
    loadingState,
    fetchEntitiesData: fetchDrumsData,
    setFilterString,
    setOffset,
    limit,
    offset,
    setLimit,
    totalNumberOfRecords,
  } = useEntities("drums", {
    params: {
      object_ids: globalFilters?.value ?? null,
    },
    initialFilters: initialFilters.apiFilterString,
    order: sortModelString,
  });

  const fetchRecordsDataWithGlobalFilters = fetchDataWithOptionsAndFilters(
    fetchDrumsData
  )({
    limit,
    offset,
    order: sortModelString,
    params: {
      object_ids: globalFilters?.value ?? null,
    },
  });

  const fetchExportData = async (filterString, selectedColumnsString) => {
    const abortController = new AbortController();
    const searchParams = {
      filters: filterString,
      columns: selectedColumnsString,
      object_ids: globalFilters?.value ?? null,
    };
    const response = await exportRecords(
      DRUM_API_PATH,
      searchParams,
      abortController
    );

    exportToFile(
      `export_${FILTER_TYPES.drum}_overview_${moment().format(
        "DD-MM-YYYY"
      )}.csv`,
      response
    );

    return "Export done";
  };

  const { isAllowedTo } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.drum);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.drum
  );

  const isAllowedToExport = isAllowedTo(UserRights.EXPORT_CSV);
  const isAllowedToMassEdit = isAllowedTo(UserRights.MASS_EDIT_DRUM);
  const isAllowedToInlineEdit = isAllowedTo(UserRights.INLINE_EDIT_DRUM);

  const tableOptions = {
    hasDelete: isAllowedToDelete,
    sortOrder: getSortOrder(FILTER_TYPES.drum),
    hasMassEdit: isAllowedToMassEdit,
    hasCopy: isAllowedToCreate,
    hasInlineEdit: isAllowedToInlineEdit,
  };

  const searchParams = new URLSearchParams(
    Object.entries({
      objectId: globalFilters?.value,
    }).filter(([, value]) => value)
  ).toString();

  return (
    <TableComponent
      {...{
        columns: transformColumns(sortColumns(columns)),
        transformColumns,
        loading: loadingState,
        rows: transformRows(data),
        refreshData: fetchRecordsDataWithGlobalFilters,
        renderCreateEntityButton: () => (
          <CreateItemButton
            to={addSearchParams(ROUTES.drums.create)(searchParams)}
            isDisabled={!isAllowedToCreate}
            entity={FILTER_TYPES.drum}
          />
        ),
        copyEntry: copyRecord,
        editRecords,
        softDeleteEntry: softDelete({
          route: DRUM_API_PATH,
          delete: true,
        }),
        hardDeleteEntry: hardDelete({
          route: DRUM_API_PATH,
        }),
        restoreEntry: softDelete({
          route: DRUM_API_PATH,
          delete: false,
        }),
        tableOptions,
        setFilters: setFilterString,
        setOffset,
        offset,
        setLimit,
        sortModel,
        setSortModel,
        numberOfRecords: totalNumberOfRecords,
        isAllowedToExport,
        entity: FILTER_TYPES.drum,
        nestedEntity: false,
        initialFilterModel: initialFilters.grid,
        exportRecords: fetchExportData,
      }}
    />
  );
};

export default DrumsContainer;
