import { Autocomplete, TextField } from "@mui/material";
import React, { useRef } from "react";

export const CustomBooleanSelectorField = (props) => {
  const { item, applyValue } = props;
  const ref = useRef(null);

  const handleFilterChange = (_event, newValue) => {
    applyValue({ ...item, value: newValue ? newValue.value : "" });
  };

  const options = [
    { label: "true", value: true },
    { label: "false", value: false },
  ];

  return (
    <Autocomplete
      options={options}
      onChange={handleFilterChange}
      getOptionLabel={(option) => option.label ?? `${option}`}
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Value'
          placeholder='Filter value'
          variant='standard'
        />
      )}
      value={item.value === undefined ? "" : item.value} // Ensure value is never undefined
      ref={ref}
      data-testid='custom-boolean-selector-field'
    />
  );
};
