const UserRights = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  SOFT_DELETE: "soft-delete",
  HARD_DELETE: "hard-delete",
  IMPORT: "import",
  EXPORT_CSV: "export-csv",
  MASS_EDIT_CABLE_TYPE: "mass-edit-cable_type",
  MASS_EDIT_CABLE: "mass-edit-cable",
  MASS_EDIT_EQUIPMENT: "mass-edit-equipment",
  MASS_EDIT_LOCATION: "mass-edit-location",
  MASS_EDIT_SECTION: "mass-edit-section",
  MASS_EDIT_CABLE_BUNDLE: "mass-edit-cable_bundle",
  MASS_EDIT_ROUTE_POINT: "mass-edit-route_point",
  MASS_EDIT_DRUM: "mass-edit-drum",
  MASS_EDIT_ORDER: "mass-edit-order",
  INLINE_EDIT_CABLE_TYPE: "inline-edit-cable_type",
  INLINE_EDIT_CABLE: "inline-edit-cable",
  INLINE_EDIT_OBJECT: "inline-edit-object",
  INLINE_EDIT_SECTION: "inline-edit-section",
  INLINE_EDIT_EQUIPMENT: "inline-edit-equipment",
  INLINE_EDIT_SYSTEM_GROUP: "inline-edit-system_group",
  INLINE_EDIT_PROJECT: "inline-edit-project",
  INLINE_EDIT_SYSTEM: "inline-edit-system",
  INLINE_EDIT_LOCATION: "inline-edit-location",
  INLINE_EDIT_CABLE_BUNDLE: "inline-edit-cable_bundle",
  INLINE_EDIT_ROUTE_POINT: "inline-edit-route_point",
  INLINE_EDIT_DRUM: "inline-edit-drum",
  INLINE_EDIT_ORDER: "inline-edit-order",
  ASSIGN_CABLE: "assign-cable",
};

export default UserRights;
