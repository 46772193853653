import log from 'loglevel';

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const ENV_TYPE = process.env.NODE_ENV;
export const RESULTS_TO_FETCH = 50;

export const LOADING_STATE_IDLE = 'idle';
export const LOADING_STATE_PENDING = 'pending';
export const LOADING_STATE_FULFILLED = 'fulfilled';

export function isDevelopment() {
  return ENV_TYPE === 'development';
}

// Set log-level on development to trace, but for production only WARN and up...
log.setDefaultLevel(isDevelopment() ? log.levels.TRACE : log.levels.WARN);
log.getLogger('verbose').setDefaultLevel(log.levels.ERROR);
