import React from "react";
import clsx from "clsx";
import { TextField, Autocomplete } from "@mui/material";

import { useFormContext } from "../../hooks/useFormContext";
import { useParams } from "react-router";
import { ejectDeletedEntities, getIdValues } from "./../../utils/utils";
import useEntities from "../../hooks/useEntities";

function getNameFromValue(options, value, setObjectEntity) {
  if (!value || value === "") return "";
  const obj = options.find((o) => o.id === value);
  setObjectEntity(obj);

  return `${obj?.number} (${obj?.name})`;
}

function setObjectValue(option, value, objectId) {
  if (value) {
    return option === value;
  }
  if (objectId) {
    // If an AutoComplete option equals the current objectId...
    if (option === objectId) {
      // ...return that option to prefill the AutoComplete.
      return option;
    }
    return false;
  }
}

export default function ObjectNumbersLookup({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  ...props
}) {
  const recordsToFetch = 10000;
  const { data: objects } = useEntities("objects", {
    initialLimit: recordsToFetch,
    initialFilters: "",
  });
  const { objectId } = useParams();
  const { setObjectEntity } = useFormContext();

  /**
   * see: https://github.com/wsmd/react-use-form-state/blob/master/README.md#custom-controls
   */
  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Delibirately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
  });

  if (!objects) {
    return null;
  }

  const cleanedObjects = ejectDeletedEntities(objects);

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    variant,
    required,
    helperText,
    ...inputProps,
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(cleanedObjects)}
      getOptionLabel={(value) =>
        getNameFromValue(cleanedObjects, value, setObjectEntity)
      }
      isOptionEqualToValue={(option, value) =>
        setObjectValue(option, value, objectId)
      }
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          {...finalInputProps}
          fullWidth
          label={
            finalInputProps.required
              ? `${finalInputProps.label} (required)`
              : finalInputProps.label
          }
          error={props.data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      )}
    />
  );
}
