import { Button, Paper, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Unauthorised = () => {
  const history = useHistory();
  return (
    <Paper sx={ {
      padding: 4,
    } }>
      <Typography
        variant='h5'
        sx={ {
          '& + *': {
            marginTop: 3,
          },
        } }
      >
        Missing permissions
      </Typography>
      <p>
          You do not have sufficient permission to view this page.
          Don't worry though, there is always a way to go back to the dashboard.
      </p>
      <Button variant="contained" onClick={ () => {
        history.push('/');
      } }>To Dashboard</Button>
    </Paper>
  );
};
