import { useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { snakeCaseToNormalCase } from "../utils/utils";

export const EXPORT_VISIBLE_COLUMNS = "exportVisibleColumns";
export const EXPORT_ALL_COLUMNS = "exportAllColumns";

export const ExportDropdown = (props) => {
  const {
    entity,
    exportOptions: { exportRecordsHandler, isAllowedToExport, isLoading },
  } = props;
  const [exportDropdownEl, setExportDropdownEl] = useState(null);

  const handleExportDropdownMenu = (event) => {
    setExportDropdownEl(event.currentTarget);
  };

  const handleExportDropdownClose = () => {
    setExportDropdownEl(null);
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <IconButton
        onClick={handleExportDropdownMenu}
        title={`Export ${snakeCaseToNormalCase(entity)} data`}
        size='small'
      >
        {isLoading ? <CircularProgress size={24} /> : <FileDownloadIcon />}
      </IconButton>

      <Menu
        open={Boolean(exportDropdownEl)}
        anchorEl={exportDropdownEl}
        onClose={handleExportDropdownClose}
        keepMounted
      >
        <MenuItem
          onClick={() =>
            exportRecordsHandler(entity, EXPORT_VISIBLE_COLUMNS)
          }
          variant='text'
          color='primary'
          title={"Visible columns"}
          disabled={!isAllowedToExport || isLoading}
        >
          Visible columns
        </MenuItem>
        <MenuItem
          onClick={() => exportRecordsHandler(entity, EXPORT_ALL_COLUMNS)}
          variant='text'
          color='primary'
          title={"All columns"}
          disabled={!isAllowedToExport || isLoading}
        >
          All columns
        </MenuItem>
      </Menu>
    </Box>
  );
};
