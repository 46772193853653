import React, { useEffect, useState } from "react";
import { getEntities } from "../../dao/common";
import { Alert, Autocomplete, TextField } from "@mui/material";
import { FIELD_DEFAULT_VALUE, FIELD_ERROR_MESSAGE } from "./constants";

export const MassEditLookup = (props) => {
  const {
    route,
    onChange,
    required,
    filterFn,
    getOptionLabelParam,
    dependencies: { objectId, projectId } = {},
    withDeleted,
    ...rest
  } = props;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Construct query parameters for the API call
  const queryParams = {
    ...(objectId ? { object_ids: objectId } : {}),
    ...(projectId ? { project_ids: projectId } : {}),
    ...(typeof withDeleted !== "undefined" ? { withDeleted } : {}),
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (route.length === 0) {
          throw new Error('prop "route" must have a valid value');
        }
        const res = await getEntities(route, queryParams, abortController);
        if (filterFn) {
          res.response = res.response.filter(filterFn);
        }

        setData(res.response);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  if (error) {
    return <Alert severity='error'>{FIELD_ERROR_MESSAGE}</Alert>;
  }

  return (
    <Autocomplete
      options={data}
      data-testid='mass-edit-lookup'
      getOptionLabel={(value) => {
        if (isLoading) return "Loading...";
        if (value === FIELD_DEFAULT_VALUE) return value;
        if (typeof value !== "object") return value.toString();
        const matchedItem = data.find((o) => o.id === value.id);
        return `${matchedItem[getOptionLabelParam]}`;
      }}
      isOptionEqualToValue={(option, value) =>
        value === FIELD_DEFAULT_VALUE ||
        value === "" ||
        option[getOptionLabelParam] === value
      }
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          required={required}
          label={required ? `${props.label} (required)` : props.label}
        />
      )}
      onChange={(_, value) => {
        if (required && !value) return; // prevent user sending empty value for required field, otherwise it will be rejected by the server
        onChange({ id: props.id, value });
      }}
      {...rest}
    />
  );
};
