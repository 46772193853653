import { Chip } from "@mui/material";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import { theme } from "./../setting";

import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

import { FILTER_TYPES } from "../dao/types";
import { noop } from "../utils/utils";
import { ROUTES } from "../enums/Routes";

function Breadcrumb({ label, link }) {
  return (
    <Chip
      clickable
      label={label}
      component={Link}
      to={link}
      deleteIcon={<ExpandCircleDownRoundedIcon />}
      onDelete={() => noop.fn()}
      sx={{
        "& .MuiChip-deleteIcon": {
          color: theme.palette.primary.main,
          transform: "rotate(-90deg)",
        },
        marginRight: "10px",
        marginBottom: "10px",
      }}
    />
  );
}

function NavigateEntity({
  entityType,
  entity,
  projectEntity,
  systemGroupEntity,
  systemEntity,
  locationEntity,
  equipmentEntity,
  sectionEntity,
  cableBundleEntity,
}) {
  return (
    <Box
      sx={{
        paddingBottom: "1rem",
      }}
    >
      {/** All screens should show the object breadcrumb except when there is no object_id */}
      {entity.object_id && (
        <Breadcrumb
          label={`Object: ${entity.object_number}`}
          link={`/object/${entity.object_id}`}
        />
      )}
      {/* EQUIPMENT - Object > Project > System Group > System */}
      {(entityType === FILTER_TYPES.equipment ||
        entityType === FILTER_TYPES.cable ||
        entityType === FILTER_TYPES.order) &&
        projectEntity && (
          <Breadcrumb
            label={`Project: ${projectEntity.reference}`}
            link={["/project", projectEntity.id].join("/")}
          />
        )}

      {(entityType === FILTER_TYPES.system ||
        entityType === FILTER_TYPES.equipment ||
        entityType === FILTER_TYPES.section ||
        entityType === FILTER_TYPES.cable) &&
        systemGroupEntity && (
          <Breadcrumb
            label={`System group: ${systemGroupEntity.reference}`}
            link={["/system-group", systemGroupEntity.id].join("/")}
          />
        )}

      {(entityType === FILTER_TYPES.equipment ||
        entityType === FILTER_TYPES.section ||
        entityType === FILTER_TYPES.cable) &&
        systemEntity && (
          <Breadcrumb
            label={`System: ${systemEntity.reference}`}
            link={["/system", systemEntity.id].join("/")}
          />
        )}
      {/* EQUIPMENT - Object > Project > System Group > System */}
      {entityType === FILTER_TYPES.equipment && (
        <>
          {sectionEntity && (
            <Breadcrumb
              label={`Section: ${[
                sectionEntity.system_number,
                sectionEntity.equipment_reference,
                ".",
                sectionEntity.number,
              ].join("")} (${sectionEntity.description})`}
              link={["/section", sectionEntity.id].join("/")}
            />
          )}
          {locationEntity && (
            <Breadcrumb
              label={`Location: ${locationEntity.number}`}
              link={["/location", locationEntity.id].join("/")}
            />
          )}
        </>
      )}
      {/* Sections
       *  sectionId (optional)
       *  locationId (optional)
       */}
      {entityType === FILTER_TYPES.section && equipmentEntity && (
        <Breadcrumb
          label={`Equipment: ${equipmentEntity.reference} (${equipmentEntity.description})`}
          link={["/equipment", equipmentEntity.id].join("/")}
        />
      )}
      {entityType === FILTER_TYPES.cable && (
        <>
          {entity.source_section_reference && (
            <Breadcrumb
              label={`Source: ${entity.source_section_reference}`}
              link={["/section", entity.source_section_id].join("/")}
            />
          )}
          {entity.destination_section_reference && (
            <Breadcrumb
              label={`Destination: ${entity.destination_section_reference}`}
              link={["/section", entity.destination_section_id].join("/")}
            />
          )}
          {entity.cable_bundle_id && cableBundleEntity && (
            <Breadcrumb
              label={`Cable bundle: ${cableBundleEntity.number}`}
              link={ROUTES.bundle.edit.replace(":id", entity.cable_bundle_id)}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default NavigateEntity;
