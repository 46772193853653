import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const SECTIONS_API_PATH = "/sections";

/**
 * Update an section
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.2/console/method/%238832/}
 */
export async function patchSection(section, sectionId, isInlineEdit) {
  return patch(
    [SECTIONS_API_PATH, sectionId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("equipment_reference2"),
      sanitizeEntity("reference"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_group_id"),
      sanitizeEntity("system_number"),
      sanitizeEntity("system_id"),
      sanitizeEntity("equipment_reference"),
      sanitizeEntity("object_number"),
      sanitizeEntity("pom_id"),
      sanitizeEntity("imported"),
      sanitizeEntity("imported_by"),
      sanitizeEntity("updated"),
      sanitizeEntity("updated_by"),
      sanitizeEntity("supplied_from_1_reference"),
      sanitizeEntity("supplied_from_2_reference"),
      sanitizeEntity("supplied_from_3_reference")
    )(section)
  );
}

/**
 * Create a section
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%2312547/}
 */
export async function addSection(section) {
  const body = await post(SECTIONS_API_PATH, section);
  return body?.response;
}

/**
 * Mark section as deleted
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%2312547/}
 */
export async function softDeleteSection(section) {
  const body = await patch([SECTIONS_API_PATH, section.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

/**
 * Unmark section as deleted
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%2312547/}}
 */
export async function restoreSection(section) {
  const body = await patch([SECTIONS_API_PATH, section.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

/**
 * Remove section from database
 *
 * @param {object[]} section
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%233688/}
 */
export async function hardDeleteSection(section) {
  const body = await del([SECTIONS_API_PATH, section.id].join("/"));
  return body?.response;
}
