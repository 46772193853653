import { get, post } from "../api/api";

export async function fetchReportTypes(entity, abortController) {
  const body = await get(
    `/reports/${entity}/types?limit=50&withDeleted=false`,
    abortController
  );
  return body?.response;
}

export async function sendPowerAutomateRequest(payload) {
  const body = await post("/reports/power-automate", payload);
  return body?.response;
}
