import { useState, useEffect } from 'react';
import { fetchTypes, FILTER_TYPES } from '../dao/types';

export default function useProjectTypes() {
  const [ projTypes, setProjTypes ] = useState(null);

  useEffect(() => {
    fetchTypes(FILTER_TYPES.project).then(setProjTypes);
  }, []);

  return [ projTypes ];
}
