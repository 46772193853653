import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { snakeCaseToNormalCase } from "../../utils/utils";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { CircularProgress } from "@mui/material";

/**
 * function that returns a filtered array of columns
 * @param {array} columns data
 * @param {*} visibleFieldNames
 * @returns
 */
export function getVisibleColumns(columns, visibleFieldNames = []) {
  // guard
  if (
    Array.isArray(columns) === false ||
    Array.isArray(visibleFieldNames) === false
  ) {
    return [];
  }
  // filter set to only visible columns
  return columns.filter((column) => {
    return visibleFieldNames.includes(column.field);
  });
}
/**
 * function that returns an array of fieldNames
 * where value is true
 * @param {object} visibilityModel from dataGrid
 * @returns {string[]} the fieldNames
 */
export function getVisibleFieldNames(visibilityModel = {}) {
  return Object.entries(visibilityModel).reduce((acc, visibilityItem) => {
    const [key, value] = visibilityItem;
    if (value === true) {
      return [...acc, key];
    }
    return acc;
  }, []);
}

/**
 * Determines whether inline edit icons should be disabled.
 * @param {object} inlineEditState - The state of inline edit.
 * @param {boolean} isInEditMode - Indicates if the single row is in edit mode.
 * @returns {boolean} - Indicates whether the icons should be disabled.
 */
export const isInlineEditIconsDisabled = (inlineEditState, isInEditMode) => {
  if (inlineEditState === undefined && isInEditMode === undefined) {
    return false;
  }
  // For single inline edit action icons
  if (isInEditMode !== undefined) {
    return inlineEditState?.saveInProgress || !isInEditMode;
  }
  // For batch inline edit action icons
  return (
    inlineEditState?.batchActionButtonsDisabled ||
    inlineEditState?.saveInProgress
  );
};

/**
 * Generates an array of control objects based on the given table options and UI state.
 *
 * @param {object} tableOptions - An object containing options related to the table controls.
 * @param {object} uiState - An object representing the UI state.
 * @param {function} dispatchUIState - A function used to dispatch UI state changes.
 * @param {function} handleinlineEditBatchCancel - A function to handle the cancelation of all inline edits.
 * @param {function} handleInlineEditBatchSave - A function to handle the cancelation of all inline edits.
 * @returns {Array} An array of control objects representing the table controls.
 */

export const generateControlsArray = (
  tableOptions,
  uiState,
  dispatchUIState,
  handleinlineEditBatchCancel,
  handleInlineEditBatchSave,
  entity
) => {
  const controlsArray = [];

  if (tableOptions.hasMassEdit) {
    controlsArray.push({
      id: "mass-edit",
      name: `Mass edit ${snakeCaseToNormalCase(entity)} of selected item(s)`,
      icon: <AppRegistrationIcon />,
      testId: "mass-edit-button",
      isDisabled:
        uiState.controlsDisabled ||
        // if any of the selected rows are deleted or have different object_id
        uiState.selectedRows.some(
          (row) =>
            row.is_deleted ||
            row.object_id !== uiState.selectedRows[0].object_id
        ),
      handleClick: (e) => {
        e.preventDefault();
        dispatchUIState({
          type: "DIALOG_EDIT_TOGGLE",
          payload: { visible: true },
        });
      },
    });
  }

  if (tableOptions.hasInlineEdit) {
    controlsArray.push(
      {
        id: "save-all-inline-edits",
        name: "Save all inline edits",
        isDisabled: isInlineEditIconsDisabled(uiState.inlineEdit),
        icon: uiState.inlineEdit.saveInProgress ? (
          <CircularProgress size={20} sx={{ mx: 0.5, p: 0.5 }} />
        ) : (
          <SaveAltIcon
            sx={{ mx: 0.5, p: 0.5 }}
            data-testid='save-inline-edit-batch'
            color={
              isInlineEditIconsDisabled(uiState.inlineEdit)
                ? "disabled"
                : "primary"
            }
          />
        ),
        handleClick: async (e) => {
          e.preventDefault();
          await handleInlineEditBatchSave();
        },
      },
      {
        id: "cancel-all-inline-edits",
        name: `Cancel the changes of the ${snakeCaseToNormalCase(entity)}.`,
        isDisabled: isInlineEditIconsDisabled(uiState.inlineEdit),
        icon: (
          <CancelOutlinedIcon
            sx={{ mx: 0.5, p: 0.5 }}
            data-testid='cancel-inline-edit-batch'
            color={
              isInlineEditIconsDisabled(uiState.inlineEdit)
                ? "disabled"
                : "error"
            }
          />
        ),
        handleClick: (e) => {
          e.preventDefault();
          handleinlineEditBatchCancel();
        },
      }
    );
  }

  return controlsArray;
};
