/* eslint-disable camelcase */
import React, { useState, useContext, useEffect } from "react";
import { AppBar, IconButton, Menu, Avatar, MenuItem, Box } from "@mui/material";
import BSLogoSVG from "../assets/logo-oasis.svg";
import EnvironmentIndicator from "../components/EnvironmentIndicator";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GlobalContext } from "../globalContext";
import { getInitials } from "../utils/utils";
import { GlobalFiltersContainer } from "./filters/GlobalFilters";

const AppBarHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [initials, setInitials] = useState("");
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, isAuthenticated } = useContext(GlobalContext);

  useEffect(() => {
    if (user.name) {
      const initials = getInitials(user.name);
      setInitials(initials);
    }
  }, [user.name]);

  return (
    <AppBar
      color='primary'
      position='absolute'
      sx={{
        height: "4rem",
        alignContent: "space-between",
        flexWrap: "wrap",
        padding: 1,
      }}
    >
      <Box
        component='img'
        sx={{
          height: "48px",
          marginRight: "2rem",
          position: "relative",
        }}
        src={BSLogoSVG}
        alt='Bakker Sliedrecht Logo'
      />
      <EnvironmentIndicator envName={process.env.REACT_APP_ENV} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          alignItems: "center",
        }}
      >
        {isAuthenticated && <GlobalFiltersContainer />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 1,
            backgroundColor: "#FFF",
            padding: "0.3rem 0 0.3rem 1rem",
            "& .MuiAvatar-root": {
              color: "#262626",
              backgroundColor: "#EFE5CB",
            },
            "& button": {
              padding: 1,
            },
          }}
        >
          <Avatar>{initials || <AccountCircle />}</Avatar>
          <IconButton
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            size='large'
          >
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem>Profile</MenuItem>
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
};

export default AppBarHeader;
