import { Alert, AlertTitle } from "@mui/material";
import { NUMBER_OF_RECORDS_BLOCK, NUMBER_OF_RECORDS_WARN } from "./constants";

export const MassEditDialogTitle = ({ numRecords = 0, message = "" }) => {
  const DISPLAY = {
    msg: `Editing ${numRecords} selected records`,
    severity: "info",
  };

  if (numRecords === 0) {
    DISPLAY.msg = "No records selected";
    DISPLAY.severity = "warning";
  } else if (numRecords > NUMBER_OF_RECORDS_BLOCK) {
    DISPLAY.msg = "It is not possible to edit more than 500 records";
    DISPLAY.severity = "error";
  } else if (numRecords > NUMBER_OF_RECORDS_WARN) {
    DISPLAY.msg = (
      <>
        Warning: Editing <u>{numRecords}</u> selected records
      </>
    );
    DISPLAY.severity = "warning";
  } else if (numRecords === 1) {
    DISPLAY.msg = `Editing ${numRecords} selected record`;
  }
  return (
    <Alert severity={DISPLAY.severity} sx={{ fontSize: "1rem" }}>
      <AlertTitle
        data-testid='dialog-title'
        sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
      >
        {DISPLAY.msg}
      </AlertTitle>
      {message && <span data-testid='dialog-description'>{message}</span>}
    </Alert>
  );
};
