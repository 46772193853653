import { useState, useEffect } from 'react';
import { fetchTypes, FILTER_TYPES } from '../dao/types';

export default function useEquipmentTypes() {
  const [ equipTypes, setEquipTypes ] = useState(null);

  useEffect(() => {
    fetchTypes(FILTER_TYPES.equipment).then(setEquipTypes);
  }, []);

  return [ equipTypes ];
}
