/**
 * utility function for checking if value is not an empty string
 * @param {string} value
 * @returns {boolean}
 */
export const isValidValue = (value) => value !== "";

/**
 * curried function that checks each value in the array
 * against a validator function
 * @param {string[]} values an array of strings
 * @returns {boolean}
 */
export const validateValues = (values) => (validator) => {
  return values.every(validator);
};
