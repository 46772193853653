import { TextField } from "@mui/material";
import { formatLabel } from "../utils";
export default function NumberField({
  formStateHandlers,
  fieldSetting,
  inputProps,
  ...props
}) {
  const { number, raw } = formStateHandlers;
  const defaultFieldConfig = number(fieldSetting);
  const fieldConfig = raw({
    ...defaultFieldConfig,
    onChange: (input) => {
      const value = parseInt(input.target.value);
      fieldSetting.onChange({
        target: {
          fieldname: fieldSetting.name,
          value,
        },
      });
      return value;
    },
  });

  return (
    <TextField
      {...fieldConfig}
      {...props}
      type='number'
      inputProps={{
        step: "any",
        ...inputProps,
      }}
      label={formatLabel(props.data)}
      error={props.data?.isFieldFailed}
      InputLabelProps={{
        // hard coded to true to position label above input
        shrink: true,
        // hard coded to false to prevent an asterisk from showing
        required: false,
      }}
    />
  );
}
