import React from "react";
import { Snackbar, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDisplayMessageContext } from "../hooks/useDisplayMessageContext";

function FeedbackComponent() {
  const { openMessage, requestResult, setOpenMessage, errorFlag } =
    useDisplayMessageContext();

  const handleCloseMessage = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  /* Set autoHideDuration to any arbitrary number,
   * to prevent disappearing when an error is encountered.
   */
  const autoHideDuration = errorFlag ? 60000 : 3000;

  const Wrapper = React.forwardRef(({ children }, ref) =>
    errorFlag ? (
      <Collapse ref={ref} in={openMessage}>
        {children}
      </Collapse>
    ) : (
      <span ref={ref}>{children}</span>
    )
  );

  return (
    <Snackbar
      open={openMessage}
      autoHideDuration={autoHideDuration}
      onClick={handleCloseMessage}
      onClose={handleCloseMessage}
      message={<Wrapper>{requestResult}</Wrapper>}
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={handleCloseMessage}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      }
      ContentProps={{
        style: {
          maxWidth: "500px", // Adjust the value as needed
          whiteSpace: "pre-wrap",
        },
      }}
    />
  );
}

export default FeedbackComponent;
