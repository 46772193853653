import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import FieldName from "../../enums/FieldName";

import { useFormContext } from "../../hooks/useFormContext";

import { getEntities } from "../../dao/common";
import { ejectDeletedEntities, getIdValues } from "./../../utils/utils";

const SOURCE_SECTION_ID = "source_section_id";
const DESTINATION_SECTION_ID = "destination_section_id";

export const getNameFromValue = (
  options,
  value,
  setSectionEntity,
  fieldName
) => {
  if (value === "") return "";
  if (options[0]?.isInitialData) {
    return options[0].label;
  }
  const section = options?.find((o) => o.id === value);

  if (section === undefined) {
    setSectionEntity(null);
    return "";
  }

  if (fieldName === FieldName.SECTION_ID) {
    setSectionEntity(section);
  }
  if (section) {
    return [
      section.system_number,
      section.equipment_reference,
      ".",
      section.number,
      " + ",
      section.description,
    ].join("");
  }
};

function setSectionValue(option, value) {
  if (typeof value !== "undefined") {
    return option === value;
  }
}

export default function SectionNumbersLookup({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  values,
  ...props
}) {
  const { setSectionEntity } = useFormContext();
  const { object_id: objectId } = values || {};
  const fieldName = fieldSetting.name;
  const isSourceSection = fieldName === SOURCE_SECTION_ID;
  const isDestinationSection = fieldName === DESTINATION_SECTION_ID;

  const initialData = useMemo(() => {
    if (isSourceSection) {
      return [
        {
          id: values.source_section_id,
          label: values.source_section_reference,
          isInitialData: true,
        },
      ];
    } else if (isDestinationSection) {
      return [
        {
          id: values.destination_section_id,
          label: values.destination_section_reference,
          isInitialData: true,
        },
      ];
    } else {
      return [];
    }
  }, [isSourceSection, isDestinationSection, values]);

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (objectId) {
      setLoading(true);
      getEntities(
        "/sections",
        {
          object_ids: objectId,
          order: "system_number asc, equipment_reference asc, number asc",
          overview: true,
        },
        abortController
      )
        .then((res) => {
          setData(res.response);
        })
        .finally(() => {
          setLoading(false);
        });
      return () => {
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId]);

  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Deliberately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
  });

  const cleanedSections = ejectDeletedEntities(data);

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    variant,
    required,
    helperText,
    ...inputProps,
  };
  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(cleanedSections)}
      getOptionLabel={(value) =>
        getNameFromValue(cleanedSections, value, setSectionEntity, fieldName)
      }
      isOptionEqualToValue={(option, value) => setSectionValue(option, value)}
      autoSelect={true}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...finalInputProps}
          fullWidth
          label={
            finalInputProps.required
              ? `${finalInputProps.label} (required)`
              : finalInputProps.label
          }
          error={props.data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && open ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
