import React from "react";
import { Collapse, Snackbar } from "@mui/material";
import { FormAlert } from "../../form/FormAlert";
import { noop } from "../../utils/utils";

export const CustomSnackbar = ({
  autoHideDuration = null,
  formActionState = 0,
  onClose = noop.fn,
  name = "",
  lastResponse = null,
}) => {
  let alertMessage = "";
  let alertMessages = [];
  let hideDuration = autoHideDuration;
  let severity = "error";
  let Wrapper = React.forwardRef(({ children }, ref) => (
    <span ref={ref}>{children}</span>
  ));
  switch (formActionState) {
    case 1:
      alertMessage = `We've updated ${name}!`;
      severity = "success";
      break;
    case 150:
      alertMessage = `We've deleted ${name}!`;
      severity = "warning";
      break;
    case 400:
      alertMessage = "There are empty required fields!";
      severity = "warning";
      break;
    case 406:
      alertMessage = "No fields were updated!";
      severity = "warning";
      break;
    case 500:
      alertMessage = "Something went wrong in the server.";
      severity = "error";
      break;
    default:
      Wrapper = React.forwardRef(({ children }, ref) => (
        <Collapse ref={ref} in={formActionState > 0}>
          {children}
        </Collapse>
      ));

      if (lastResponse === null) {
        alertMessages = [`Something went wrong while updating ${name}`];
        break;
      }

      alertMessages = lastResponse?.body?.errors?.map(
        (error) => `${error.field}: ${error.message}`
      ) || [`Something went wrong while updating ${name}`];

      // Set autoHideDuration to any arbitrary number to prevent disappearing.
      hideDuration = 60000;
  }
  return (
    <Snackbar
      open={formActionState > 0}
      autoHideDuration={hideDuration}
      data-testid='customSnackBar'
      onClose={onClose}
    >
      <Wrapper>
        {alertMessages.length > 0 ? (
          alertMessages.map((message, index) => (
            <FormAlert
              key={index}
              severity={severity}
              alertMessage={message}
              onClose={onClose}
            />
          ))
        ) : (
          <FormAlert
            severity={severity}
            alertMessage={alertMessage}
            onClose={onClose}
          />
        )}
      </Wrapper>
    </Snackbar>
  );
};
