import { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { getEntities } from "../../dao/common";
import { getIdValues } from "../../utils/utils";
import { useFormContext } from "../../hooks/useFormContext";
import InputFieldType from "../../enums/InputFieldType";
import useDebounce from "../../hooks/UseDebounce";

const API_FAILURE_ERROR_MESSAGE =
  "The dropdown couldn't be populated. Please try again by refreshing the page or call ICT department.";

/**
 * Lookup field component
 * How to use:
 * When using this component, you need to consider the following:
 * 1. **Route**: Specify the API route that the component should call.
 * 2. **Dependencies**: Determine if there are dependencies required for the API call, such as `systemGroupId` for calling `/systems`.
 * 3. **Option Label**: Define the field from the API response that should be displayed in the dropdown.
 * 4. **Initial Option Label**: This refers to the field in the parent entity object that will be shown when the form is loaded.
 * 5. **Filter Function** (Optional): Provide a custom function to filter the data before rendering it in the dropdown.
 * 6. **Order** (Optional): Specify how to order the fetched data.
 * Example usage with withConfig:
 * ```
 * [InputFieldType.LOCATION_LIST]: withConfig({
 *  route: "/locations",
 * order: "number asc",
 * optionLabel: "number", => This is the field name in the response data from GET/locations endpoint that will be displayed in the dropdown
 * initialOptionLabel: "location_reference", => If you render Location dropdown in Equipment detail page, location_reference is available in the Equipment object.
 * dependencies: { objectId: true }, => Location options are dependent on objectId
 * })(Lookup),
 *
 */

export default function Component({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  route = "",
  dependencies,
  filterFn,
  order,
  disabled = false,
  optionLabel = "reference", // reference is the most common field name in the response data from GET/entity for lookup values
  initialOptionLabel,
  ...props
}) {
  const [data, setData] = useState([
    {
      id: props.values?.[fieldSetting?.name],
      [optionLabel]: props.values?.[initialOptionLabel],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedInputValue, setDebouncedInputValue] = useDebounce("", 1000);

  const {
    objectId,
    projectEntity,
    systemId,
    systemGroupId,
    setSystemEntity,
    setLocationEntity,
    setEquipmentEntity,
    setSystemGroupEntity,
    setCableBundleEntity,
    setProjectEntity,
  } = useFormContext();

  //  Dependencies
  const dependenciesObjectId = dependencies?.objectId ? objectId : null;
  const dependenciesProjectId = dependencies?.projectId
    ? projectEntity?.id
    : null;
  const dependenciesSystemGroupId = dependencies?.systemGroupId
    ? systemGroupId
    : null;
  const dependenciesSystemId = dependencies?.systemId ? systemId : null;

  //  API Call
  useEffect(() => {
    const abortController = new AbortController();

    // Validate route
    if (!route || route.length === 0) {
      throw new Error('prop "route" must have a valid value');
    }

    // Skip API call if required dependencies are missing
    const shouldSkipApiCall =
      (dependencies?.objectId && !dependenciesObjectId) ||
      (dependencies?.projectId && !dependenciesProjectId) ||
      (dependencies?.systemId && !dependenciesSystemId) ||
      (dependencies?.systemGroupId && !dependenciesSystemGroupId);

    if (shouldSkipApiCall) {
      return;
    }

    // Fetch data function with async/await
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Build query params
        const queryParams = {
          withDeleted: false,
          // Include the order parameter if provided
          ...(order && { order }),
          // Add object, project, or system group dependencies if they exist
          ...(dependenciesObjectId && { object_ids: dependenciesObjectId }),
          ...(dependenciesProjectId && { project_ids: dependenciesProjectId }),
          ...(dependenciesSystemGroupId && {
            system_group_ids: dependenciesSystemGroupId,
          }),
          // Apply a search filter when there's a debounced input value
          ...(debouncedInputValue && {
            filters: JSON.stringify({
              [optionLabel]: {
                type: "search",
                value: debouncedInputValue,
              },
            }),
          }),
        };

        // Await API response
        const res = await getEntities(route, queryParams, abortController);
        setData(res.response);
      } catch (error) {
        console.error("Failed to fetch entities:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Call the fetch data function
    fetchData();

    // Cleanup
    return () => abortController.abort();

    // Dependencies array:
    // - dependenciesObjectId: Used to trigger the effect when `objectId` changes, but only if `objectId` is required.
    // - dependenciesProjectId: Used to trigger the effect when `projectEntity.id` changes, but only if `projectId` is required.
    // - dependenciesSystemGroupId: Used to trigger the effect when `systemGroupId` changes, but only if `systemGroupId` is required.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dependenciesObjectId,
    dependenciesSystemId,
    dependenciesProjectId,
    dependenciesSystemGroupId,
    debouncedInputValue,
  ]);

  //  Field Configuration
  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      return !required || !!event.selectValue || !!value;
    },
    compare: () => false,
  });

  //  Filter Items
  const filteredItems = filterFn ? data.filter(filterFn) : data;

  const item = filteredItems.find((item) => item.id === fieldConfig.value);

  // Set entity based on the field type, so navigation breadcrumbs can be updated
  switch (props.data.type) {
    case InputFieldType.SYSTEM_GROUP_NUMBER_LIST:
      setSystemGroupEntity(item);
      break;
    case InputFieldType.CABLE_BUNDLE_LIST:
      setCableBundleEntity(item);
      break;
    case InputFieldType.SYSTEM_NUMBER_LIST:
      setSystemEntity(item);
      break;
    case InputFieldType.EQUIPMENT_LIST:
      setEquipmentEntity(item);
      break;
    case InputFieldType.LOCATION_LIST:
      setLocationEntity(item);
      break;
    case InputFieldType.PROJECTS_LIST:
      setProjectEntity(item);
      break;
    default:
      break;
  }

  //  Field Props
  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(filteredItems)}
      getOptionLabel={(option) => {
        if (!option) return "";
        const matchedItem = filteredItems.find((item) => item.id === option);
        return `${matchedItem?.[optionLabel] ?? ""}`;
      }}
      isOptionEqualToValue={(option, value) => option === value}
      autoSelect={true}
      disabled={disabled}
      loading={isLoading}
      loadingText='Loading...'
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          label={props.label}
          variant={variant}
          required={required}
          error={props.error || error}
          helperText={error ? API_FAILURE_ERROR_MESSAGE : helperText}
          onChange={(event) => setDebouncedInputValue(event.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ position: "absolute", right: 15 }}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
          fullWidth
        />
      )}
    />
  );
}
