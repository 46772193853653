import React, { useState, useEffect } from "react";
import { useAccount } from "@azure/msal-react";

// Create the context
const GlobalContext = React.createContext();

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: "Anonymous User",
    username: "Anonymous",
  });
  const [globalFilters, setGlobalFilters] = useState(null);

  // Get the account information from the useAccount hook
  // @docs: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#useaccount-hook
  const accountInfo = useAccount();

  useEffect(() => {
    if (accountInfo) {
      setUser({
        name: accountInfo.name ?? "",
        username: accountInfo.username ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  // This component will render its children
  // wrapped around a UserContext's provider whose
  // value is set to the method defined above
  return (
    <GlobalContext.Provider
      value={{
        user: user,
        isAuthenticated: accountInfo !== null,
        globalFilters: globalFilters,
        setGlobalFilters: setGlobalFilters,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, GlobalContext };
