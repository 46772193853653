import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import { getVisibleFields, REDRAW_DEBOUNCE_TIMEOUT } from "./FormFactory";


export function FormGroup({ group, ...props }) {
  const UserPermissionsContext = useUserPermissionsContext;

  const [visibleFields, setVisibleFields] = useState(
    getVisibleFields({ group, ...props, UserPermissionsContext })
  );
  const { formState, values, commonFieldProps, copyOneValue } = props;

  // Debouncing visibility check to allow smooth data-entry
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibleFields(
        getVisibleFields({
          group,
          ...props,
          UserPermissionsContext,
        })
      );
    }, REDRAW_DEBOUNCE_TIMEOUT);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, formState, values, commonFieldProps, copyOneValue]);

  // If it turns out all fields in this group are hidden, render nothing
  if (!visibleFields || visibleFields.length === 0) return null;

  return (
    <>
      {group.name ? (
        <Typography variant="h6" component="h4" className="groupHeader">
          {group.name}
        </Typography>
      ) : (
        <></>
      )}
      {visibleFields}
    </>
  );
}
