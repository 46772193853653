import { useEffect, useRef, useState } from "react";
import { TextField, Box } from "@mui/material";

export const CustomFilterField = (props) => {
  const { item, applyValue, type, name } = props;
  const [value, setValue] = useState(item.value);
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const delay = 500;

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      applyValue({ ...item, value });
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, applyValue, item]);

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
      }}
    >
      <TextField
        name={name}
        placeholder='Filter value'
        label='Value'
        variant='standard'
        value={value}
        onChange={(event) => setValue(event.target.value)}
        type={type}
        inputRef={inputRef}
        sx={{ width: "100%" }}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};
