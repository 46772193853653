import { useState, useEffect } from "react";
import { getEntities } from "../dao/common";
import { errorMessageHandler } from "./../utils/error";

export default function useCableType({ cableTypeId = null }) {
  const [cableType, setCableType] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchCableTypeData = (abortController) => {
    // Only fetch if we have the required params
    if (cableTypeId) {
      getEntities(
        "/cable-types",
        {
          ids: cableTypeId,
        },
        abortController
      )
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setCableType(response[0]);
          setIsLoading(false);
        })
        .catch(errorMessageHandler);
    }
    setIsLoading(false);
  };

  /**
   * useEffect should have a dependency on cableTypeId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchCableTypeData(abortController);
    return () => {
      abortController.abort();
    };
  }, [cableTypeId]);

  return {
    cableType,
    fetchCableTypeData,
    isLoading,
  };
}
