import { useState, useEffect } from 'react';
import { fetchTypes, FILTER_TYPES } from '../dao/types';

export default function useObjectTypes() {
  const [ objTypes, setObjTypes ] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetchTypes(FILTER_TYPES.object, abortController).then(setObjTypes);
    return () => {
      abortController.abort();
    };
  }, []);

  return [ objTypes ];
}
