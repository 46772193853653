/**
 * Curried fn that removes fields from an object
 * @param {any} field
 * @returns {object} - the sanitised object
 */
export const sanitizeEntity = (field) => (entity) => {
  const clonedEntity = { ...entity };
  delete clonedEntity[field];
  return clonedEntity;
};

/**
 * curried fn that sets 'Accept' key with
 * given value on Map object
 * @param {Map<Any, Any>} requestObject
 * @param {string} dataType - the data type
 * @returns {Map<Any, Any>} the object
 */
export const addAcceptHeader = (dataType) => (requestObject) => {
  return requestObject.set("Accept", dataType);
};

/**
 * function for creating objects for copy requests
 * @param {object} - the object used to creat the object
 * @returns {object} - an object for an item in a copy payload
 */
export const copyBodyFactory = (item) => {
  // guard
  if ("type" in item) {
    const { type, id, number_start_with: startNumber, nTimes: amount } = item;

    return {
      source: {
        type,
        ids: [id],
      },
      amount,
      number_start_with: startNumber,
    };
  }
  throw new Error("Missing required field: type");
};

/**
 * function for creating an Array for copy requests
 * @param {object[]} items - an array of objects
 * @returns {object[]} - an array of objects
 */
export const getCopyBodyArray = (items = []) => {
  return items.map(copyBodyFactory);
};

/**
 * Curried fn for conditionally adding searchParams to url
 * path
 * @returns {string} the url path
 */
export const addSearchParams = (pathname) => (searchParams) => {
  if (searchParams) {
    return `${pathname}?${searchParams}`;
  }
  return pathname;
};

/**
 * Curried fn for adding responsetype requestObject
 * path
 * @returns {object} the Request object
 */

export const addResponseType = (responseType) => (reqObject) => {
  return reqObject.responseType(responseType);
};
