import { useState, useEffect } from "react";
import {
  LOADING_STATE_FULFILLED,
  LOADING_STATE_IDLE,
  LOADING_STATE_PENDING,
  RESULTS_TO_FETCH,
} from "../config";
import { errorMessageHandler } from "./../utils/error";
import { getEntities } from "../dao/common";

export default function useEntities(entity, options) {
  const [data, setData] = useState([]);
  const [loadingState, setLoadingState] = useState(LOADING_STATE_IDLE);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [filterString, setFilterString] = useState(
    options?.initialFilters ?? ""
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(options?.initialLimit ?? RESULTS_TO_FETCH);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  // initialize params with a fallback to an empty object
  const params = options?.params ?? {};

  const fetchEntitiesData = async (_filterString, _options = {}) => {
    setLoadingState(LOADING_STATE_PENDING);
    try {
      const res = await getEntities(
        `/${entity}`,
        {
          filters: _filterString,
          limit: _options.limit,
          offset: _options.offset,
          overview: true,
          ...(_options.order ? { order: _options.order } : {}),
          ..._options.params,
        },
        _options.abortController
      );
      // guard for when the API responds with {} instead of []
      // which can happen if there is an error
      if (Array.isArray(res.response)) {
        setData(res.response);
        setTotalNumberOfRecords(res._metadata._total);
      }
      setLoadingState(LOADING_STATE_FULFILLED);
    } catch (error) {
      errorMessageHandler(error);
      setHasError(true);
      setError(error.message);
    } finally {
      setLoadingState(LOADING_STATE_IDLE);
    }
  };

  useEffect(() => {
    // effect runs
    // - onmount
    // - onChange filterString
    // - onChange offset
    // - onChange limit
    // - onChange options.order
    // - onChange params.ids
    // - onChange params.object_ids

    const abortController = new AbortController();
    fetchEntitiesData(filterString, {
      abortController,
      offset: offset,
      limit: limit,
      order: options?.order,
      params: params,
    });
    return () => {
      abortController.abort();
    };
  }, [
    filterString,
    offset,
    limit,
    options?.order,
    params?.ids,
    params?.object_ids,
  ]);

  return {
    data,
    error,
    offset,
    setOffset,
    limit,
    setLimit,
    hasError,
    loadingState,
    filterString,
    setFilterString,
    fetchEntitiesData,
    totalNumberOfRecords,
  };
}
