export const ACTION_ON_FIELD_CHANGE = "copy/onFieldChange";
export const ACTION_ON_FORM_RESET = "copy/onFormReset";
export const ACTION_ON_FORM_ENABLED = "copy/onFormEnabled";
export const ACTION_ON_FORM_DISABLED = "copy/onFormDisabled";

export const INITIAL_STATE = {
  newId: {
    value: "",
    hasError: false,
  },
  nTimes: {
    value: "",
    hasError: false,
  },
  formDisabled: true,
};
