
import { useState } from 'react';
import {AlertDialog} from '../components/Dialogs/Alert';
import InfoIcon from '../components/icons/InfoIcon';
import { Box } from '@mui/system';

export default function FieldWrapper({
  children,
  data = { label: "", description: "" },
}) {
  // State
  const [isVisible, setIsVisible] = useState(false);

  const title = `'${data.label}' description`;
  const content = data.description;

  function toggleVisibility() {
    setIsVisible(!isVisible);
  }

  return (
    <Box
      sx={{
        position: "relative",
        "& > .MuiFormControl-root ": {
          width: "100%",
        },
      }}
    >
      {children}
      <AlertDialog
        title={title}
        content={content}
        visible={isVisible}
        setVisible={toggleVisibility}
        />
      <Box
        data-testid="alert-trigger"
        component="span"
        sx={{
          position: "absolute",
          left: "100%",
          top: "0",
        }}
        onClick={toggleVisibility}
      >
        <InfoIcon />
      </Box>
    </Box>
  );
}
