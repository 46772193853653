import { get } from "../api/api";

export const API_PATH = "/types";
export const FILTER_TYPE = "type";
export const FILTER_TYPES = {
  object: "object",
  project: "project",
  system: "system",
  system_group: "system_group",
  location: "location",
  equipment: "equipment",
  section: "section",
  cable: "cable",
  cable_type: "cable_type",
  cable_bundle: "cable_bundle",
  signal: "signal",
  route_point: "route_point",
  drum: "drum",
  order: "order",
};

/**
 * @typedef ObjectType
 * @type object
 * @property {string} abbr
 * @property {string} description
 */

/**
 * Fetch known types for specific
 * @param {string} targetType Filter-type, from `FILTER_TYPES`
 * @returns {Promise<ObjectType[]>}
 */
export async function fetchTypes(targetType, abortController) {
  const params = new URLSearchParams();
  if (targetType) {
    if (!Object.values(FILTER_TYPES).includes(targetType)) {
      throw new Error(
        `Type '${targetType}' is not valid. (Valid options: ${Object.values(
          FILTER_TYPES
        )})`
      );
    }
    params.append("type", targetType);
    params.append("order", "abbr asc");
  }
  const body = await get(`${API_PATH}?${params.toString()}`, abortController);

  return body?.response;
}
