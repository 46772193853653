import { useRef, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

export const RangeFilter = (props) => {
  const inputRef = useRef(null);
  const didMount = useRef(false);
  const timeoutRef = useRef(null);
  const delay = 1000;

  const { item, applyValue, type } = props;

  const [filterValueState, setFilterValueState] = useState([
    item.value?.from || "",
    item.value?.to || "",
  ]);

  useEffect(() => {
    // Return early, if this is the first render:
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    timeoutRef.current = setTimeout(() => {
      applyValue({
        ...item,
        value: {
          from: filterValueState[0] ?? "",
          to: filterValueState[1] ?? "",
        },
      });
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [applyValue, filterValueState, item]);

  const updateFilterValue = (lowerBound, upperBound) => {
    setFilterValueState([lowerBound, upperBound]);
  };

  const handleUpperFilterChange = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };

  const handleLowerFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name='lower-bound-input'
        placeholder='From'
        label='From'
        variant='standard'
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        type={type}
        inputRef={inputRef}
        sx={{ mr: 2 }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        name='upper-bound-input'
        placeholder='To'
        label='To'
        variant='standard'
        value={filterValueState[1]}
        onChange={handleUpperFilterChange}
        type={type}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};
