/*
Adds a isFieldFailed property to each field in the fieldsConfig array.
This property is set to true if the field name is in the invalidFields array.
If the invalidFields array is empty, the isFieldFailed property is set to false.

@param {object[]} fieldsConfig - An array of field objects
@param {string[]} invalidFields - An array of field names, string[]
@return {object[]} An array of field objects with isFieldFailed property
*/

export const updateFieldsWithValidation = (fieldsConfig, invalidFields) => {
  if (!invalidFields || invalidFields?.length === 0)
    return fieldsConfig.map((field) => ({ ...field, isFieldFailed: false }));

  return fieldsConfig.map((field) => {
    const isFieldFailed = invalidFields.includes(field.field_name);
    return { ...field, isFieldFailed };
  });
};
