import compose from "compose-function";
import { get, post } from "../api/api";
import { getCopyBodyArray } from "../utils/dao";

import {
  addAcceptHeader,
  addSearchParams,
  addResponseType,
} from "./../utils/dao";
import { NUMBER_OF_RECORDS_TO_EXPORT } from "../constants/common";

export async function getEntities(
  path,
  urlParams,
  abortController,
  dataType = "application/json"
) {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(urlParams)) {
    if (value !== "" && value !== null && typeof value !== "undefined") {
      params.append(key, value);
    }
  }

  return get(
    addSearchParams(path)(params.toString()),
    abortController,
    addAcceptHeader(dataType)
  );
}

const customModifier = (type) => (reqObject) => {
  return compose(addAcceptHeader(type), addResponseType("blob"))(reqObject);
};

/**
 * async function for fetching
 * @param {string} path - the path
 * @param {Object} searchParams - the search params
 * @param {AbortController} abortController - the abort controller
 * @param {string} dataType - the data type
 * @returns {Promise} - the result
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.3/console/method/%233325/}
 */
export async function exportRecords(
  path,
  searchParams,
  abortController,
  dataType = "text/csv"
) {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(searchParams)) {
    if (value !== "" && value !== null && typeof value !== "undefined") {
      params.append(key, value);
    }
  }
  // always add these params for export
  params.append("limit", NUMBER_OF_RECORDS_TO_EXPORT);
  params.append("overview", true);

  try {
    return await get(
      addSearchParams(path)(params.toString()),
      abortController,
      customModifier(dataType)
    );
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Sends POST request to backend
 * for copying a record
 * @param {Object} record
 * @param {Object} options
 * @returns {Promise|boolean}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.5/console/method/%234284/ }
 */
export async function copyRecord(record = {}, options = {}) {
  const requestOptions = {
    nTimes: 1,
    newRecordId: null,
    startNumber: null,
    type: "",
    ...options,
  };
  // guard
  if (Object.keys(record).length === 0 || Object.keys(options).length === 0) {
    return false;
  }

  return post(
    "/copy",
    getCopyBodyArray([
      {
        ...record,
        number_start_with: requestOptions.startNumber,
        nTimes: requestOptions.nTimes,
        type: requestOptions.type,
      },
    ])
  );
}

/**
 * POST to edit endpoint with payload
 * @param {string} type the entity type
 * @param {string[]} ids the ids to update
 * @param {object} editedFields the updated fields
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.5/console/method/%234880/ Docs}
 */
export async function editRecords(payload = {}) {
  const requestPayload = {
    ids: [],
    type: "",
    fields: {},
    ...payload,
  };
  // guard
  if (
    requestPayload.ids.length === 0 ||
    Object.values(requestPayload.fields).length === 0 ||
    requestPayload.type === ""
  ) {
    return Promise.reject(new Error("Invalid payload passed as argument"));
  }

  return post("/edit", {
    source: {
      type: requestPayload.type,
      ids: requestPayload.ids,
    },
    edit: requestPayload.fields,
  });
}
