import compose from "compose-function";
import { get, del, patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const OBJECT_API_PATH = "/objects";
export const EPLAN_API_PATH = "/eplan/objects";

export async function patchObject(object, objectId, isInlineEdit) {
  return patch(
    `${OBJECT_API_PATH}/${objectId}` + getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("number"),
      sanitizeEntity("projects"),
      sanitizeEntity("reference")
    )(object)
  );
}

export async function addObject(object) {
  const body = await post(OBJECT_API_PATH, object);
  return body?.response;
}

export async function softDeleteObject(object) {
  const body = await patch(`${OBJECT_API_PATH}/${object.id}`, {
    delete: true,
  });
  return body?.response;
}

export async function restoreObject(object) {
  const body = await patch(`${OBJECT_API_PATH}/${object.id}`, {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteObject(object) {
  const body = await del(`${OBJECT_API_PATH}/${object.id}`);
  return body?.response;
}

/**
 * Sends GET request to backend
 * for exporting an Object item for Eplan
 * @param {Object} object
 * @param {Object} abortController
 * @returns {string}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.2/console/method/%233078/}
 */
export async function fetchObjectExportEplan(objectId, abortController) {
  return get(`${EPLAN_API_PATH}/${objectId}`, abortController);
}

/**
 * Sends POST request to backend
 * for copying an Object item
 * @param {Object} object
 * @param {Number} newObjectNumber
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.1/console/method/%232018/}
 */
export async function copyObject(object = {}, newObjectNumber = 0) {
  return post([OBJECT_API_PATH, object.id, "copy"].join("/"), {
    number: newObjectNumber,
  });
}
