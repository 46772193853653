import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const LOCATIONS_API_PATH = "/locations";

export async function patchLocation(location, locationId, isInlineEdit) {
  return patch(
    [LOCATIONS_API_PATH, locationId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("object_number"),
      sanitizeEntity("section_number")
    )(location)
  );
}

export async function addLocation(location) {
  const body = await post(LOCATIONS_API_PATH, location);
  return body?.response;
}

export async function softDeleteLocation(location) {
  const body = await patch([LOCATIONS_API_PATH, location.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

export async function restoreLocation(location) {
  const body = await patch([LOCATIONS_API_PATH, location.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteLocation(location) {
  const body = await del([LOCATIONS_API_PATH, location.id].join("/"));
  return body?.response;
}
