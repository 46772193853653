import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';

/**
 * state initialization function
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
  };
}

export default function useLocation(
  locationId = null,
  objectId = null,
  ) {
  const [ location, setLocation ] = useState(createInitialState);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchLocationData = (abortController) => {
    if (locationId) {
      getEntities(
        '/locations',
        {
          object_ids: objectId,
          ids: locationId,
        },
        abortController,
        ).then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setLocation(response[0]);
          setIsLoading(false);
      }).catch(errorMessageHandler);
    } else {
      if (objectId) {
         setLocation((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
        }));
      }
      setIsLoading(false);
    }
  };

  /**
   * useEffect should have a dependency on number
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchLocationData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ locationId ]);

  return { 
    location,
    fetchLocationData,
    isLoading 
  };
}
