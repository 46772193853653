export const OPERATOR_DICTIONARY = {
  contains: "search",
  equals: "exact",
  startsWith: "search",
  endsWith: "search",
  isAnyOf: "exact_multi",
  betweenDates: "date",
  is: "exact",
  after: "date",
  before: "date",
  exactDate: "date",
  doesNotContain: "not",
  equalsNot: "exact_not",
  isNot: "exact_not",
  containsMultiple: "contain_multi",
  doesNotContainMultiple: "not_contain_multi",
  "=": "number",
  ">=": "number",
  "<=": "number",
  betweenNumbers: "number",
  isEmpty: "empty",
  isNotEmpty: "not_empty",
};
