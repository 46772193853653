import React, { useState } from 'react';

export const FormContext = React.createContext(null);

export function FormContextProvider({ children }) {
  const [formErrors, setFormErrors] = useState(null);
  const [formEntity, setFormEntity] = useState({});
  const [objectId, setObjectId] = useState(null);
  const [systemGroupId, setSystemGroupId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [objectEntity, setObjectEntity] = useState(null);
  const [projectEntity, setProjectEntity] = useState(null);
  const [systemGroupEntity, setSystemGroupEntity] = useState(null);
  const [systemEntity, setSystemEntity] = useState(null);
  const [locationEntity, setLocationEntity] = useState(null);
  const [equipmentEntity, setEquipmentEntity] = useState(null);
  const [sectionEntity, setSectionEntity] = useState(null);
  const [cableBundleEntity, setCableBundleEntity] = useState(null);

  const formData = {
    formErrors,
    setFormErrors,
    formEntity,
    setFormEntity,
    objectId,
    setObjectId,
    systemGroupId,
    setSystemGroupId,
    systemId,
    setSystemId,
    locationId,
    setLocationId,
    objectEntity,
    setObjectEntity,
    projectEntity,
    setProjectEntity,
    systemGroupEntity,
    setSystemGroupEntity,
    systemEntity,
    setSystemEntity,
    locationEntity,
    setLocationEntity,
    equipmentEntity,
    setEquipmentEntity,
    sectionEntity,
    setSectionEntity,
    cableBundleEntity,
    setCableBundleEntity
  };
  return (
    <FormContext.Provider value={formData}>{children}</FormContext.Provider>
  );
}
