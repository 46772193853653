import { createTheme } from "@mui/material/styles";

const spacing = 8;
// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createTheme({
  palette: {
    primary: {
      main: "#00904b",
      light: "#00904b14",
    },
    secondary: {
      main: "#FA8F25",
    },
    lightGrey: {
      main: "#E7E7E7",
      contrastText: "#262626",
    },
    grey: {
      light: "#E7E7E7",
      main: "#E7E7E7",
      dark: "#d3d3d3",
    },
    salmon: "#EFE5CB",
    white: "#FFF",
    red: {
      main: "#FBE9E7",
      dark: "#FFCCBC",
    },
    black: "#000",
  },
  spacing,
  drawerWidth: {
    open: 30 * spacing,
    closed: 7 * spacing,
  },
  gap: spacing * 4,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 2,
            borderColor: "rgba(0, 0, 0, 0.6)",
          },
          "& .Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-error, &.Mui-focused": {
            color: "black",
          },
        },
      },
    },
  },
});
