import { Box, IconButton } from "@mui/material";
import { theme } from "../../setting";
import { ReportDropdown } from "../ReportDropdown";
import { ExportDropdown } from "../ExportDropdown";

export function ActionBar({
  controls = [],
  selectedRows,
  entity,
  exportOptions = {},
  renderCreateEntityButton,
  assignModal,
}) {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey.main,
        borderRadius: 1,
        position: "absolute",
        top: -2,
        right: 0,
        zIndex: "2",
        padding: "5px",
        display: "flex",
        gap: "5px",
      }}
      data-testid='action-bar'
    >
      {renderCreateEntityButton && renderCreateEntityButton({ selectedRows })}
      {/* Always show export action */}
      <ExportDropdown entity={entity} exportOptions={exportOptions} />
      {/* Always show report action */}
      <ReportDropdown selectedRows={selectedRows} entity={entity} />

      {!assignModal && controls.length > 0 && (
        <>
          {controls.map((control) => (
            <IconButton
              key={control.id}
              size='small'
              aria-label={control.name}
              disabled={control.isDisabled}
              sx={{ padding: 0 }}
              title={control.isDisabled ? "First select row(s)" : control.name}
              onClick={control.handleClick}
              data-testid={control.testId}
            >
              {control.icon}
            </IconButton>
          ))}
        </>
      )}
    </Box>
  );
}
