import { theme } from "./../setting";

export const DATA_GRID_CONTAINER_STYLES = {
  border: "none",
  "& .MuiBadge-badge": {
    display: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent": {
    fontWeight: "bold",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "&.MuiDataGrid-root .MuiDataGrid-row.even": {
    backgroundColor: "red",
  },
  "&.MuiDataGrid-root .MuiDataGrid-row.deleted-true": {
    backgroundColor: theme.palette.red.main,
  },

  "&.MuiDataGrid-root .MuiDataGrid-row.deleted-true:hover": {
    backgroundColor: theme.palette.red.dark,
  },
  "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
    justifyContent: "flex-start",
  },
  "&.MuiDataGrid-root .MuiToolbar-root": {
    paddingLeft: 0,
  },
  "&.MuiDataGrid-root .MuiTablePagination-displayedRows": {
    order: 2,
  },
  "&.MuiDataGrid-root .MuiTablePagination-actions": {
    order: 1,
    marginLeft: 0,
  },
  "&.MuiDataGrid-root .highlighted-row": {
    backgroundColor: theme.palette.primary.light,
    transition: "background-color 0.5s",
  },
  "&.MuiDataGrid-root .highlighted-row-deleted": {
    backgroundColor: `${theme.palette.red.dark} !important`,
    transition: "background-color 0.5s",
  },
};

export const DATA_GRID_FILTER_PANEL_STYLES = {
  // Hide first filter delete button,
  // so filter can only be cleared by removing the value.
  // This makes sure the filter always responds to changes",
  "& .MuiDataGrid-filterForm:first-of-type .MuiDataGrid-filterFormDeleteIcon": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-filterFormOperatorInput": {
    minWidth: "210px",
  },
  "& .MuiDataGrid-filterFormColumnInput": {
    width: "auto",
  },
  "& .MuiDataGrid-filterFormValueInput": {
    minWidth: "260px",
  },
  // Below is to remove padding from the value input for date / is between filter
  "& div:has(> .MuiTextField-root)": {
    paddingLeft: "0",
  },
};
