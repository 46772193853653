import FieldType from "../enums/FieldType";
import FieldName from "../enums/FieldName";
import logLevel from "loglevel";
import { dateToString } from "../utils/utils";
const log = logLevel.getLogger("verbose");

/**
 * Determine if a 'value' is actually anything other than `null` or
 * `''`.
 *
 * @param {Any} value Any value, except `undefined`
 * @return {boolean} returns `true` if `value` is something
 */
function hasValue(value) {
  return value !== null && value.toString().length > 0;
}

/**
 * Determine if default data is configured for a field and generate
 * or fetch it.
 *
 * @param {JSON} field Field-data
 * @param {User} user A User Object as defined by MS
 * @return {*} Generated data base on field.default_value
 */
export function getDefaultValue(field = {}, user = {}) {
  switch (field.type) {
    case FieldType.DATE: {
      if (field.default_value?.includes("f('today')")) {
        const currentDate = dateToString(new Date());
        return currentDate;
      }
      return null;
    }
    case FieldType.TEXT: {
      return field.default_value ?? "";
    }
    case FieldType.BOOLEAN: {
      return field.default_value ?? false;
    }
    default:
      if (field.default_value?.includes?.("f('username')")) {
        return user.username;
      }
      // all other cases
      return field.default_value ?? null;
  }
}

/**
 * Map all fields without a value to pre-defined or generated defaults where
 * applicable.
 *
 * @param {Field[]} fields An array of field-Objects
 * @param {User} user A user object from MS
 * @return {Dictionary} Key-value pairs of all fields with default or null value
 */
export function mapDefaultValues(fields = [], user = { username: "Guest" }) {
  // Reduce all fields to a dictionary of field-names and default values
  return fields.reduce((accumulator, field) => {
    accumulator[field.field_name] = getDefaultValue(field, user);
    return accumulator;
  }, {});
}

/**
 * Helper function to generate a String version of a field-value, in case
 * of 'weird' Object types.
 *
 * @param {String} fieldName name of field
 * @param {Object} fieldValue value of field
 * @return {String}
 */
function valueAsString(fieldName = "", fieldValue = {}) {
  if (fieldName === FieldName.CUSTOMER) {
    return fieldValue?.name ?? "";
  }
  return fieldValue;
}

/**
 * Set all known values from the back-end into the form-fields, may cause
 * invalid values for lookup-fields, without triggering an error.
 * This should override empty and 'default' field values
 *
 * @param {FormState} state Form-state Object
 * @param {Dictionary} values Key-value pairs of field-names and values
 */
export function setKnownValues(
  state = { setField: () => {} },
  sortedFields = [],
  values = {}
) {
  sortedFields.flat().forEach((field) => {
    const fieldvalue = values[field.field_name] ?? "";
    const stringValue = valueAsString(field.field_name, fieldvalue);
    log.trace(field, fieldvalue, stringValue);
    if (hasValue(stringValue)) {
      state.setField(field.field_name, stringValue);
    }
  });
}

/**
 * Checks the value of the field against a visible rule
 * @param {object} visible - the visibility rule
 * @param {string} matchedFieldName
 * @param {object} values - the actual values
 * @returns {boolean} hasMatch flag
 */
export function hasMatchedValue(visible, matchedFieldName, values) {
  // guard
  if (!visible[matchedFieldName] || !values[matchedFieldName]) {
    return false;
  }
  const regex = new RegExp(visible[matchedFieldName].toString().toLowerCase());
  return regex.test(values[matchedFieldName].toString().toLowerCase());
}

/**
 * Check if a field is made invisible by other values of this job.
 *
 * @param {JSON} visibility set of visibility checks, or `null`
 * @param {Dictionary} formValues Formstate values
 * @param {String} entityType The type of the item edited
 * @return {boolean} true if visible
 */
export function isVisible({ visible }, formValues, entityType) {
  if (visible?.[entityType]) {
    // Extract the complete visible object with all visiblity rules.
    const visibleFields = visible[entityType];
    // Loop through all rules.
    const matchedValues = visibleFields.map((options) => {
      // Check if field value matches the value to check against.
      const matchedValues = Object.keys(options).map((fieldName) => {
        return hasMatchedValue(options, fieldName, formValues);
      });
      // Evaluating as AND (check if all rules are matched).
      return !matchedValues.includes(false);
    });

    // Evaluating as OR (check if any rules are matched).
    return matchedValues.includes(true);
  }

  return true;
}

/**
 * Get empty required fields from the provided state object based on the specified fields.
 *
 * @param {Object} state - The state object containing values for fields.
 * @param {Array} fields - An array of field objects specifying field_name and required properties.
 *
 * @return {Array} - An array containing the names of fields that are required but have empty values.
 */
export const getEmptyRequiredFields = (state, fields) => {
  if (!state) return [];

  const { values } = state;

  const emptyRequiredFields = Object.keys(values).filter((fieldName) => {
    const value = values[fieldName];
    const isValueEmpty = value === "" || value === null || value === undefined;
    const field = fields.find((field) => field.field_name === fieldName);
    const required = field?.required;
    if (required && isValueEmpty) return field;
  });
  return emptyRequiredFields;
};

/**
 * Filter for all fields which have a default value and have not been
 * modified by the user.
 *
 * @param {FormState} state FormState Object
 * @return {Field[]} An array of field-definitions which are default
 */
export function getDefaultFields(state = { touched: [], values: [] }) {
  const { touched, values } = state;
  // Has a value, but not been touched
  const defaults = Object.keys(values).filter((fieldName) => {
    const value = values[fieldName];
    const isTouched = touched[fieldName];
    return value !== "" && !isTouched;
  });
  const untouched = defaults.map((name) => {
    const value = values[name];
    return {
      name,
      value,
    };
  });
  return untouched;
}

function calculateSortorder(fieldA, fieldB) {
  const groupOrder = fieldA.group_sort_order - fieldB.group_sort_order;
  if (groupOrder !== 0) {
    return groupOrder;
  }

  return fieldA.sort_order.form - fieldB.sort_order.form;
}

/**
 * Group fields by subject, link `Unit of Measurement` fields to their
 * value inputs and hide fields that should not be visible.
 *
 * @param {Field[]} fields Array of fields in order of appearance.
 * @param {Dictionary} values Key/value pairs of field-values (optional)
 * @param {Function} sortingFilter Optional filter to run before sorting.
 * @return {Field[]} Array with grouped fields per subject
 */
export function sortFields(
  fields = [],
  values = {},
  sortingFilter = () => true
) {
  const sortedFields = fields.filter(sortingFilter).sort(calculateSortorder);
  const groups = [
    // Fast conversion to unique values
    ...new Set(sortedFields.map((data) => data.groupname)),
  ].reduce(
    // Convert array to dictionary
    (accumulator, groupName) => {
      accumulator[groupName] = [];
      return accumulator;
    },
    {}
  );

  let link = null;
  // Create two-way link of linked fields
  // Running array in reverse so that we don't have to 'look back' to find
  // linked fields.
  for (let i = sortedFields.length - 1; i >= 0; --i) {
    const field = sortedFields[i];
    const group = groups[field.groupname];

    if (link) {
      field.link = link;
      link.count++;
      if (link.linkedTo === field.sort_order.form) {
        link = null;
      }
    }
    if (field.linked_to) {
      link = {
        linkedTo: field.linked_to,
        linkedFrom: field.sort_order.form,
        count: 1,
      };
      field.link = link;
    }

    group.unshift(field);
  }

  return Object.keys(groups)
    .filter((groupname) => groups[groupname].length > 0)
    .map((groupname) => {
      const group = groups[groupname];
      group.name = groupname;
      return group;
    });
}
