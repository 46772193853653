/**
 * Merges two arrays of objects based on a common property 'id'
 *
 * @param {array} array1 - The first array
 * @param {array} array2 - The second array
 * @returns {Array} The merged array sorted by id, if common property is found, then array2's property will be used
 */
export const mergeArraysById = (array1, array2) => {
  const mergedArray = array1.reduce((result, item1) => {
    const matchingItemIndex = array2.findIndex(
      (item2) => item2?.id === item1?.id
    );
    if (matchingItemIndex !== -1) {
      result[matchingItemIndex] = { ...item1, ...array2[matchingItemIndex] };
    } else {
      result.push(item1);
    }
    return result;
  }, array2.slice());

  const sortedArray = mergedArray.sort((a, b) => a.id - b.id);
  return sortedArray;
};

/**
 * Sorts an array of objects based on a common property using a reference array of objects.
 * @param {Array} primaryArray - The array of objects to be sorted.
 * @param {Array} referenceArray - The reference array of objects used for sorting.
 * @param {string} propertyName - The name of the common property to use for sorting.
 * @returns {Array} A new sorted array based on the order of objects in the reference array.
 */
export const sortArraysOfObjectsByCommonProperty = (
  primaryArray,
  referenceArray,
  propertyName
) => {
  return primaryArray.sort((a, b) => {
    const aIndex = referenceArray.findIndex(
      (item) => item[propertyName] === a[propertyName]
    );
    const bIndex = referenceArray.findIndex(
      (item) => item[propertyName] === b[propertyName]
    );

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
};

/**
 * Reorders an array of operators based on specified ordering conditions.
 *
 * @param {Object[]} operators - The array of operator objects to be reordered.
 * @param {Object[]} operatorsOrder - An array of objects specifying the reorder conditions.
 *                                Each object must have an 'operator' key specifying the value
 *                                of the operator to move, and an 'afterWhich' key specifying
 *                                the value of the operator after which the first operator should be placed.
 * @returns {Object[]} - A new array of operator objects reordered according to the conditions.
 */
export const reorderOperators = (operators, operatorsOrder) => {
  // guards
  if (!operators || operators.length === 0) return [];
  if (!operatorsOrder || operatorsOrder.length === 0) return operators;

  const reorderedOperators = [...operators];

  // Process each ordering condition from the end, so earlier insertions don't affect the indices of later ones
  operatorsOrder
    .slice()
    .reverse()
    .forEach(({ operator, afterWhich }) => {
      // Find the index of the operator and the element it should come after
      const operatorIndex = reorderedOperators.findIndex(
        (op) => op.value === operator
      );
      const afterWhichIndex = reorderedOperators.findIndex(
        (op) => op.value === afterWhich
      );

      if (operatorIndex > -1 && afterWhichIndex > -1) {
        // Remove the operator from its current position
        const [operatorObj] = reorderedOperators.splice(operatorIndex, 1);
        // Insert the operator right after the 'afterWhich' element
        reorderedOperators.splice(afterWhichIndex + 1, 0, operatorObj);
      }
    });

  return reorderedOperators;
};
