import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Box,
  Alert,
} from "@mui/material";
import { useEffect, useMemo, useReducer } from "react";
import { getType, noop } from "../../utils/utils";
import { DIALOG_COPY_HIDE } from "../../components/TableComponent";
import { reducer } from "./reducer";
import {
  ACTION_ON_FORM_ENABLED,
  ACTION_ON_FORM_DISABLED,
  ACTION_ON_FIELD_CHANGE,
  ACTION_ON_FORM_RESET,
  INITIAL_STATE,
} from "./constants";
import { validateValues, isValidValue } from "./utils";

export const CopyDialog = ({
  handler = noop.reject,
  visible = false,
  setVisible = noop.fn,
  rows = [],
  entityType,
}) => {
  // =========================
  // State
  // =========================

  const [formState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const showWarning = useMemo(() => {
    const SHOW_WARNING_LIMIT = 49;
    return Number(formState.nTimes.value) > SHOW_WARNING_LIMIT;
  }, [formState.nTimes.value]);

  useEffect(() => {
    // values are empty
    if (
      validateValues([formState.newId.value, formState.nTimes.value])(
        isValidValue
      )
    ) {
      return dispatch({ type: ACTION_ON_FORM_ENABLED });
    }
    return dispatch({ type: ACTION_ON_FORM_DISABLED });
  }, [formState.newId.value, formState.nTimes.value]);

  // =========================
  // Event Handlers
  // =========================

  function handleSubmit(e) {
    e.preventDefault();
    handler(Number(formState.newId.value), Number(formState.nTimes.value));
    handleCloseClick();
  }

  function handleCloseClick() {
    setVisible({ type: DIALOG_COPY_HIDE });
    dispatch({ type: ACTION_ON_FORM_RESET });
  }

  function handleChange(e) {
    const value = e.target.value;
    dispatch({
      type: ACTION_ON_FIELD_CHANGE,
      payload: {
        [e.target.id]: {
          value,
          hasError: isValidValue(value) === false,
        },
      },
    });
  }

  // Field configuration
  const FIELDS = [
    {
      label: "Start numbering from",
      id: "newId",
      "data-testid": "newId",
      autoFocus: true,
      value: formState.newId.value,
      error: formState.newId.hasError,
      required: true,
      helperText: "This is a required field",
      onChange: handleChange,
      type: "number",
    },
    {
      label: "How many time you want to copy this record?",
      id: "nTimes",
      autoFocus: false,
      "data-testid": "nTimes",
      value: formState.nTimes.value,
      error: formState.nTimes.hasError,
      required: true,
      helperText: "This is a required field",
      onChange: handleChange,
      type: "number",
    },
  ];

  // =========================
  // Render
  // =========================

  if (visible === false) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={visible}
        onClose={handleCloseClick}
        aria-labelledby='copy-dialog-title'
        fullWidth={true}
        data-testid='copyDialog'
        maxWidth='sm'
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id='copy-dialog-title' data-testid='dialogTitle'>
            Copy record
            {rows.map(({ number }) => (
              <span key={number}>{` #${number}`}</span>
            ))}
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: 2,
                gap: 4,
              }}
            >
              {showWarning && (
                <Alert
                  severity='warning'
                  sx={{ display: "flex", alignItems: "center" }}
                  data-testid='copy-warning'
                >
                  Warning: You are about to copy the selected{" "}
                  {getType(entityType)} {formState.nTimes.value} times, are you
                  sure?
                </Alert>
              )}
              {FIELDS.map((field) => (
                <TextField
                  key={field.id}
                  InputLabelProps={{ shrink: true }}
                  {...field}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              marginBottom: 2,
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Button
              onClick={handleCloseClick}
              color='primary'
              data-testid='cancelButton'
            >
              Cancel
            </Button>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              data-testid='copyButton'
              disabled={formState.formDisabled}
            >
              Copy
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
