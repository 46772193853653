import React from "react";
import compose from "compose-function";
import moment from "moment";

import TableComponent from "../../components/TableComponent";
import CreateItemButton from "../../components/CreateItemButton";

import { useColumns } from "../../hooks/useColumns";
import useUserPermissionsContext from "../../hooks/useUserPermissionsContext";

import {
  getSortOrder,
  sortColumns,
  sortModelToString,
  fetchDataWithOptionsAndFilters,
  exportToFile,
} from "../../utils/utils";

import {
  addHrefToRow,
  changeDeletedToBoolean,
  readDataFromLocalStorage,
  getDefaultFilterModel,
  getInitialFilters,
  generateEntityKeyForLocalStorage,
} from "../../utils/table";

import { softDelete, hardDelete } from "../../dao/operations";
import { copyRecord, editRecords, exportRecords } from "../../dao/common";
import { FILTER_TYPES } from "../../dao/types";

import UserRights from "../../enums/UserRights";

import { ROUTES } from "../../enums/Routes";
import { addSearchParams } from "../../utils/dao";

import { API_ROUTES } from "../../enums/api";
import useEntities from "../../hooks/useEntities";
import { useSortModel } from "../../hooks/useSortModel";

const API_ROUTE = API_ROUTES["cable-type"];

/**
 * Here we need to transform the row data retrieved,
 * in order to make it compatible with @mui/x-data-grid-pro
 */
const transformRows = (rows) => {
  return rows.reduce((acc, row) => {
    return [
      ...acc,
      compose(
        changeDeletedToBoolean("deleted"),
        addHrefToRow({
          field: "id",
          slug: "cable-type",
        })
      )(row),
    ];
  }, []);
};

/**
 * Here we need to transform the column data retrieved,
 * in order to add @mui/x-data-grid-pro formatters
 */
const transformColumns = (columns) => {
  return columns.reduce((acc, column) => {
    return [...acc, column];
  }, []);
};

export default function CableTypesContainer() {
  const { sortModel, setSortModel } = useSortModel(FILTER_TYPES.cable_type);

  // Get cached local filter model from local storage
  // with a fallback to default field values
  const cachedLocalFilter = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(FILTER_TYPES.cable_type, "filterModel")
  ) ?? {
    filterModel: null,
    filterJSON: "",
  };

  // destructure fields from cachedLocalFilter
  const {
    filterModel: cachedLocalFilterModel,
    filterJSON: cachedFilterJSONString,
  } = cachedLocalFilter;

  const initialFilters = getInitialFilters({
    cache: {
      localFilterModel: cachedLocalFilterModel,
      filterJSONString: cachedFilterJSONString,
    },
    defaultFilterModel: getDefaultFilterModel("type").filterModel,
  });

  const { columns } = useColumns(FILTER_TYPES.cable_type);

  const sortModelString = sortModelToString(sortModel, columns);

  const {
    data,
    loadingState,
    fetchEntitiesData: fetchCableTypesData,
    setFilterString,
    setOffset,
    limit,
    offset,
    setLimit,
    totalNumberOfRecords,
  } = useEntities("cable-types", {
    initialFilterModel: initialFilters.apiFilterString,
    order: sortModelString,
  });

  const fetchRecordsDataWithGlobalFilters = fetchDataWithOptionsAndFilters(
    fetchCableTypesData
  )({
    limit,
    offset,
    order: sortModelString,
  });

  const fetchExportData = async (filterString, selectedColumnsString) => {
    const abortController = new AbortController();
    const searchParams = {
      filters: filterString,
      columns: selectedColumnsString,
    };
    const response = await exportRecords(
      API_ROUTE,
      searchParams,
      abortController
    );

    exportToFile(
      `export_${FILTER_TYPES.cable_type}_overview_${moment().format(
        "DD-MM-YYYY"
      )}.csv`,
      response
    );

    return "Export done";
  };

  const { isAllowedTo } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.cable_type
  );
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.cable_type
  );
  const isAllowedToExport = isAllowedTo(UserRights.EXPORT_CSV);
  const isAllowedToMassEdit = isAllowedTo(UserRights.MASS_EDIT_CABLE_TYPE);

  const tableOptions = {};
  tableOptions.hasDelete = isAllowedToDelete;
  tableOptions.hasCopy = false;
  tableOptions.hasMassEdit = isAllowedToMassEdit;
  tableOptions.sortOrder = getSortOrder(FILTER_TYPES.cable_type);
  tableOptions.hasInlineEdit = false;

  const searchParams = new URLSearchParams().toString();

  return (
    <TableComponent
      {...{
        columns: transformColumns(sortColumns(columns)),
        transformColumns,
        loading: loadingState,
        rows: transformRows(data),
        refreshData: fetchRecordsDataWithGlobalFilters,
        renderCreateEntityButton: () => (
          <CreateItemButton
            to={addSearchParams(ROUTES.cableType.create)(searchParams)}
            isDisabled={!isAllowedToCreate}
            entity={FILTER_TYPES.cable_type}
          />
        ),
        copyEntry: copyRecord,
        editRecords,
        softDeleteEntry: softDelete({
          route: API_ROUTE,
          delete: true,
        }),
        hardDeleteEntry: hardDelete({
          route: API_ROUTE,
        }),
        restoreEntry: softDelete({
          route: API_ROUTE,
          delete: false,
        }),
        tableOptions,
        setFilters: setFilterString,
        setOffset,
        offset,
        setLimit,
        sortModel,
        setSortModel,
        numberOfRecords: totalNumberOfRecords,
        entityRoute: API_ROUTE,
        isAllowedToExport,
        entity: FILTER_TYPES.cable_type,
        nestedEntity: false,
        initialFilterModel: initialFilters.grid,
        exportRecords: fetchExportData,
      }}
    />
  );
}
