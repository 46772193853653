import { del, patch, post } from "../api/api";
import { getInlineEditQueryParam } from "../utils/utils";

export const ROUTE_POINT_API_PATH = "/route-points";
/**
 * patchRoutePoint
 * @param {Object} payload
 * @param {string} id
 * @param {boolean} isInlineEdit
 * @returns
 */
export async function patchRoutePoint(payload, id, isInlineEdit) {
  return patch(
    `${ROUTE_POINT_API_PATH}/${id}` + getInlineEditQueryParam(isInlineEdit),
    payload
  );
}

export async function addRoutePoint(object) {
  const body = await post(ROUTE_POINT_API_PATH, object);
  return body?.response;
}

export async function softDeleteRoutePoint(object) {
  const body = await patch(`${ROUTE_POINT_API_PATH}/${object.id}`, {
    delete: true,
  });
  return body?.response;
}

export async function restoreRoutePoint(object) {
  const body = await patch(`${ROUTE_POINT_API_PATH}/${object.id}`, {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteRoutePoint(object) {
  const body = await del(`${ROUTE_POINT_API_PATH}/${object.id}`);
  return body?.response;
}

/**
 * Sends POST request to backend
 * for copying an Route point item
 * @param {Object} object
 * @param {Number} newObjectNumber
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.1/console/method/%232018/}
 */
export async function copyRoutePoint(object = {}, newObjectNumber = 0) {
  return post([ROUTE_POINT_API_PATH, object.id, "copy"].join("/"), {
    number: newObjectNumber,
  });
}
