import React, { useContext } from "react";
import compose from "compose-function";
import moment from "moment";

import TableComponent from "../../components/TableComponent";
import CreateItemButton from "../../components/CreateItemButton";
import { addSearchParams } from "../../utils/dao";
import { useColumns } from "../../hooks/useColumns";
import useUserPermissionsContext from "../../hooks/useUserPermissionsContext";

import {
  getSortOrder,
  sortColumns,
  sortModelToString,
  fetchDataWithOptionsAndFilters,
  exportToFile,
  dateToString,
} from "../../utils/utils";

import {
  addHrefToRow,
  changeDeletedToBoolean,
  addValueFormatter,
  padValueWith,
  readDataFromLocalStorage,
  getInitialFilters,
  getDefaultFilterModel,
  generateEntityKeyForLocalStorage,
} from "../../utils/table";

import { softDelete, hardDelete } from "../../dao/operations";

import { copyRecord, editRecords, exportRecords } from "../../dao/common";

import { FILTER_TYPES } from "../../dao/types";

import UserRights from "../../enums/UserRights";
import { ROUTES } from "../../enums/Routes";

import { GlobalContext } from "../../globalContext";
import useEntities from "../../hooks/useEntities";
import { useSortModel } from "../../hooks/useSortModel";

const ORDER_API_PATH = "/orders";

/**
 * Here we need to transform the row data retrieved,
 * in order to make it compatible with @mui/x-data-grid-pro
 */
const transformRows = (rows) => {
  return rows.reduce((acc, row) => {
    return [
      ...acc,
      compose(
        changeDeletedToBoolean("deleted"),
        addHrefToRow({
          field: "id",
          slug: "order",
        })
      )(row),
    ];
  }, []);
};

/**
 * Here we need to transform the column data retrieved,
 * in order to add @mui/x-data-grid-pro formatters
 */
export const transformColumns = (columns) => {
  return columns.reduce((acc, column) => {
    return [
      ...acc,
      compose(
        addValueFormatter("object_number")(padValueWith(5)),
        addValueFormatter("purchase_date")(dateToString),
        addValueFormatter("purchase_delivery_date")(dateToString)
      )(column),
    ];
  }, []);
};

export default function OrdersContainer() {
  const { globalFilters } = useContext(GlobalContext);
  const { sortModel, setSortModel } = useSortModel(FILTER_TYPES.order);

  // Get cached local filter model from local storage
  // with a fallback to default field values
  const cachedLocalFilter = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(FILTER_TYPES.order, "filterModel")
  ) ?? {
    filterModel: null,
    filterJSON: "",
  };

  // destructure fields from cachedLocalFilter
  const {
    filterModel: cachedLocalFilterModel,
    filterJSON: cachedFilterJSONString,
  } = cachedLocalFilter;

  const initialFilters = getInitialFilters({
    cache: {
      localFilterModel: cachedLocalFilterModel,
      filterJSONString: cachedFilterJSONString,
    },
    defaultFilterModel: getDefaultFilterModel("number").filterModel,
  });

  const { columns } = useColumns(FILTER_TYPES.order);

  const sortModelString = sortModelToString(sortModel, columns);

  const {
    data,
    loadingState,
    fetchEntitiesData: fetchData,
    setFilterString,
    setOffset,
    limit,
    offset,
    setLimit,
    totalNumberOfRecords,
  } = useEntities("orders", {
    params: {
      object_ids: globalFilters?.value ?? null,
    },
    initialFilters: initialFilters.apiFilterString,
    order: sortModelString,
  });

  const fetchRecordsDataWithGlobalFilters = fetchDataWithOptionsAndFilters(
    fetchData
  )({
    limit,
    offset,
    order: sortModelString,
    params: {
      object_ids: globalFilters?.value ?? null,
    },
  });

  const fetchExportData = async (filterString, selectedColumnsString) => {
    const abortController = new AbortController();
    const searchParams = {
      filters: filterString,
      columns: selectedColumnsString,
      object_ids: globalFilters?.value ?? null,
    };
    const response = await exportRecords(
      ORDER_API_PATH,
      searchParams,
      abortController
    );

    exportToFile(
      `export_${FILTER_TYPES.order}_overview_${moment().format(
        "DD-MM-YYYY"
      )}.csv`,
      response
    );

    return "Export done";
  };

  const { isAllowedTo } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.order);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.order
  );

  const isAllowedToExport = isAllowedTo(UserRights.EXPORT_CSV);
  const isAllowedToMassEdit = isAllowedTo(UserRights.MASS_EDIT_ORDER);
  const isAllowedToInlineEdit = isAllowedTo(UserRights.INLINE_EDIT_ORDER);

  const tableOptions = {
    hasDelete: isAllowedToDelete,
    sortOrder: getSortOrder(FILTER_TYPES.order),
    hasMassEdit: isAllowedToMassEdit,
    hasCopy: isAllowedToCreate,
    hasControls: true,
    hasInlineEdit: isAllowedToInlineEdit,
  };

  const searchParams = new URLSearchParams(
    Object.entries({
      objectId: globalFilters?.value,
    }).filter(([, value]) => value)
  ).toString();

  return (
    <TableComponent
      {...{
        columns: transformColumns(sortColumns(columns)),
        transformColumns,
        loading: loadingState,
        rows: transformRows(data),
        refreshData: fetchRecordsDataWithGlobalFilters,
        renderCreateEntityButton: () => (
          <CreateItemButton
            to={addSearchParams(ROUTES.order.create)(searchParams)}
            isDisabled={!isAllowedToCreate}
            entity={FILTER_TYPES.order}
          />
        ),
        copyEntry: copyRecord,
        editRecords,
        softDeleteEntry: softDelete({
          route: ORDER_API_PATH,
          delete: true,
        }),
        hardDeleteEntry: hardDelete,
        restoreEntry: softDelete({
          route: ORDER_API_PATH,
          delete: false,
        }),
        tableOptions,
        setFilters: setFilterString,
        setOffset,
        offset,
        setLimit,
        sortModel,
        setSortModel,
        numberOfRecords: totalNumberOfRecords,
        isAllowedToExport,
        entity: FILTER_TYPES.order,
        nestedEntity: false,
        initialFilterModel: initialFilters.grid,
        exportRecords: fetchExportData,
      }}
    />
  );
}
