import React from "react";
import clsx from "clsx";
import { TextField, Autocomplete } from "@mui/material";

import useProjectTypes from "../../hooks/useProjectTypes";
import { getNameFromValue } from "../../utils/form";

function isSameValue(valueA, valueB) {
  return valueA?.abbr === valueB;
}

export default function ProjectTypeLookup({
  className,
  data,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  ...props
}) {
  const [projectTypes] = useProjectTypes();
  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Delibirately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
  });

  if (!projectTypes) {
    return null;
  }

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value.abbr;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
    getOptionSelected: isSameValue,
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    variant,
    required,
    helperText,
    ...inputProps,
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={projectTypes}
      getOptionLabel={(value) => getNameFromValue(projectTypes, value)}
      isOptionEqualToValue={(option, value) => isSameValue(option, value)}
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          {...finalInputProps}
          fullWidth
          label={
            finalInputProps.required
              ? `${finalInputProps.label} (required)`
              : finalInputProps.label
          }
          error={data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      )}
    />
  );
}
