import React, { useContext } from "react";
import {
  Alert,
  Card,
  CardHeader,
  CardActions,
  Button,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import ProjectsIcon from "../components/icons/ProjectsIcon";
import ShipIcon from "../components/icons/ShipIcon";
import SystemGroupIcon from "../components/icons/SystemGroupIcon";
import SystemsIcon from "../components/icons/SystemsIcon";
import LocationIcon from "../components/icons/LocationIcon";
import EquipmentIcon from "../components/icons/EquipmentIcon";
import SectionsIcon from "../components/icons/SectionsIcon";
import CableIcon from "../components/icons/CableIcon";
import CableTypeIcon from "../components/icons/CableTypeIcon";
import CableBundleIcon from "../components/icons/CableBundleIcon";
import OrderIcon from "../components/icons/OrderIcon";

import { Link } from "react-router-dom";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import { Box } from "@mui/system";
import { ROUTES } from "../enums/Routes";
import { GlobalContext } from "../globalContext";
import { addSearchParams } from "../utils/dao";
import RoutePointsIcon from "../components/icons/RoutePointsIcon";
import DrumsIcon from "../components/icons/DrumsIcon";

const tiles = [
  {
    title: "Objects",
    overviewPath: ROUTES.object.list,
    addPath: ROUTES.object.create,
    buttonDisplay: "Show objects",
    buttonAdd: "Create object",
    icon: <ShipIcon />,
    entity: "object",
  },
  {
    title: "Projects",
    overviewPath: ROUTES.project.list,
    addPath: ROUTES.project.create,
    buttonDisplay: "Show projects",
    buttonAdd: "Create project",
    icon: <ProjectsIcon />,
    entity: "project",
  },
  {
    title: "System groups",
    overviewPath: ROUTES.systemGroup.list,
    addPath: ROUTES.systemGroup.create,
    buttonDisplay: "Show system groups",
    buttonAdd: "Create system group",
    icon: <SystemGroupIcon />,
    entity: "system_group",
  },
  {
    title: "Systems",
    overviewPath: ROUTES.system.list,
    addPath: ROUTES.system.create,
    buttonDisplay: "Show systems",
    buttonAdd: "Create system",
    icon: <SystemsIcon />,
    entity: "system",
  },
  {
    title: "Locations",
    overviewPath: ROUTES.location.list,
    addPath: ROUTES.location.create,
    buttonDisplay: "Show locations",
    buttonAdd: "Create location",
    icon: <LocationIcon />,
    entity: "location",
  },
  {
    title: "Equipments",
    overviewPath: ROUTES.equipment.list,
    addPath: ROUTES.equipment.create,
    buttonDisplay: "Show equipments",
    buttonAdd: "Create equipment",
    icon: <EquipmentIcon />,
    entity: "equipment",
  },
  {
    title: "Sections",
    overviewPath: ROUTES.section.list,
    addPath: ROUTES.section.create,
    buttonDisplay: "Show sections",
    buttonAdd: "Create section",
    icon: <SectionsIcon />,
    entity: "section",
  },
  {
    title: "Cables",
    overviewPath: ROUTES.cable.list,
    addPath: ROUTES.cable.create,
    buttonDisplay: "Show cables",
    buttonAdd: "Create cable",
    icon: <CableIcon />,
    entity: "cable",
  },
  {
    title: "Cable types",
    overviewPath: ROUTES.cableType.list,
    addPath: ROUTES.cableType.create,
    buttonDisplay: "Show cable types",
    buttonAdd: "Create cable type",
    icon: <CableTypeIcon title='Cable type' titleId='cable-type-title' />,
    entity: "cable_type",
  },
  {
    title: "Bundles",
    overviewPath: ROUTES.bundle.list,
    addPath: ROUTES.bundle.create,
    buttonDisplay: "Show bundles",
    buttonAdd: "Create bundle",
    icon: <CableBundleIcon title='Bundle' titleId='cable-bundle-title' />,
    entity: "cable_bundle",
  },
  {
    title: "Route points",
    overviewPath: ROUTES.routePoints.list,
    addPath: ROUTES.routePoints.create,
    buttonDisplay: "Show route points",
    buttonAdd: "Create route point",
    icon: <RoutePointsIcon title='Route point' titleId='route-point-title' />,
    entity: "route_point",
  },
  {
    title: "Drums",
    overviewPath: ROUTES.drums.list,
    addPath: ROUTES.drums.create,
    buttonDisplay: "Show drums",
    buttonAdd: "Create drum",
    icon: <DrumsIcon title='Drum' titleId='drum-title' />,
    entity: "drum",
  },
  {
    title: "Orders",
    overviewPath: ROUTES.order.list,
    addPath: ROUTES.order.create,
    buttonDisplay: "Show orders",
    buttonAdd: "Create order",
    icon: <OrderIcon title='Order' titleId='order-title' />,
    entity: "order",
  },
];

function Tile({ title, toOverview, toAdd, btnDisplay, btnAdd, icon, entity }) {
  const { isAllowedTo } = useUserPermissionsContext();

  const isAllowedToRead = isAllowedTo(UserRights.READ, entity);
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, entity);

  return (
    <Card
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: "0px 3px 20px #DBDBDB",
        "& .MuiCardHeader-root": {
          padding: 1,
        },
        "& .MuiCardActions-root": {
          alignItems: "stretch",
          flexDirection: "column",
          "& a": {
            marginLeft: 0,
            marginTop: "8px",
            maxWidth: "240px",
            textTransform: "none",
          },
        },
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={6} md={9}>
          <CardHeader title={title} />
          <CardActions>
            <Button
              variant='contained'
              color='primary'
              component={Link}
              to={toOverview}
              disabled={!isAllowedToRead}
              title={
                !isAllowedToRead
                  ? "You are not allowed to perform this action"
                  : undefined
              }
            >
              {btnDisplay}
            </Button>
            <Button
              variant='outlined'
              color='primary'
              component={Link}
              to={toAdd}
              disabled={!isAllowedToCreate}
              title={
                !isAllowedToCreate
                  ? "You are not allowed to perform this action"
                  : undefined
              }
            >
              {btnAdd}
            </Button>
          </CardActions>
        </Grid>
        <Grid
          sx={{
            display: "flex",
          }}
          item
          xs={6}
          md={3}
          container
          justifyContent='center'
          alignItems='center'
        >
          <Box
            sx={{
              height: "24px",
              padding: "16px",
              borderRadius: "40px",
              // not using the variable because we need transparency value
              backgroundColor: "rgba(0, 144, 75, .2)",
              "& svg": {
                fill: "#00904b",
              },
            }}
          >
            {icon}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default function HomeDashboard() {
  const { isLoading, hasError } = useUserPermissionsContext();
  const { globalFilters } = useContext(GlobalContext);

  const searchParams = new URLSearchParams(
    Object.entries({ objectId: globalFilters?.value }).filter(
      ([, value]) => value
    )
  ).toString();

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: 3,
          textAlign: "center",
        }}
      >
        <CircularProgress sx={{ marginBottom: "2rem" }} />
        <Typography>Loading your permissions</Typography>
      </Box>
    );
  }
  if (hasError) {
    return (
      <Alert severity='error'>
        <Typography>
          There was an error loading your permissions. Please try again later.
        </Typography>
      </Alert>
    );
  }
  return (
    <Grid container spacing={3} data-testid='homeDashboard' marginBottom={6}>
      {tiles.map((tile, i) => {
        return (
          <Grid key={`${tile.title}_${i}`} item xs={12} md={5} lg={4}>
            <Tile
              title={tile.title}
              toOverview={tile.overviewPath}
              toAdd={
                tile.entity !== "object"
                  ? addSearchParams(tile.addPath)(searchParams)
                  : tile.addPath
              }
              btnDisplay={tile.buttonDisplay}
              btnAdd={tile.buttonAdd}
              icon={tile.icon}
              entity={tile.entity}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
