import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import FieldName from './../enums/FieldName';
import { errorMessageHandler } from './../utils/error';

/**
 *  state initialization function
 * should return an initial state object
 * @returns {object} initial state object
 */
function createInitialState() {
  return {
    object_id: "",
    system_group_id: "",
  };
}

export default function useSystem(
    objectId = null,
    systemGroupId = null,
    systemId = null,
) {
  const [ system, setSystem ] = useState(createInitialState);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchSystemData = (abortController) => {
    if (systemId) {
      getEntities(
        "/systems",
        {
          object_ids: objectId,
          system_group_ids: systemGroupId,
          ids: systemId,
        },
        abortController
      ).then((res) => {
        const response = res.response;
        // guard
        if (response.length === 0) {
          return;
        }
        setSystem(response[0]);
        setIsLoading(false);
      }).catch(errorMessageHandler);
    } else {
      if (objectId || systemGroupId) {
        setSystem((prevState) => ({
          ...prevState,
          [FieldName.OBJECT_ID]: objectId,
          [FieldName.SYSTEM_GROUP_ID]: systemGroupId,
        }));
      }
      setIsLoading(false);
    }
  };
  /**
   * useEffect should have a dependency on systemNumber
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchSystemData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ systemId ]);

  return { 
    system,
    fetchSystemData,
    isLoading,
   };
}
