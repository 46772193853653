import React from "react";

export default function CableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20.286"
      viewBox="0 0 24 20.286"
    >
      <g transform="translate(-83.993 -54.881)">
        <path
          d="M103.269,64.077H91.092a2.8,2.8,0,0,1,0-5.608h4.825v.884a.76.76,0,0,0,.758.758h2.779a.744.744,0,0,0,.758-.758h1.971a.572.572,0,0,0,.581-.581V56.22a.572.572,0,0,0-.581-.581h-1.971a.777.777,0,0,0-.758-.758H96.675a.76.76,0,0,0-.758.758v.884H91.092a4.724,4.724,0,1,0,0,9.448h12.177a2.8,2.8,0,0,1,0,5.608H90.84V70.7a.76.76,0,0,0-.758-.758H87.3a.744.744,0,0,0-.758.758H84.574a.572.572,0,0,0-.581.581v2.552a.572.572,0,0,0,.581.581h1.971a.777.777,0,0,0,.758.758h2.779a.76.76,0,0,0,.758-.758v-.884h12.429a4.724,4.724,0,0,0,0-9.448ZM101.6,58.216h-1.39V56.8h1.39ZM85.155,71.858h1.39v1.415h-1.39Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
}
