import { useEffect, useState } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import moment from "moment";

import useFields, { FILTER_TYPES } from "../hooks/useFields";
import useObject from "../hooks/useObject";

import EditEntity from "../components/EditEntity";

import EntityOverview from "../views/EntityOverview";
import CreateItemButton from "../components/CreateItemButton";

import {
  addObject,
  patchObject,
  softDeleteObject,
  restoreObject,
} from "../dao/objects";

import { exportToFile } from "../utils/utils";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";
import { exportRecords } from "../dao/common";
import { errorMessageHandler } from "../utils/error";
import ButtonWithSpinner from "../components/ButtonWithSpinner";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";

const EXPORT_DATE_FORMAT = "YYYYMMDD";

export default function ObjectDetail({ title = "", mode = "" }) {
  const [isExportingObject, setIsExportingObject] = useState(false);
  const [isExportingEquipment, setIsExportingEquipment] = useState(false);
  const [isExportingCables, setIsExportingCables] = useState(false);
  const history = useHistory();
  const { objectId } = useParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, FILTER_TYPES.object);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.object
  );
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.object);

  const { object, fetchObjectData } = useObject(objectId);

  const [fields] = useFields(
    FILTER_TYPES.object,
    false,
    isCreateMode,
    isUpdateMode
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeObject(payload) {
    if (isUpdateMode) {
      log.trace("about to patch", object.id);
      await patchObject(payload, object.id).then(() => {
        fetchObjectData();
      });
    } else {
      log.trace("about to create", JSON.stringify(object));
      return addObject(payload).then((res) => {
        history.push(`/object/${res.id}`);
      });
    }
  }

  async function removeObject() {
    if (isUpdateMode) {
      return softDeleteObject(object).then(() => {
        fetchObjectData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchObjectData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedObject() {
    restoreObject(object).then(() => {
      fetchObjectData();
    });
  }

  const handleExportObjectClick = () => {
    setIsExportingObject(true);
    const abortController = new AbortController();
    exportRecords(`/eplan/objects/${object.id}`, {}, abortController)
      .then((response) => {
        const currentDate = moment().format(EXPORT_DATE_FORMAT);
        exportToFile(`${object.number}_EPLAN_OBJ_${currentDate}.csv`, response);
      })
      .catch((err) => {
        errorMessageHandler(err);
      })
      .finally(() => {
        setIsExportingObject(false);
      });
  };

  const handleExportEquipmentClick = () => {
    setIsExportingEquipment(true);
    const abortController = new AbortController();
    const type = "application/xlsx";
    exportRecords(
      `/eplan/objects/${object.id}/equipments`,
      {},
      abortController,
      type
    )
      .then((response) => {
        const currentDate = moment().format(EXPORT_DATE_FORMAT);
        exportToFile(
          `${object.number}_EPLAN_EQM_${currentDate}.xlsx`,
          response,
          type
        );
      })
      .catch((err) => {
        errorMessageHandler(err);
      })
      .finally(() => {
        setIsExportingEquipment(false);
      });
  };

  const handleExportCablesClick = () => {
    setIsExportingCables(true);
    const abortController = new AbortController();
    const type = "application/xlsx";
    exportRecords(
      `/eplan/objects/${object.id}/cables`,
      {},
      abortController,
      type
    )
      .then((response) => {
        const currentDate = moment().format(EXPORT_DATE_FORMAT);
        exportToFile(
          `${object.number}_EPLAN_CAB_${currentDate}.xlsx`,
          response,
          type
        );
      })
      .catch((err) => {
        errorMessageHandler(err);
      })
      .finally(() => {
        setIsExportingCables(false);
      });
  };

  return !fields || !object ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            entity={FILTER_TYPES.object}
            size='medium'
            to={ROUTES.object.create}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <>
          <EditEntity
            isAllowedToUpdate={isAllowedToUpdate}
            isAllowedToDelete={isAllowedToDelete}
            isAllowedToCreate={isAllowedToCreate}
            entity={object}
            fields={fields}
            storeEntity={storeObject}
            deleteEntity={removeObject}
            entityType={FILTER_TYPES.object}
            cancel={cancelChanges}
            restoreEntity={restoreDeletedObject}
            isUpdateMode={isUpdateMode}
          />
          {isUpdateMode && (
            <>
              <ButtonWithSpinner
                onClick={handleExportObjectClick}
                variant='text'
                color='primary'
                title={"Export"}
                loading={isExportingObject}
                disabled={isExportingObject}
              >
                Export
              </ButtonWithSpinner>
              <ButtonWithSpinner
                onClick={handleExportEquipmentClick}
                variant='text'
                color='primary'
                title={"Export Equipment/Sections"}
                loading={isExportingEquipment}
                disabled={isExportingEquipment}
              >
                Export Equipment/Sections
              </ButtonWithSpinner>
              <ButtonWithSpinner
                onClick={handleExportCablesClick}
                variant='text'
                color='primary'
                title={"Export Cables"}
                loading={isExportingCables}
                disabled={isExportingCables}
              >
                Export Cables
              </ButtonWithSpinner>
            </>
          )}
        </>
      )}
      {isUpdateMode && (
        <>
          <EntityOverview
            entity={FILTER_TYPES.project}
            title={"Projects overview"}
            nestedEntity={true}
            parent={{
              object_id: object.id,
            }}
          />
          <EntityOverview
            entity={FILTER_TYPES.system_group}
            title={"System groups overview"}
            nestedEntity={true}
            parent={{
              object_id: object.id,
            }}
          />
          <EntityOverview
            entity={FILTER_TYPES.location}
            title={"Locations overview"}
            nestedEntity={true}
            parent={{
              object_id: object.id,
            }}
          />
          <EntityOverview
            entity={FILTER_TYPES.equipment}
            title={"Equipments overview"}
            nestedEntity={true}
            parent={{
              object_id: object.id,
            }}
          />
          <EntityOverview
            entity={FILTER_TYPES.cable_bundle}
            title={"Bundles overview"}
            nestedEntity={true}
            parent={{
              object_id: object.id,
            }}
          />
        </>
      )}
    </Paper>
  );
}
