export default function CableIcon({
  title,
  titleId,
  width = "24",
  height = "24",
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath='url(#a)'>
        <path d='M15.724 2.084c-.013.061-.061.22-.097.368-.074.28-.134.317-.353.159-.157-.11-.085-.269-.741 1.646-.56 1.646-.888 2.549-1.41 3.841a22.292 22.292 0 0 1-1.86 3.817.916.916 0 0 1-.023-.451c.024-.476.133-.707.352-.707a.31.31 0 0 0 .206-.159c.122-.195.074-.232-.389-.329-.79-.17-1.166.195-1.166 1.122.02.64.19 1.267.498 1.829 1.107 2.256 3.318 3.978 4.339 3.378.304-.17.44-.451.474-.963.012-.295 0-.454-.037-.476a.248.248 0 0 0-.34.207.572.572 0 0 1-.572.426c-.242-.012-.862-.232-.887-.342-.037-.134 2.212-1.012 4.096-1.597 1.3-.402 3.083-.89 4.41-1.22 1.143-.28 1.07-.231.973-.597-.024-.097.012-.122.389-.158l.413-.037-.073-.244-.073-.256-.377.012-.377.013.037-.183c.024-.122.012-.182-.037-.182-.036 0-.498.097-1.02.207a64.905 64.905 0 0 0-8.957 2.853l-.669.268-.17-.195c-.085-.11-.158-.232-.147-.256.303-.184.62-.343.948-.476a68.637 68.637 0 0 0 6.15-3.183c.971-.573 2.71-1.707 2.71-1.756 0-.024-.074-.097-.158-.171l-.158-.134.316-.158c.34-.171.34-.183.073-.516l-.11-.147-.315.207c-.304.195-.316.195-.4.085a1.399 1.399 0 0 1-.098-.17c-.024-.074-.22.024-1.592.89-2.236 1.4-4.874 2.865-4.63 2.573.06-.074.462-.5.875-.927a52.03 52.03 0 0 0 3.427-3.963l.498-.646-.182-.049a.367.367 0 0 1-.17-.073c.053-.112.119-.219.195-.317.303-.426.303-.415.085-.549a1.083 1.083 0 0 0-.268-.122c-.132.1-.244.225-.328.368l-.243.369-.17-.159-.158-.158-.267.317c-.147.17-.608.731-1.009 1.231-1.203 1.464-3.135 3.622-3.184 3.585a5.85 5.85 0 0 1 .256-.658 57.051 57.051 0 0 0 2.041-5.414c.122-.402.232-.781.256-.841.024-.086-.012-.098-.182-.098-.231 0-.231.025-.085-.536.024-.11.037-.221.037-.221-.013 0-.122-.024-.268-.049-.231-.025-.28-.013-.304.072Z' />
        <path d='M9.368 11.267a44.314 44.314 0 0 0-3.875 3.053c-1 .881-1.96 1.805-2.88 2.769-2.47 2.578 3.17 7.694 5.5 5.283a46.57 46.57 0 0 1 2.253-2.176 37.525 37.525 0 0 1 3.67-2.93c.232-.157.441-.304.462-.328.025-.013-.073-.037-.206-.037-1.155-.024-2.93-1.598-3.84-3.403a3.413 3.413 0 0 1-.413-2.511.756.756 0 0 0 .073-.221c-.015.001-.343.222-.744.501Z' />
      </g>
    </svg>
  );
}
