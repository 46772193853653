import { useEffect, useState } from "react";
import { getEntities } from "../dao/common";
import { errorMessageHandler } from "../utils/error";

const useDrum = ({ drumId = null, objectId = null }) => {
  const [drum, setDrum] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchDrumData = (abortController) => {
    // Only fetch if we have the required params
    if (drumId) {
      getEntities(
        "/drums",
        {
          ids: drumId,
          getTotals: true,
        },
        abortController
      )
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setDrum(response[0]);
          setIsLoading(false);
        })
        .catch(errorMessageHandler);
    } else if (objectId) {
      setDrum((prevState) => ({
        ...prevState,
        object_id: objectId,
      }));
    }
    setIsLoading(false);
  };
  /**
   * useEffect should have a dependency on drumId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchDrumData(abortController);
    return () => {
      abortController.abort();
    };
  }, [drumId]);

  return {
    drum,
    fetchDrumData,
    isLoading,
  };
};

export default useDrum;
