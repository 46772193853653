import compose from "compose-function";
import { get, del, patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const PROJECT_API_PATH = "/projects";
export const PROJECT_COLUMNS_API_PATH = "/fields?type=project";

export async function patchProject(project, projectId, isInlineEdit) {
  const body = await patch(
    [PROJECT_API_PATH, projectId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("reference"),
      sanitizeEntity("client")
    )(project)
  );
  return body?.response;
}

export async function fetchProjectTableColumns() {
  const body = await get(PROJECT_COLUMNS_API_PATH);
  return body?.response;
}

export async function softDeleteProject(project) {
  const body = await patch([PROJECT_API_PATH, project.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

export async function restoreProject(project) {
  const body = await patch([PROJECT_API_PATH, project.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteProject(project) {
  const body = await del([PROJECT_API_PATH, project.id].join("/"));
  return body?.response;
}

export async function addProject(project) {
  const body = await post(PROJECT_API_PATH, project);
  return body?.response;
}
