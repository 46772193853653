/**
 * Feature: Mass Edit
 *
 * Utility functions
 **/

import moment from "moment";

/**
 * isMassEditable
 * @param {object} field the field object
 * @returns {boolean}
 */
export const isMassEditable = (field = {}) => field.mass_editable === true;

/**
 * curried fn getTabIndex
 * @param {number} row
 * @returns {number} the index
 */
export const getTabIndex =
  (row = 0) =>
  (iteration = 0) => {
    // 0 1
    // 0 2
    if (row === 0) {
      return row + 1 + iteration;
    }
    if (row === 1) {
      return row + 2 + iteration;
    }
    return row * 2 + iteration;
  };

/**
 * getDefaultFieldValue
 * @param {string} type
 * @returns {boolean|string|number} the default field value
 *
 **/
export const getDefaultFieldValue = (type = "") => {
  switch (true) {
    case ["date"].includes(type):
      return moment().format("YYYY-MM-DD");
    case ["boolean"].includes(type):
      return false;
    default:
      return "";
  }
};

/**
 * createInitialState
 * @param {array} fields array
 * @returns {object} initalState
 */
export const createInitialState = (fields = []) => {
  return (
    fields
      // create the initial state object from the fields
      .reduce(
        (acc, item) => {
          return {
            ...acc,
            [item.field]: {
              value: item.value ?? getDefaultFieldValue(item.type),
              indeterminate: item.indeterminate || false,
              hasError: false,
              isPristine: true,
              disabled: false,
              meta: {
                type: item.type || "",
              },
              id: item.id,
            },
          };
        },
        // initial params
        {
          formSubmitDisabled: true,
        }
      )
  );
};

/**
 * function to transform the values from the form into
 * compatible values for API
 * @param {array} item the current item
 * @returns {object} the new object
 */
export const transformValue = (item) => {
  const [fieldName = "", data = {}] = item;
  // guard
  if (fieldName === "" || "value" in data === false) {
    throw new Error("Invalid argument, item");
  }
  return { [fieldName]: data.value };
};

/**
 * function to sanitize the value
 * before it is sent to the api
 */
export const sanitizeValue = (sanitizerFn) => (item) => {
  const [fieldName = "", data = {}] = item;
  // guard
  if (
    fieldName === "" ||
    "value" in data === false ||
    "meta" in data === false
  ) {
    throw new Error("Invalid argument, item");
  }
  return [
    fieldName,
    { ...data, value: sanitizerFn(data.value, data.meta.type) },
  ];
};

/**
 *
 * @param {number|string|boolean} value
 * @param {string} type
 * @returns {number|string|boolean} the sanitized value
 */
export const sanitizer = (value, type) => {
  // settings
  const SUPPORTED_TYPES = ["number"];
  // guard
  if (SUPPORTED_TYPES.includes(type)) {
    return value === "" ? null : Number(value);
  }
  return value;
};

/**
 * Checks if all items in an array of objects have the same value for a given property.
 *
 * @param {Array} array - The array of objects to check.
 * @param {string} propertyName - The name of the property to compare.
 * @returns {boolean} - True if all items have the same value for the property, false otherwise.
 */
export const areAllItemsSame = (array, propertyName) => {
  const firstValue = array[0][propertyName];
  return array.every((item) => item[propertyName] === firstValue);
};

/**
 * This function transforms the form state for a cable / mass edit dropdown based on the current values and their pristine state.
 * It checks if a field's value is missing (null, undefined, or an empty string) and if the field has been modified
 * (i.e., it is not pristine). If these conditions are met, it resets the value and associated ID fields accordingly.
 * Otherwise, if the field has a valid value and is not pristine, it updates the form state with the appropriate values.
 *
 * The function is designed to handle multiple fields: `cable_type`, `bundle_number`, `drum_number`, and `order_number`.
 * It dynamically processes these fields using an array of objects that map the key names and their corresponding ID
 * and value properties.
 *
 * @param {Object} formState - The current state of the form, including values and their pristine status.
 * @returns {Object} - The transformed form state with updated values and ID fields.
 */
export const transformFormStateForCableDropdown = (formState) => {
  const fields = [
    { key: "cable_type", idKey: "cable_type_id", valueKey: "type" },
    { key: "bundle_number", idKey: "cable_bundle_id", valueKey: "number" },
    { key: "drum_number", idKey: "drum_id", valueKey: "number" },
    { key: "order_number", idKey: "order_id", valueKey: "number" },
  ];

  for (const { key, idKey, valueKey } of fields) {
    const { isPristine = true, value = null } = formState[key] || {};

    if (
      (value === null || value === undefined || value === "") &&
      !isPristine
    ) {
      return {
        ...formState,
        [key]: {
          ...formState[key],
          value: valueKey === "type" ? "" : null,
        },
        [idKey]: { ...formState[key], value: null },
      };
    } else if (!!value?.[valueKey] && !isPristine) {
      return {
        ...formState,
        [key]: { ...formState[key], value: value[valueKey] },
        [idKey]: { ...formState[key], value: value.id },
      };
    }
  }

  return formState;
};

/**
 * Handles responses with partial success (HTTP status 206) by identifying and reporting unsuccessful records.
 * If any records failed to update, it throws an error with a detailed message including failure reason.
 *
 * @param {Object} response - The response object from the server.
 * @throws {Error} If any records are unsuccessful, an error is thrown with the failure details.
 */
export const handleUnsuccessfulRecords = (response) => {
  const results = response?.response?.results || [];

  const unsuccessfulResults = results.filter((result) => !result.success);

  // If there are any unsuccessful results, throw an error with detailed messages
  if (unsuccessfulResults.length > 0) {
    const errorMessages = unsuccessfulResults
      .map((result) => result.message)
      .join("\n");
    throw new Error(
      `Failed to update ${unsuccessfulResults.length} records for the following reason: \n${errorMessages}`
    );
  }
};
