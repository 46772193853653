import { noop } from "../utils/utils";

/**
 * curried function that is used by fields
 * to
 * - call setValueCallback with data
 * - call onChangeCallBack with the event
 * @param {setValueCallback} cb - the callback that sets the state
 * @param {onChangeCallBack} cb - the callback for onChange
 * @returns {}
 */
export function createChangeHandler(
  setValueCallback = noop.fn,
  onChangeCallBack = noop.fn
) {
  return (e) => {
    // guard
    if ("target" in e === false) {
      return;
    }
    const { value, type, fieldname } = e.target;

    const sanitizedValue = sanitize(value, type);

    setValueCallback({ value: sanitizedValue, fieldname: fieldname });
    if (onChangeCallBack) {
      return onChangeCallBack(e);
    }
  };
}

/**
 *
 * @param {number|string|boolean} value
 * @param {string} type
 * @returns {number|string|boolean} the sanitized value
 */
export function sanitize(value, type) {
  // settings
  const SUPPORTED_TYPES = ["number"];
  // guard
  if (SUPPORTED_TYPES.includes(type)) {
    return value === "" ? null : Number(value);
  }
  return value;
}

/**
 * High order function withConfig
 * that takes a config object and loads it into the component
 * @param {object} config
 * @returns {function} a function that takes a component
 */
export function withConfig(config) {
  // guard
  if (typeof config !== "object") {
    throw new Error("config must be an object");
  }
  return (Component) => {
    return (props) => {
      return <Component {...props} {...config} />;
    };
  };
}

/**
 * Formats a label string with additional annotations based on the provided attributes.
 * @param {Object} data - An object containing the label, required flag, and unit.
 * @returns {string} The formatted label.
 */
export function formatLabel(data) {
  if (!data || typeof data !== "object") {
    return "";
  }
  const { label, required, unit } = data;
  if (!label) {
    return "";
  }
  let formattedLabel = label;
  if (required) {
    formattedLabel += " (required)";
  }
  if (unit) {
    formattedLabel += ` [${unit}]`;
  }
  return formattedLabel;
}
