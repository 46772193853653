import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const SYSTEMS_API_PATH = "/systems";

export async function patchSystem(system, systemId, isInlineEdit) {
  return patch(
    [SYSTEMS_API_PATH, systemId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("system_group_number")
    )(system)
  );
}

export async function addSystem(system) {
  const body = await post(SYSTEMS_API_PATH, system);
  return body?.response;
}

export async function softDeleteSystem(system) {
  const body = await patch([SYSTEMS_API_PATH, system.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

export async function restoreSystem(system) {
  const body = await patch([SYSTEMS_API_PATH, system.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteSystem(system) {
  const body = await del([SYSTEMS_API_PATH, system.id].join("/"));
  return body?.response;
}
