import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const SYSTEMGROUPS_API_PATH = "/system-groups";

export async function patchSystemGroup(
  systemGroup,
  systemGroupId,
  isInlineEdit
) {
  return patch(
    [SYSTEMGROUPS_API_PATH, systemGroupId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(sanitizeEntity("id"), sanitizeEntity("object_number"))(systemGroup)
  );
}

export async function addSystemGroup(systemGroup) {
  const body = await post(SYSTEMGROUPS_API_PATH, systemGroup);
  return body?.response;
}

export async function softDeleteSystemGroup(systemGroup) {
  const body = await patch([SYSTEMGROUPS_API_PATH, systemGroup.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

export async function restoreSystemGroup(systemGroup) {
  const body = await patch([SYSTEMGROUPS_API_PATH, systemGroup.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

export async function hardDeleteSystemGroup(systemGroup) {
  const body = await del([SYSTEMGROUPS_API_PATH, systemGroup.id].join("/"));
  return body?.response;
}

/**
 * Sends POST request to backend
 * for copying an SystemGroup item
 * @param {Object} systemGroup
 * @param {Number} newSystemGroupNumber
 * @returns {Promise}
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.1/console/method/%233911/}
 */
export async function copySystemGroup(
  systemGroup = {},
  newSystemGroupNumber = 0
) {
  const { id } = systemGroup;
  return post([SYSTEMGROUPS_API_PATH, id, "copy"].join("/"), {
    number: newSystemGroupNumber,
  });
}
