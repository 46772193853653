import { useState, useEffect } from 'react';
import { getEntities } from '../dao/common';
import { errorMessageHandler } from './../utils/error';

export default function useObject(objectId = null) {
  const [ object, setObject ] = useState(null);

  const fetchObjectData = (abortController) => {
    if (objectId) {
      getEntities(
        '/objects',
        {ids: objectId},
        abortController,
        ).then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setObject(response[0]);
      }).catch(errorMessageHandler);
    } else {
      setObject({});
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchObjectData(abortController);
    return () => {
      abortController.abort();
    };
  }, [ objectId ]);

  return { object, fetchObjectData };
}
