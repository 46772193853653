import ProjectsIcon from "../components/icons/ProjectsIcon";
import ShipIcon from "../components/icons/ShipIcon";
import SystemGroupIcon from "../components/icons/SystemGroupIcon";
import SystemsIcon from "../components/icons/SystemsIcon";
import LocationIcon from "../components/icons/LocationIcon";
import DashboardIcon from "../components/icons/DashboardIcon";
import EquipmentIcon from "../components/icons/EquipmentIcon";
import SectionsIcon from "../components/icons/SectionsIcon";
import CableIcon from "../components/icons/CableIcon";
import CableTypeIcon from "../components/icons/CableTypeIcon";
import CableBundleIcon from "../components/icons/CableBundleIcon";
import RoutePointsIcon from "../components/icons/RoutePointsIcon";
import DrumsIcon from "../components/icons/DrumsIcon";
import OrderIcon from "../components/icons/OrderIcon";
import { ROUTES } from "../enums/Routes";

export const menuItems = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    path: "/",
  },
  {
    title: "Objects overview",
    icon: <ShipIcon />,
    path: ROUTES.object.list,
  },
  {
    title: "Projects overview",
    icon: <ProjectsIcon />,
    path: ROUTES.project.list,
  },
  {
    title: "System groups overview",
    icon: <SystemGroupIcon />,
    path: ROUTES.systemGroup.list,
  },
  {
    title: "Systems overview",
    icon: <SystemsIcon />,
    path: ROUTES.system.list,
  },
  {
    title: "Locations overview",
    icon: <LocationIcon />,
    path: ROUTES.location.list,
  },
  {
    title: "Equipments overview",
    icon: <EquipmentIcon />,
    path: ROUTES.equipment.list,
  },
  {
    title: "Sections overview",
    icon: <SectionsIcon />,
    path: ROUTES.section.list,
  },
  {
    title: "Cables overview",
    icon: <CableIcon />,
    path: ROUTES.cable.list,
  },
  {
    title: "Cable types overview",
    icon: <CableTypeIcon />,
    path: ROUTES.cableType.list,
  },
  {
    title: "Bundles overview",
    icon: <CableBundleIcon />,
    path: ROUTES.bundle.list,
  },
  {
    title: "Route points overview",
    icon: <RoutePointsIcon />,
    path: ROUTES.routePoints.list,
  },
  {
    title: "Drums overview",
    icon: <DrumsIcon />,
    path: ROUTES.drums.list,
  },
  {
    title: "Orders overview",
    icon: <OrderIcon />,
    path: ROUTES.order.list,
  },
];
