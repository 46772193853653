import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { noop } from "../../utils/utils";
import { DIALOG_INFO_TOGGLE } from "../TableComponent";

export const AlertDialog = ({
  visible = false,
  setVisible = noop.fn,
  content = "",
  title = "Column description",
}) => {
  const handleClose = () => {
    setVisible({ type: DIALOG_INFO_TOGGLE, payload: "" });
  };
  return (
    <div>
      <Dialog
        open={visible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
