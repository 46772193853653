import { useEffect, useRef } from "react";

const useBeforeUnload = (unsavedChanges, history) => {
  const isPromptCanceledRef = useRef(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges && !isPromptCanceledRef.current) {
        e.preventDefault();
        e.returnValue = ""; // This line is required for Chrome
        return ""; // This line is required for Firefox
      }
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "w") {
        isPromptCanceledRef.current = true;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [unsavedChanges, history]);
};

export default useBeforeUnload;
