import { useEffect, useState } from "react";
import { getEntities } from "../dao/common";
import { errorMessageHandler } from "../utils/error";

const useOrder = ({ orderId = null, objectId = null }) => {
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrderData = (abortController) => {
    // Only fetch if we have the required params
    if (orderId) {
      getEntities(
        "/orders",
        {
          ids: orderId,
        },
        abortController
      )
        .then((res) => {
          const response = res.response;
          // guard
          if (response.length === 0) {
            return;
          }
          setOrder(response[0]);
          setIsLoading(false);
        })
        .catch(errorMessageHandler);
    } else if (objectId) {
      setOrder((prevState) => ({
        ...prevState,
        object_id: objectId,
      }));
    }
    setIsLoading(false);
  };
  /**
   * useEffect should have a dependency on orderId
   * so that it fires when this changes (changed url params)
   */
  useEffect(() => {
    const abortController = new AbortController();
    fetchOrderData(abortController);
    return () => {
      abortController.abort();
    };
  }, [orderId]);

  return {
    order,
    fetchOrderData,
    isLoading,
  };
};

export default useOrder;
