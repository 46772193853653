import { Box } from '@mui/system';

export default function ContentActions({ children, className }) {
  return (
    <Box
      sx={ {
        'paddingTop': 1,
        'paddingBottom': 2,
        'display': 'flex',
        'flexDirection': 'row-reverse',
        '& > *': {
          'marginLeft': 2,
        },
      } }
      className={ className }>
      { children }
    </Box>
  );
}
