import {
  ACTION_ON_FIELD_CHANGE,
  ACTION_ON_FORM_ENABLED,
  ACTION_ON_FORM_DISABLED,
  ACTION_ON_FORM_RESET,
  INITIAL_STATE,
} from "./constants";

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_ON_FIELD_CHANGE:
      return { ...state, ...action.payload };
    case ACTION_ON_FORM_ENABLED:
      return { ...state, formDisabled: false };
    case ACTION_ON_FORM_DISABLED:
      return { ...state, formDisabled: true };
    case ACTION_ON_FORM_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
