import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { Box } from "@mui/system";

import { exportToFile } from "../utils/utils";
import { getEntities } from "../dao/common";

const exportItems = [
  "Object",
  "Project",
  "System group",
  "System",
  "Location",
  "Equipment",
  "Section",
  "Cable bundle",
  "Cable type",
];

export default function Export() {
  const downloadEntity = async (filename, route) => {
    const abortController = new AbortController();
    const res = await getEntities(route, {}, abortController, "text/csv");
    exportToFile(`${filename}.csv`, res);
  };

  return (
    <Paper
      sx={{
        padding: "2rem",
      }}
    >
      <Box
        sx={{
          padding: "1rem",
        }}
      >
        <Typography variant='h5'>Export</Typography>
        <List dense={true}>
          {exportItems.map((entity) => {
            const route = `/${entity.toLowerCase().replace(" ", "-")}s`;
            return (
              <ListItem
                key={route}
                onClick={() =>
                  downloadEntity(
                    `${entity.toLowerCase().replace(" ", "_")}s`,
                    route
                  )
                }
              >
                <ListItemButton>
                  <ListItemText primary={`Export all ${entity} records`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}
