import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { menuItems } from "../constants/drawerMenu";
import { getSelectedMenuItemIndex } from "../utils/utils";

export default function DrawerMenu() {
  const location = useLocation();

  const selectedItem = getSelectedMenuItemIndex(location.pathname, menuItems);

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: "56px",
        flexShrink: 0,
        whiteSpace: "nowrap",
        zIndex: 100,
        backgroundColor: "grey.light",
      }}
      PaperProps={{
        sx: {
          flexShrink: 0,
          whiteSpace: "nowrap",
          zIndex: 100,
          backgroundColor: "grey.light",
          borderRight: "none",
        },
      }}
    >
      <List
        sx={{
          padding: 1,
          marginTop: "4rem",
          paddingBottom: "2rem",
        }}
      >
        {menuItems.map((item, index) => (
          <ListItem
            key={item.title}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              component={RouterLink}
              aria-current={selectedItem === index ? "page" : undefined}
              to={item.path}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                },
                borderRadius: "4px",
                marginBottom: "0.25rem",
                overflow: "hidden",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                "@media (min-height: 900px)": {
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                },
              }}
              selected={selectedItem === index}
              title={item.title}
            >
              <ListItemIcon
                sx={{
                  fill: selectedItem === index ? "white" : "black",
                  display: "flex",
                  minWidth: "auto",
                }}
              >
                {item.icon}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
